import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import { speciePropType } from '../species/Species';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const Varieties = (props) => {
  const {
    varieties,
    query,
    routes,
    actionsPermitted,
  } = props;
  const urlNewVariety = routes.newVarietyPath;
  const urlVariety = routes.varietiesPath;

  const columns = [
    {
      header: 'Descrição',
      cell: ({ description }) => description,
    },
    {
      header: 'Atividade',
      cell: ({ specie }) => specie.description,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.varietyPath}
          editUrl={routes.editVarietyPath}
          deleteUrl={routes.varietyPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a cultivar <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        />

      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div>
          <Typography component="h1" variant="h2" align="center">
            Cultivares
          </Typography>
          <SearchBar
            url={urlVariety}
            query={query}
          />
          <NewButton
            url={urlNewVariety}
            description="Nova Cultivar"
          />
          <ListTable
            columns={columns}
            data={varieties}
          />
        </div>
      </Container>
    </>
  );
};

export const varietyPropType = PropTypes.shape({
  description: PropTypes.string,
  averageGermination: PropTypes.number,
  plantingEfficiency: PropTypes.number,
  specie: speciePropType,
  id: PropTypes.string,
});

export const varietyCategoryPropType = PropTypes.shape({
  description: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  id: PropTypes.string,
});

Varieties.propTypes = {
  varieties: PropTypes.arrayOf(varietyPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default Varieties;
