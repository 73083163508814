import React from 'react';

const IconConversao = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="conversao" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.36">
      <path d="M79.24,39.18a24.52,24.52,0,0,1-24.49,24.5H26.89l5.29,5a3.68,3.68,0,0,1,.08,5.28l-1.65,1.65a3.69,3.69,0,0,1-5.2,0L11.22,61.38a3.69,3.69,0,0,1,0-5.2L25.41,42a3.69,3.69,0,0,1,5.2,0l1.65,1.65a3.68,3.68,0,0,1-.08,5.28l-5.29,5H54.75a14.7,14.7,0,0,0,13.41-20.7,3.66,3.66,0,0,1,.72-4.08l1.87-1.86a3.66,3.66,0,0,1,5.86.91A24.37,24.37,0,0,1,79.24,39.18Zm-67.3,6a14.48,14.48,0,0,1-1.29-6,14.72,14.72,0,0,1,14.7-14.7H53.2l-5.28,5a3.67,3.67,0,0,0-.08,5.27l1.65,1.65a3.66,3.66,0,0,0,5.19,0L68.87,22.18a3.66,3.66,0,0,0,0-5.19L54.68,2.8a3.66,3.66,0,0,0-5.19,0L47.84,4.45a3.67,3.67,0,0,0,.08,5.27l5.28,5H25.35A24.5,24.5,0,0,0,3.48,50.21a3.68,3.68,0,0,0,5.87.92l1.86-1.87a3.63,3.63,0,0,0,.73-4.08Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconConversao;
