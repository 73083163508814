import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import DropdownActionButtons from '../../components/DropdownActionButtons';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';

const Producers = (props) => {
  const {
    producers,
    query,
    routes,
    actionsPermitted,
  } = props;

  const urlNewProducer = routes.newProducerPath;
  const urlProducers = routes.producersPath;

  const columns = [
    {
      header: 'Nome',
      cell: (row) => row.name,
    },
    {
      header: 'Cidade',
      cell: (row) => `${(row.city || {}).description} (${(row.city || {}).uf})`,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.producerPath}
          editUrl={routes.editProducerPath}
          deleteUrl={routes.producerPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir o produtor <b>${row.name}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="producers">
          <Typography component="h1" variant="h2" align="center">
            Produtores
          </Typography>
          <SearchBar
            url={urlProducers}
            query={query}
          />
          <NewButton
            url={urlNewProducer}
            description="Novo Produtor"
          />
          <ListTable
            columns={columns}
            data={producers}
          />
        </div>
      </Container>
    </>
  );
};

export const cityPropType = PropTypes.shape({
  description: PropTypes.string.isRequired,
  ibge: PropTypes.string,
  uf: PropTypes.string.isRequired,
});

export const producerPropType = PropTypes.shape({
  name: PropTypes.string,
  city: cityPropType.isRequired,
  address: PropTypes.string,
  id: PropTypes.string,
});

Producers.propTypes = {
  producers: PropTypes.arrayOf(producerPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default Producers;
