import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormModal from '../../components/FormModal';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { laboratoryType } from '../../../services/GeneralTypes';

const CreateLaboratoryModal = (props) => {
  const {
    open,
    onCancel,
    onSubmit,
    laboratories,
  } = props;

  const getInitialState = () => ({
    name: '',
  });

  const [state, setState] = useState(getInitialState());

  const handleChange = (id, value) => {
    const updateState = {
      ...state,
      [id]: value,
    };

    if (id === 'specie') {
      updateState.variety = null;
    }

    setState(updateState);
  };

  const inputFields = [
    {
      id: 'name',
      label: 'Nome do laboratório',
      type: 'text',
      field: 'name',
      validations: [
        (v) => !!v || 'Nome do laboratório é obrigatório',
        (v) => (
          !_.isArray(laboratories)
          || laboratories.every(({ name }) => name.toLowerCase() !== v.toLowerCase())
          || 'Este nome de laboratório já esta cadastrado'
        ),
      ],
      autoFocus: true,
      required: true,
    },
  ];

  const validInputs = () => UtilsService.validateState(inputFields, state);

  return (
    <>
      <FormModal
        open={open}
        title="Laboratório"
        formForProps={{
          onSubmit: (...params) => onSubmit(state, inputFields, ...params),
          url: 'post',
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: onCancel,
          },
        }}
      >
        {inputFields.map((
          {
            id,
            label,
            type,
            field: inputField,
            validations,
            required,
            autoFocus,
          },
        ) => (
          <FloatingLabelInput
            key={inputField}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={inputField}
            required={required}
            autoFocus={autoFocus}
          />
        ))}
      </FormModal>
    </>
  );
};

CreateLaboratoryModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  laboratories: PropTypes.arrayOf(PropTypes.shape(laboratoryType)),
};

export default CreateLaboratoryModal;
