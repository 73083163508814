import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import FormModal from '../../components/FormModal';
import { userPropType } from './Users';
import { userProfileAccessPropType } from '../userProfileAccess/UserProfilesAccess';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'name',
    label: 'Nome',
    type: 'text',
    validations: [],
    disabled: true,
    field: '',
  },
  {
    id: 'email',
    label: 'E-mail',
    type: 'text',
    validations: [],
    disabled: true,
    field: '',
  },
  {
    id: 'cpf',
    label: 'CPF',
    type: 'text',
    validations: [],
    disabled: true,
    field: '',
  },
  {
    id: 'userProfileAccess',
    label: 'Perfil do usuário',
    type: 'autocomplete',
    validations: [
      (v) => !!v || 'Perfil do usuário é obrigatório',
    ],
    field: 'user_profile_access_id',
    required: true,
    renderLabel: ({ description }) => description,
  },
  {
    id: 'admin',
    label: 'Usuário administrador',
    type: 'checkbox',
    validations: [],
    field: 'admin',
  },
];

export const buildUser = (user) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  return {
    name: user.name,
    email: user.email,
    cpf: user.cpf.replace(/^(.{0,3})(.{0,3})(.{0,3})(.{0,2})/, '$1.$2.$3-$4'),
    userProfileAccess: user.userProfileAccess,
    admin: user.admin,
  };
};

const EditUserModal = (props) => {
  const {
    routes,
    user,
    userProfilesAccess,
    currentUser,
  } = props;

  const [state, setState] = useState(() => buildUser(user));
  const [open, setOpen] = useState(true);

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleChange = (id, value, event) => {
    if (event && event.currentTarget.type === 'checkbox') {
      setState({
        ...state,
        [id]: !state[id],
      });
    } else {
      setState({
        ...state,
        [id]: value,
      });
    }
  };

  const additionalInputsInfo = {
    userProfileAccess: {
      values: userProfilesAccess,
    },
    admin: {
      checked: state.admin,
      disabled: !currentUser.admin,
    },
  };

  const generalFields = UtilsService.updateFields(fields, additionalInputsInfo);

  const handleClose = () => setOpen(false);

  const onFormSubmit = () => { FormService.data = state; };

  return (
    <>
      <FormModal
        open={open}
        title="Usuário"
        formForProps={{
          url: routes.userPath.replace(':id', user.id),
          method: 'put',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {generalFields.map(({
          id,
          label,
          type,
          validations,
          placeholder,
          format,
          required,
          autoFocus,
          renderLabel,
          values,
          field,
          disabled,
          checked,
        }) => (
          <FloatingLabelInput
            key={id}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            placeholder={placeholder}
            name={field}
            format={format}
            required={required}
            autoFocus={autoFocus}
            renderLabel={renderLabel}
            values={values}
            disabled={disabled}
            checked={checked}
          />
        ))}
      </FormModal>
    </>
  );
};

EditUserModal.propTypes = {
  user: userPropType,
  userProfilesAccess: PropTypes.arrayOf(userProfileAccessPropType),
  currentUser: userPropType,
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditUserModal;
