import React, { useState } from 'react';
import { sievePropType } from './Sieves';
import { fields, buildSieve } from './EditSieveModal';
import ViewModal from '../../components/ViewModal';

const ViewSieveModal = (props) => {
  const { sieve } = props;

  const data = buildSieve(sieve);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Peneira"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={data}
      />
    </>
  );
};

ViewSieveModal.propTypes = {
  sieve: sievePropType,
};

export default ViewSieveModal;
