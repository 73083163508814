import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const RecommendationPeriods = (props) => {
  const {
    recommendationPeriods,
    query,
    routes,
    actionsPermitted,
  } = props;

  const [allRecommendationPeriod, setRecommendationPeriod] = useState([]);

  const urlNewRecommendationPeriod = routes.newRecommendationPeriodPath;
  const urlRecommendationPeriod = routes.recommendationPeriodsPath;

  useEffect(() => {
    setRecommendationPeriod(recommendationPeriods.map((recommendationPeriod) => {
      const updatedRecommendationPeriod = { ...recommendationPeriod, id: recommendationPeriod.id };
      return updatedRecommendationPeriod;
    }));
  }, [recommendationPeriods]);

  const formatDate = (date) => {
    if (!date) {
      return '';
    }

    return moment(date).utcOffset(date).format('DD/MM');
  };

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Data Início',
      cell: (row) => formatDate(row.startDate),
    },
    {
      header: 'Data Fim',
      cell: (row) => formatDate(row.endDate),
    },
    {
      header: 'Atividade',
      cell: (row) => (row.specie || {}).description || '',
    },
    {
      header: 'Ações',
      // eslint-disable-next-line react/prop-types
      cell: ({ description, id }) => (
        <DropdownActionButtons
          viewUrl={routes.recommendationPeriodPath}
          editUrl={routes.editRecommendationPeriodPath}
          deleteUrl={routes.recommendationPeriodPath}
          itemId={id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir o período de recomendação <b>${description}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="recommendationPeriod">
          <Typography component="h1" variant="h2" align="center">
            Períodos de Recomendações
          </Typography>
          <SearchBar
            url={urlRecommendationPeriod}
            query={query}
          />
          <NewButton
            url={urlNewRecommendationPeriod}
            description="Novo período"
          />
          <ListTable
            columns={columns}
            data={allRecommendationPeriod}
          />
        </div>
      </Container>
    </>
  );
};

export const recommendationPeriodPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
});

RecommendationPeriods.propTypes = {
  recommendationPeriods: PropTypes.arrayOf(recommendationPeriodPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionsPermitted: actionsPermittedPropType,
};

export default RecommendationPeriods;
