import React from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import ViewModalRow from './ViewModalRow';

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  tableContainer: {
    margin: 15,
  },
});

/**
 * Exibe uma modal para exibição de dados.
 *
 * As childrens devem estar com englobadas em tags <Grid item> </Grid>
 *
 * Define quando a modal está aberta/fechada
 * @open
 *
 * Título da modal
 * @title
 *
 * Parâmetros utilizados pelos botões de fechar
 * @actionsButtons
 */

const ViewModalTable = ({
  fields, data, title,
}) => {
  const classes = useStyles();

  const show = ({ type }) => type !== 'hidden';
  const filteredFields = fields.filter((field) => show(field));

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        {title && (
          <>
            <TableHead>
              <TableRow>
                <TableCell>{title}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          </>
        )}
        <TableBody>
          {filteredFields.map(({
            id, label, type, renderLabel, format, renderViewLabel, viewLabel,
          }) => (
            <ViewModalRow
              id={id}
              label={label}
              viewLabel={viewLabel}
              value={get(data, id)}
              type={type}
              key={id}
              renderLabel={renderLabel}
              format={format}
              renderViewLabel={renderViewLabel}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const fieldsPropType = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  renderLabel: PropTypes.func,
  renderViewLabel: PropTypes.func,
  format: PropTypes.string,
}));

export const dataPropType = PropTypes.any;

ViewModalTable.propTypes = {
  fields: fieldsPropType.isRequired,
  data: dataPropType.isRequired,
  title: PropTypes.string,
};

export default ViewModalTable;
