import React from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from './DropdownMenu';
import EditListItem from './EditListItem';
import DeleteListItem from './DeleteListItem';
import ViewListItem from './ViewListItem';
import { actionsPermittedPropType } from '../../services/GeneralTypes';

const DropdownActionButtons = (props) => {
  const {
    viewUrl,
    editUrl,
    itemId,
    deleteUrl,
    deleteMessage,
    children,
    actionsPermitted,
    currentPage,
  } = props;

  const isShowSeedLot = () => viewUrl === '/seed_lots/:id';

  const canRead = (
    !actionsPermitted
    || actionsPermitted.includes('read')
    || isShowSeedLot()
  ) && viewUrl;
  const canEdit = !actionsPermitted || actionsPermitted.includes('update');
  const canDelete = !actionsPermitted || actionsPermitted.includes('destroy');
  const disabled = !canEdit && !canDelete && !children && !canRead;

  return (
    <DropdownMenu disabled={disabled}>
      {canRead && (
        <ViewListItem
          path={viewUrl}
          itemId={itemId}
          currentPage={currentPage}
        />
      )}
      {canEdit && (
        <EditListItem
          path={editUrl}
          itemId={itemId}
          currentPage={currentPage}
        />
      )}
      {canDelete && (
        <DeleteListItem
          path={deleteUrl}
          itemId={itemId}
          message={deleteMessage}
          currentPage={currentPage}
        />
      )}
      {children}
    </DropdownMenu>
  );
};

DropdownActionButtons.propTypes = {
  viewUrl: PropTypes.string,
  editUrl: PropTypes.string,
  deleteUrl: PropTypes.string,
  itemId: PropTypes.string,
  deleteMessage: PropTypes.string,
  children: PropTypes.node,
  actionsPermitted: actionsPermittedPropType,
  currentPage: PropTypes.number,
};

export default DropdownActionButtons;
