import React from 'react';
import PropTypes from 'prop-types';
import convert from 'htmr';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonGroup, makeStyles } from '@material-ui/core';
import { ButtonPropType } from './FormModal';
/**
 * Exibe um <Dialog/> personalizado com botões de confirmação e cancelamento
 *
 * Define quando o dialog está aberto/fechada
 * @open
 *
 * Título do dialog
 * @title
 *
 * Parâmetros utilizados pelos botões de cancelamento e confirmação
 * @actionsButtons
 *
 * Mensagem exibida no dialog
 * @message
 */

const DialogConfirmation = ({
  message, title, actionsButtons, open,
}) => {
  const useStyles = makeStyles(() => ({
    buttonGroup: {
      float: 'right',
    },
  }));

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message && convert(message)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonGroup className={classes.buttonGroup}>
          <Button
            variant="text"
            color="primary"
            id="form-model-cancel-btn"
            type={actionsButtons.confirm.type}
            disabled={actionsButtons.cancel.disabled}
            onClick={actionsButtons.cancel.onClick}
            form={actionsButtons.cancel.form}
          >
            {actionsButtons.cancel.label || 'Cancelar'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="form-model-confirm-btn"
            type={actionsButtons.confirm.type || 'submit'}
            disabled={actionsButtons.confirm.disabled}
            onClick={actionsButtons.confirm.onClick}
            form={actionsButtons.confirm.form}
          >
            {actionsButtons.confirm.label || 'Confirmar'}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};


const actionsButtonsPropTypes = {
  confirm: PropTypes.shape({ label: PropTypes.string, ...ButtonPropType }),
  cancel: PropTypes.shape({ label: PropTypes.string, ...ButtonPropType }),
};

DialogConfirmation.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  actionsButtons: PropTypes.shape(actionsButtonsPropTypes),
  open: PropTypes.bool,
};

export default DialogConfirmation;
