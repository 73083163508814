import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { FormFor } from 'react-rails-form-helpers/lib/forms';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import PersonIcon from '@material-ui/icons/Person';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import Divider from '@material-ui/core/Divider';

import MenuLateral from './MenuLateral';
import { customerPropType } from '../views/Customers';

const HeaderToolbar = ({ user, routes, customer }) => {
  const { name, customers } = user;
  const [openMenuLateral, setOpenMenuLateral] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuLateralOpen = () => {
    setOpenMenuLateral(true);
  };

  const handleMenuLateralClose = () => {
    setOpenMenuLateral(false);
  };

  const useStyles = makeStyles((theme) => ({
    toolBar: {
      paddingRight: theme.spacing(0),
    },
    userNameBtn: {
      padding: theme.spacing(2.5, 2),
    },
    toolBarTitle: {
      flex: 1,
      padding: theme.spacing(1),
    },
    exitIcon: {
      marginLeft: theme.spacing(0.5),
    },
    profileIcon: {
      marginLeft: theme.spacing(1),
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      border: '1px solid #d3d4d5',
    },
    menuItem: {
      width: '100%',
    },
    context: {
      maxWidth: '200px',
    },
  }));

  const showContext = () => customers && customers.length > 1;

  const classes = useStyles();

  return (
    <>
      <MenuLateral
        routes={routes}
        handleClose={handleMenuLateralClose}
        open={openMenuLateral}
      />
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <MenuIcon onClick={handleMenuLateralOpen} style={{ cursor: 'pointer' }} />
          <Typography color="inherit" variant="h6" className={classes.toolBarTitle}>
            Aqila - Rastreabilidade
          </Typography>
          <Button
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.userNameBtn}
            color="inherit"
          >
            {name}
            <PersonIcon className={classes.profileIcon} />
          </Button>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{
              paper: classes.paper,
            }}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {showContext() && customer && (
              <div>
                <ListItem
                  component="div"
                >
                  <ListItemIcon>
                    <DeviceHubIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography noWrap className={classes.context} title={customer.name}>
                    {customer.name}
                  </Typography>
                </ListItem>
                <Divider />
              </div>
            )}
            {showContext() && (
              <FormFor
                url={routes.customersPath}
                method="get"
              >
                <MenuItem
                  button
                  component="button"
                  type="submit"
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    <SwapHorizIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Alterar Contexto" />
                </MenuItem>
              </FormFor>
            )}
            <FormFor
              url={routes.logoutIndexPath}
              method="post"
            >
              <MenuItem
                button
                component="button"
                type="submit"
                className={classes.menuItem}
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </MenuItem>
            </FormFor>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};

HeaderToolbar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    customers: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])),
  }),
  routes: PropTypes.objectOf(PropTypes.string),
  customer: customerPropType,
};

export default HeaderToolbar;
