import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Link } from '@material-ui/core';
import _ from 'lodash';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import Moment from 'moment';
import FormModal from '../../components/FormModal';
import UtilsService from '../../../services/UtilsService';
import FileUtilsService from '../../../services/FileUtilsService';
import MessageSnackBar from '../../components/MessageSnackBar';
import { cropPropType } from '../crop/Crops';
import { varietyPropType, varietyCategoryPropType } from '../variety/Varieties';
import { SeedLotPropType } from './SeedLots';
import { measureUnitType, cropPeriodType } from '../../../services/GeneralTypes';
import { fieldPropType } from '../field/Fields';
import { sievePropType } from '../sieve/Sieves';
import { producerPropType } from '../producer/Producers';
import { seedBeneficiationUnitType } from '../seedBeneficiationUnit/SeedBeneficiationUnit';
import TraceabilityDivider from '../../components/TraceabilityDivider';
import TabsModal from '../../components/TabsModal';
import FormService from '../../../services/FormService';
import useFields from './use-fields';


const getInitialDate = (date) => (date ? new Moment(date).utcOffset(date) : null);

const getSeedLotValue = (seedLot, path, defaultValue) => (
  UtilsService.getObjectValue(seedLot, path, defaultValue)
);

const getAdditionalInfo = (seedLot) => {
  const {
    others,
    wild,
    harmfulTolerated,
    harmfulForbidden,
  } = getSeedLotValue(seedLot, 'additionalInfo', {});

  return {
    others: others || '',
    wild: wild || '',
    harmfulTolerated: harmfulTolerated || '',
    harmfulForbidden: harmfulForbidden || '',
  };
};

const getGroundEmergency = (seedLot) => {
  const {
    firstReadDate,
    firstReadVigor,
    secondReadDate,
    secondReadViabilidade,
  } = getSeedLotValue(seedLot, 'groundEmergency', {});

  return {
    firstReadDate: getInitialDate(firstReadDate),
    firstReadVigor: firstReadVigor || '',
    secondReadDate: getInitialDate(secondReadDate),
    secondReadViabilidade: secondReadViabilidade || '',
  };
};

const initialTerms = (terms = []) => FileUtilsService.processInitialFile('terms', terms);

const initialImages = (images = []) => FileUtilsService.processInitialFile('images', images);

export const buildSeedLot = (seedLot) => {
  const terms = initialTerms(getSeedLotValue(seedLot, 'terms', []));
  const images = initialImages(getSeedLotValue(seedLot, 'images', []));

  if (FormService.hasData) {
    const {
      seedBedAnalysisDate,
      productionOrderDate,
      hasTerms,
      hasImages,
      ...formData
    } = FormService.data;

    return {
      ...formData,
      seedBedAnalysisDate: getInitialDate(seedBedAnalysisDate),
      productionOrderDate: getInitialDate(productionOrderDate),
      terms,
      images,
    };
  }

  // [key, defaultValue]
  /** @type {([string, any])[]} */
  const valuesToGet = [
    ['totalQuantity'],
    ['measureUnit', {}],
    ['lotNumber'],
    ['sieveSize'],
    ['productionFields', []],
    ['crop', {}],
    ['cropPeriod', ''],
    ['variety', {}],
    ['packingWeight'],
    ['varietyCategory', {}],
    ['seedQuantity'],
    ['productionOrderNumber'],
    ['productionOrderDate', null],
    ['sieve', {}],
    ['producer', {}],
    ['seedBeneficiationUnit', {}],
    ['purity'],
    ['vigor'],
    ['germination'],
    ['pms'],
    ['infestedSeeds'],
    ['inertMaterial'],
    ['averageSeedBed'],
    ['seedBedAnalysisDate', null],
    ['tetrazoliumViability', ''],
    ['tetrazoliumVigor', ''],
    ['laboratoryAttestationNumber', ''],
    ['higherAndGreaterVigor', ''],
    ['specialSeeds', false],
    ['traceabilityCode', ''],
    ['invoiceCodes', []],
    ['repacker', false],
  ];

  const initialValueResult = valuesToGet.reduce(
    (acc, [key, defaultValue]) => ({
      ...acc,
      [key]: getSeedLotValue(seedLot, key, defaultValue),
    }),
    {},
  );

  return {
    ...initialValueResult,
    images,
    terms,
    additionalInfo: getAdditionalInfo(seedLot),
    groundEmergency: getGroundEmergency(seedLot),
    productionOrderDate: getInitialDate(initialValueResult.productionOrderDate),
    seedBedAnalysisDate: getInitialDate(initialValueResult.seedBedAnalysisDate),
  };
};

const EditSeedLotModal = (props) => {
  const {
    routes,
    seedLot,
    measureUnits,
    crops,
    cropPeriods,
    varieties,
    productionFields: allProductionFields,
    varietyCategories,
    sieves,
    producers,
    seedBeneficiationUnits,
  } = props;

  const [filteredProductionFields, setFilteredProductionFields] = useState(allProductionFields);
  const [filteredSBUs, setFilteredSBUs] = useState(seedBeneficiationUnits);

  // States
  const [state, setState] = useState(() => buildSeedLot(seedLot));
  const [messageSnackBar, setMessageSnackBar] = useState({ variant: '', message: '' });
  const [open, setOpen] = useState(true);
  const [edit] = useState(!!seedLot);
  const [imagesFields, setImagesFields] = useState([]);
  const [termsFields, setTermsFields] = useState([]);
  const [defaultTermDescription, setDefaultTermDescription] = useState('');
  const [forceUpdate, setForceUpdate] = useState({
    images: false,
    terms: false,
  });

  const fields = useFields(state);

  const formUrl = edit
    ? routes.seedLotPath.replace(':id', seedLot.id)
    : routes.seedLotsPath;

  const tabs = [
    {
      label: 'Básico',
      icon: <AssignmentOutlinedIcon />,
      key: 'basic',
    },
    {
      label: 'Análise',
      icon: <FindInPageOutlinedIcon />,
      key: 'analyze',
    },
    {
      label: 'Imagens',
      icon: <PhotoLibraryOutlinedIcon />,
      key: 'images',
    },
    {
      label: 'Termos',
      icon: <DescriptionOutlinedIcon />,
      key: 'terms',
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (id, value) => {
    const newState = { ...state };

    _.set(newState, id, value);
    setState(newState);
  };

  const removeFile = (key, objectKey) => {
    setState((prevState) => {
      const newState = { ...prevState };
      const currentObj = newState[objectKey][key];

      if (currentObj.id) {
        currentObj.destroy = true;

        setForceUpdate({
          ...forceUpdate,
          [objectKey]: true,
        });
      } else {
        delete newState[objectKey][key];
      }

      return newState;
    });
  };

  const getFileFields = (key, objectKey, isLast, fileLabel, descriptionLabel, tab) => {
    const currentObj = _.get(state, [objectKey, key]);

    if (typeof currentObj !== 'object') return [];

    const index = Object.keys(state[objectKey]).indexOf(key);

    const hiddenClass = { display: 'none' };
    const fileFields = [
      {
        elementHeader: (
          <Grid item xs={12}>
            <TraceabilityDivider
              onClose={() => removeFile(key, objectKey)}
              disabled={isLast}
            />
          </Grid>
        ),
        id: `${objectKey}.${key}.file`,
        label: fileLabel,
        type: 'file',
        field: `${objectKey}_attributes[${index}][file]`,
        validations: [
          () => currentObj.hasFile || isLast || 'Arquivo é obrigatório',
          () => currentObj.validFileType || 'Arquivo inválido',
        ],
        gridSize: 11,
        tab,
        valueDefault: '',
        elementFooter: (
          <Grid item xs={1} style={{ paddingLeft: 0 }}>
            <Button fullWidth style={{ padding: 0, height: 36 }} disabled={!currentObj.fileUrl}>
              <Link href={currentObj.fileUrl} color="inherit" download>
                <GetAppIcon />
              </Link>
            </Button>
          </Grid>),
        fileName: currentObj.fileName,
      },
      {
        id: `${objectKey}.${key}.description`,
        label: descriptionLabel,
        type: 'text',
        field: `${objectKey}_attributes[${index}][description]`,
        tab,
        valueDefault: '',
      },
      {
        id: `${objectKey}.${key}.id`,
        label: '',
        type: 'text',
        field: currentObj.id ? `${objectKey}_attributes[${index}][id]` : '',
        fieldStyle: hiddenClass,
        tab,
        valueDefault: '',
      },
      {
        id: `${objectKey}.${key}.destroy`,
        label: '',
        type: 'text',
        field: `${objectKey}_attributes[${index}][_destroy]`,
        fieldStyle: hiddenClass,
        tab,
        valueDefault: '',
      },
    ];

    if (currentObj.destroy) {
      fileFields.forEach((field) => {
        const newField = field;

        newField.validations = [];
        newField.elementHeader = null;
        newField.elementFooter = null;
        newField.fieldStyle = hiddenClass;
      });
    }

    if (isLast) {
      fileFields.forEach((field) => {
        const newField = field;

        newField.field = '';
      });
    }

    return fileFields;
  };

  const setNewFile = (objectKey) => {
    setState((oldState) => {
      const newState = { ...oldState };
      const key = _.uniqueId(`${objectKey}_`);
      const newFile = FileUtilsService.initialFileState();

      if (objectKey === 'terms') {
        setDefaultTermDescription((newDescription) => {
          newFile.description = newDescription;
          return newDescription;
        });
      }

      newState[objectKey][key] = newFile;
      return newState;
    });
  };

  const validateHasFile = (event) => event.currentTarget.files.length > 0;

  const validateFileType = ({ currentTarget }) => {
    const type = _.get(currentTarget, 'files[0].type');
    const { accept } = currentTarget;

    if (type && accept) {
      const regex = new RegExp(`^${accept.replace('*', '.*')}$`);
      return regex.test(type);
    }

    return true;
  };

  const updateFileUrl = (fileObject, objectKey) => {
    const currentObj = fileObject;

    if (currentObj.fileUrl) {
      currentObj.fileUrl = '';

      setForceUpdate({
        ...forceUpdate,
        [objectKey]: true,
      });
    }
  };

  const onChangeFile = (objectKey, key, event, isLast) => {
    if (isLast) setNewFile(objectKey, key);

    const hasFile = validateHasFile(event);
    const validFileType = validateFileType(event);

    setState((prevState) => {
      const currentObj = prevState[objectKey][key];

      currentObj.hasFile = hasFile;
      currentObj.validFileType = validFileType;
      currentObj.id = '';

      updateFileUrl(currentObj, objectKey);

      return prevState;
    });
  };

  const createFileFields = (objectKey, fileLabel, descriptionLabel, tab) => (
    Object.keys(state[objectKey]).reduce((acc, key, index, objectKeys) => {
      const isLast = objectKeys.length === index + 1;
      const newFileFields = getFileFields(
        key,
        objectKey,
        isLast,
        fileLabel,
        descriptionLabel,
        tab,
      );

      const fileField = newFileFields.find(({ type }) => type === 'file');

      if (objectKey === 'images') fileField.accept = 'image/*';

      fileField.onChange = (_id, _value, event) => onChangeFile(objectKey, key, event, isLast);

      return acc.concat(newFileFields);
    }, [])
  );

  const updateDefaultTermDescription = () => {
    switch (_.get(state.varietyCategory, 'key')) {
      case 'c1':
      case 'c2':
        setDefaultTermDescription('Certificado de Conformidade');
        break;
      case 's1':
      case 's2':
        setDefaultTermDescription('Termo de Conformidade');
        break;
      default:
        setDefaultTermDescription('');
        break;
    }
  };

  const updateTermsDescriptions = () => {
    const newState = { ...state };
    _.forEach(newState.terms, (term) => {
      const newTerm = term;

      if (
        _.isEmpty(term.description)
        || term.description === 'Termo de Conformidade'
        || term.description === 'Certificado de Conformidade'
      ) {
        newTerm.description = defaultTermDescription;
      }
    });

    setState(newState);
  };

  const updateImagesFields = () => setImagesFields(
    createFileFields(
      'images',
      'Imagem',
      'Descrição da imagem',
      2,
    ),
  );
  const updateTermsFields = () => setTermsFields(
    createFileFields(
      'terms',
      'Termos',
      'Descrição do termo',
      3,
    ),
  );

  useEffect(
    updateImagesFields,
    [Object.keys(state.images).length],
  );

  useEffect(
    updateTermsFields,
    [Object.keys(state.terms).length],
  );

  useEffect(() => {
    const { images, terms } = forceUpdate;

    if (images) {
      updateImagesFields();
    }

    if (terms) {
      updateTermsFields();
    }

    if (images || terms) {
      setForceUpdate({
        terms: false,
        images: false,
      });
    }
  }, [forceUpdate]);

  useEffect(() => {
    const newState = { ...state };
    const productionField = newState.productionFields[0];
    const objectKeys = ['crop', 'variety', 'varietyCategory'];

    if (!_.isEmpty(productionField)) {
      objectKeys.forEach((key) => {
        if (_.isEmpty(newState[key]) && _.has(productionField, key)) {
          newState[key] = productionField[key];
        }
      });
    }

    setState(newState);
  }, [state.productionFields[0]]);

  useEffect(() => {
    const { producer } = state;
    if (_.isEmpty(producer)) {
      setFilteredProductionFields(allProductionFields);
      setFilteredSBUs(seedBeneficiationUnits);
    } else {
      const selectedProducer = producers.find((p) => p.id === producer.id);
      const filterField = (field) => field.producer.id === producer.id;
      const filterSBU = (sbu) => selectedProducer.seedBeneficiationUnit.length === 0
        || selectedProducer.seedBeneficiationUnit.find((producerSbu) => producerSbu.id === sbu.id);

      const filteredFields = allProductionFields.filter(filterField);
      setFilteredProductionFields(filteredFields);

      const sbus = seedBeneficiationUnits.filter(filterSBU);

      if (sbus.length > 0) {
        setFilteredSBUs(sbus);
        if (sbus.length === 1) {
          handleChange('seedBeneficiationUnit', sbus[0]);
        }
      } else {
        setFilteredSBUs(seedBeneficiationUnits);
      }
    }
  }, [state.producer]);

  useEffect(() => {
    const { productionFields } = state;
    handleChange(
      'productionFields',
      productionFields.filter(
        (field) => filteredProductionFields.some((filtered) => filtered.id === field.id),
      ),
    );
  }, [filteredProductionFields]);

  useEffect(() => {
    const { seedBeneficiationUnit } = state;

    if (seedBeneficiationUnit && !filteredSBUs.find((sbu) => sbu.id === seedBeneficiationUnit.id)) {
      handleChange('seedBeneficiationUnit', {});
    }
  }, [filteredSBUs]);

  useEffect(updateDefaultTermDescription, [state.varietyCategory]);

  useEffect(updateTermsDescriptions, [defaultTermDescription]);


  useEffect(() => {
    const { data, hasData } = FormService;

    if (!hasData) return;

    let message = '';

    if (data.hasImages && data.hasTerms) message = 'Não foi possível recuperar os arquivos';
    else if (data.hasImages) message = 'Não foi possível recuperar as imagens';
    else if (data.hasTerms) message = 'Não foi possível recuperar os termos';

    if (message) {
      setMessageSnackBar({
        message,
        variant: 'warning',
      });
    }
  }, []);

  const additionalInfoFields = {
    measureUnit: {
      values: measureUnits,
    },
    sieve: {
      values: sieves,
    },
    producer: {
      values: producers,
    },
    seedBeneficiationUnit: {
      values: filteredSBUs,
    },
    productionFields: {
      values: filteredProductionFields,
    },
    crop: {
      values: crops,
      warnings: [
        (v) => !(
          _.has(state.productionFields[0], 'crop.id')
          && _.has(v, 'id')
          && v.id !== state.productionFields[0].crop.id
        ) || 'Esta safra não está cadastrada no primeiro campo de produção',
      ],
    },
    cropPeriod: {
      values: cropPeriods,
    },
    variety: {
      values: varieties,
      warnings: [
        (v) => !(
          _.has(state.productionFields[0], 'variety.id')
          && _.has(v, 'id')
          && v.id !== state.productionFields[0].variety.id
        ) || 'Esta safra não está cadastrada no primeiro campo de produção',
      ],
    },
    varietyCategory: {
      warnings: [
        (v) => !(
          _.has(state.productionFields[0], 'varietyCategory.id')
          && _.has(v, 'id')
          && v.id !== state.productionFields[0].varietyCategory.id
        ) || 'Esta categoria não está cadastrada no primeiro campo de produção',
      ],
      values: varietyCategories,
    },
    specialSeeds: {
      checked: !!state.specialSeeds,
    },
    repacker: {
      checked: !!state.repacker,
    },
  };

  const generalFields = UtilsService.updateFields(fields, additionalInfoFields);

  const validInputs = () => (
    UtilsService.validateState([
      ...generalFields,
      ...imagesFields,
      ...termsFields,
    ], state)
  );

  const onFormSubmit = () => {
    const { images, terms, ...filtedState } = state;

    const hasFile = (file) => !(file.id && !file.destroy) && FileUtilsService.hasValidFile(file);

    FormService.data = {
      ...filtedState,
      hasTerms: Object.values(terms).some(hasFile),
      hasImages: Object.values(images).some(hasFile),
    };
  };

  return (
    <>
      <FormModal
        open={open}
        title="Lote de Semente"
        maxWidth="60%"
        maxHeight="85%"
        formForProps={{
          url: formUrl,
          method: edit ? 'put' : 'post',
          encType: 'multipart/form-data',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        <TabsModal
          tabs={tabs}
          fields={[
            ...generalFields,
            ...imagesFields,
            ...termsFields,
          ]}
          handleChange={handleChange}
          value={state}
        />
      </FormModal>
      {messageSnackBar.message && messageSnackBar.variant && (
        <MessageSnackBar
          message={messageSnackBar.message}
          variant={messageSnackBar.variant}
        />
      )}
    </>
  );
};

EditSeedLotModal.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
  seedLot: SeedLotPropType,
  varieties: PropTypes.arrayOf(varietyPropType),
  crops: PropTypes.arrayOf(cropPropType),
  cropPeriods: PropTypes.arrayOf(PropTypes.shape(cropPeriodType)),
  measureUnits: PropTypes.arrayOf(PropTypes.shape(measureUnitType)),
  productionFields: PropTypes.arrayOf(fieldPropType),
  varietyCategories: PropTypes.arrayOf(varietyCategoryPropType),
  sieves: PropTypes.arrayOf(sievePropType),
  producers: PropTypes.arrayOf(producerPropType),
  seedBeneficiationUnits: PropTypes.arrayOf(seedBeneficiationUnitType),
};

export default EditSeedLotModal;
