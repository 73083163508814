import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ViewModal from '../../components/ViewModal';
import { fields, buildMeasureUnit } from './EditMeasureUnitModal';
import { measureUnitType } from '../../../services/GeneralTypes';

const ViewMeasureModal = ({ measureUnit }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Unidade de Medida"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildMeasureUnit(measureUnit)}
      />
    </>
  );
};

ViewMeasureModal.propTypes = {
  measureUnit: PropTypes.shape(measureUnitType),
};

export default ViewMeasureModal;
