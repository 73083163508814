import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, IconButton, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

const useStyles = makeStyles({
  divider: {
    backgroundColor: '#7b7b7b',
    flex: 1,
  },
  closeButton: {
    padding: 5,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

function TraceabilityDivider({ onClose, title, disabled }) {
  const css = useStyles();
  return (
    <div className={css.root}>
      <Typography variant="subtitle1">{title}</Typography>
      <Divider className={css.divider} />
      {!onClose || (
      <IconButton
        onClick={onClose}
        disabled={disabled}
        className={css.closeButton}
      >
        <CloseRounded fontSize="small" />
      </IconButton>
      )}
    </div>
  );
}

TraceabilityDivider.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TraceabilityDivider;
