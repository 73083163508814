import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const Crops = (props) => {
  const {
    crops,
    query,
    routes,
    actionsPermitted,
  } = props;
  const [allCrops, setCrops] = useState([]);

  const urlNewCrop = routes.newCropPath;
  const urlCrops = routes.cropsPath;

  useEffect(() => {
    setCrops(crops.map((crop) => {
      const updatedCrop = { ...crop, id: crop.id };
      return updatedCrop;
    }));
  }, [crops]);

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Data Início',
      cell: (row) => moment(row.startDate).utcOffset(row.startDate).format('DD/MM/YYYY'),
    },
    {
      header: 'Data Fim',
      cell: (row) => moment(row.endDate).utcOffset(row.endDate).format('DD/MM/YYYY'),
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.cropPath}
          editUrl={routes.editCropPath}
          deleteUrl={routes.cropPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a safra <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="crops">
          <Typography component="h1" variant="h2" align="center">
            Safras
          </Typography>
          <SearchBar
            url={urlCrops}
            query={query}
          />
          <NewButton
            url={urlNewCrop}
            description="Nova Safra"
          />
          <ListTable
            columns={columns}
            data={allCrops}
          />
        </div>
      </Container>
    </>
  );
};

export const cropPropType = PropTypes.shape({
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.string,
});

Crops.propTypes = {
  crops: PropTypes.arrayOf(cropPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default Crops;
