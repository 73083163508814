
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import FloatingPrintFieldInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { printFieldPropType } from './PrintFields';
import FormModal from '../../components/FormModal';
import { labelPropType } from '../label/Labels';

const alignments = [
  {
    description: 'Esquerda',
    id: 'left',
  },
  {
    description: 'Centralizado',
    id: 'center',
  },
  {
    description: 'Direita',
    id: 'right',
  },
  {
    description: 'Justificado',
    id: 'justify',
  },
];


const EditPrintFieldModal = (props) => {
  const {
    routes,
    printField,
    label,
    availableFields,
  } = props;

  const getInitialState = () => {
    if (printField) {
      return {
        description: printField.description,
        height: printField.height,
        width: printField.width,
        x: printField.x,
        y: printField.y,
        fontSize: printField.fontSize || '',
        field: availableFields.find((field) => field.id === printField.field),
        align: alignments.find((alignment) => alignment.id === printField.align),
      };
    }

    return {
      description: '',
      height: '',
      width: '',
      x: '',
      y: '',
      fontSize: '',
      field: {},
      align: alignments[1],
    };
  };

  const [state, setState] = useState(getInitialState());
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(printField));

  const fields = [
    {
      id: 'description',
      label: 'Descrição',
      type: 'text',
      field: 'description',
      validations: [
        (v) => !!v || 'Descrição é obrigatória',
      ],
      visible: true,
      autoFocus: true,
      required: true,
    },
    {
      id: 'field',
      label: 'Campo',
      type: 'autocomplete',
      field: 'field',
      validations: [
        (v) => !isEmpty(v) || 'Campo é obrigatório',
      ],
      visible: true,
      required: true,
      values: availableFields,
      renderLabel: (field) => field.description,
    },
    {
      id: 'x',
      label: 'Coordenada X (cm)',
      type: 'number',
      field: 'x',
      validations: [
        (v) => !!v || 'Coordenada X é obrigatória',
      ],
      visible: true,
      required: true,
    },
    {
      id: 'y',
      label: 'Coordenada Y (cm)',
      type: 'number',
      field: 'y',
      validations: [
        (v) => !!v || 'Coordenada Y é obrigatória',
      ],
      visible: true,
      required: true,
    },
    {
      id: 'height',
      label: 'Altura (cm)',
      type: 'number',
      field: 'height',
      validations: [
        (v) => !!v || 'Altura é obrigatória',
      ],
      visible: true,
      required: true,
    },
    {
      id: 'width',
      label: 'Largura (cm)',
      type: 'number',
      field: 'width',
      validations: [
        (v) => !!v || 'Largura é obrigatória',
      ],
      visible: true,
      required: true,
    },
    {
      id: 'fontSize',
      label: 'Tamanho da Fonte',
      type: 'number',
      field: 'font_size',
      validations: [
      ],
      visible: true,
      required: false,
    },
    {
      id: 'align',
      label: 'Alinhamento',
      type: 'autocomplete',
      field: 'align',
      validations: [
      ],
      values: alignments,
      renderLabel: (alignment) => alignment.description,
      visible: true,
      required: true,
    },
  ];

  const validInputs = () => UtilsService.validateState(fields, state);
  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      const path = routes.printFieldPath;
      return path.replace(':id', printField.id);
    }

    return routes.labelPrintFieldsPath.replace(':id', label.id);
  };

  return (
    <>
      <FormModal
        title="Campos de Impressão"
        open={open}
        formForProps={{
          method: edit ? 'put' : 'post',
          url: getUrlForm(),
        }}
        actionsButtons={{
          confirm: {
            printField: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >

        {fields.map((
          {
            id,
            label: labelText,
            type,
            field,
            validations,
            placeholder,
            format,
            required,
            autoFocus,
            values,
            renderLabel,
          },
        ) => (
          <FloatingPrintFieldInput
            key={field}
            id={id}
            label={labelText}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            values={values}
            renderLabel={renderLabel}
          />
        ))}
      </FormModal>
    </>
  );
};

EditPrintFieldModal.propTypes = {
  printField: printFieldPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  label: labelPropType,
  availableFields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })),
};

export default EditPrintFieldModal;
