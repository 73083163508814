import React, { useState } from 'react';
import { unitConversionPropType } from './UnitConversions';
import { fields, buildUnitConversion } from './EditUnitConversionModal';
import ViewModal from '../../components/ViewModal';

const ViewUnitConversionModal = (props) => {
  const { unitConversion } = props;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Unidade de Conversão"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildUnitConversion(unitConversion)}
      />
    </>
  );
};

ViewUnitConversionModal.propTypes = {
  unitConversion: unitConversionPropType,
};

export default ViewUnitConversionModal;
