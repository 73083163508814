import React from 'react';

const IconUsuarios = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="usuarios" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.36">
      <path d="M12.4,35.28a7.82,7.82,0,1,0-7.81-7.82A7.83,7.83,0,0,0,12.4,35.28Zm54.7,0a7.82,7.82,0,1,0-7.82-7.82A7.82,7.82,0,0,0,67.1,35.28ZM71,39.18H63.19a7.78,7.78,0,0,0-5.5,2.27,17.84,17.84,0,0,1,9.16,13.36h8.06a3.91,3.91,0,0,0,3.91-3.91V47A7.82,7.82,0,0,0,71,39.18Zm-31.26,0A13.68,13.68,0,1,0,26.08,25.51,13.66,13.66,0,0,0,39.75,39.18Zm9.38,3.91h-1a18.9,18.9,0,0,1-16.72,0h-1A14.07,14.07,0,0,0,16.31,57.15v3.52a5.86,5.86,0,0,0,5.86,5.86H57.33a5.86,5.86,0,0,0,5.86-5.86V57.15A14.07,14.07,0,0,0,49.13,43.09ZM21.81,41.45a7.78,7.78,0,0,0-5.5-2.27H8.49A7.82,7.82,0,0,0,.68,47v3.9a3.91,3.91,0,0,0,3.91,3.91h8a17.91,17.91,0,0,1,9.18-13.36Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconUsuarios;
