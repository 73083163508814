import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const Species = (props) => {
  const {
    species,
    query,
    routes,
    actionsPermitted,
  } = props;
  const [allSpecie, setSpecie] = useState([]);

  const urlNewSpecie = routes.newSpeciesPath;
  const urlSpecie = routes.speciesIndexPath;

  useEffect(() => {
    setSpecie(species.map((specie) => {
      const updatedSpecie = { ...specie, id: specie.id };
      return updatedSpecie;
    }));
  }, [species]);

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.speciesPath}
          editUrl={routes.editSpeciesPath}
          deleteUrl={routes.speciesPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a atividade <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="specie">
          <Typography component="h1" variant="h2" align="center">
            Atividades
          </Typography>
          <SearchBar
            url={urlSpecie}
            query={query}
          />
          <NewButton
            url={urlNewSpecie}
            description="Nova Atividade"
          />
          <ListTable
            columns={columns}
            data={allSpecie}
          />
        </div>
      </Container>
    </>
  );
};

export const speciePropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
});

Species.propTypes = {
  species: PropTypes.arrayOf(speciePropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default Species;
