import React from 'react';

const IconPlantabilityRecommendations = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="plantabilityRecommendations" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Plantabilidade</title>
      <path d="M58.9,13.6H48.3a8.6,8.6,0,0,0-17.1,0H20.6A6.4,6.4,0,0,0,14.2,20V66.9a6.4,6.4,0,0,0,6.4,6.4H58.9a6.4,6.4,0,0,0,6.4-6.4V20A6.4,6.4,0,0,0,58.9,13.6ZM39.7,10.4a3.2,3.2,0,0,1,3.2,3.2,3.2,3.2,0,1,1-6.3,0A3.2,3.2,0,0,1,39.7,10.4ZM27,22.9a.8.8,0,0,1,.8-.8H51.7a.8.8,0,0,1,.8.8v2.7a.8.8,0,0,1-.8.8H27.8a.8.8,0,0,1-.8-.8ZM42.2,64.7A1.3,1.3,0,0,1,41,65.9H38.5a1.3,1.3,0,0,1-1.2-1.2V53.8A16.9,16.9,0,0,1,20.4,36.9h4.9A16.9,16.9,0,0,1,42.2,53.8Zm1.7-15.8a19.5,19.5,0,0,0-4.4-8.1,16.7,16.7,0,0,1,14.7-8.7h4.9A17,17,0,0,1,43.9,48.9Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconPlantabilityRecommendations;
