import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DynamicComponent from '../DynamicComponent';
import { SeedLotPropType } from '../seedLot/SeedLots';
import { regionPropType } from '../region/Regions';
import { unitConversionPropType } from '../unit_conversion/UnitConversions';
import { SeedTreatmentPropType } from '../seedTreatment/SeedTreatments';

const LandingPageContent = (props) => {
  const {
    config,
    seedLot,
    regions,
    unitConversions,
    seedTreatment,
  } = props;

  return (
    <>
      <Grid container spacing={3}>
        {config.content.body.map((component) => (
          <DynamicComponent
            key={component.uuid}
            config={config}
            seedLot={seedLot}
            block={component}
            regions={regions}
            unitConversions={unitConversions}
            seedTreatment={seedTreatment}
          />
        ))}
      </Grid>
    </>
  );
};

LandingPageContent.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  seedLot: SeedLotPropType,
  regions: PropTypes.arrayOf(regionPropType),
  unitConversions: PropTypes.arrayOf(unitConversionPropType),
  seedTreatment: SeedTreatmentPropType,
};

export default LandingPageContent;
