/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';
import MaterialTable, { MTableBodyRow } from 'material-table';
import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import Remove from '@material-ui/icons/Remove';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Cancel from '@material-ui/icons/Cancel';
import Clear from '@material-ui/icons/Clear';

import { recommendationPeriodPropType } from '../recommendationPeriod/RecommendationPeriods';
import MessageSnackBar from '../../components/MessageSnackBar';

const BatchPlantabilityRecommendationTable = (props) => {
  const {
    recommendationPeriods,
    hasData,
  } = props;

  const [state, setState] = useState({
    columns: [
    ],
    data: [
    ],
  });

  const [message, setMessage] = useState(null);
  const periodField = 'recommendation_period';

  useEffect(() => {
    hasData(false);
  }, []);

  useEffect(() => {
    const lookup = {
      select: 'Selecione um Período',
      ...recommendationPeriods.reduce((result, item) => {
        // eslint-disable-next-line no-param-reassign
        result[item.id] = item.description;
        return result;
      }, {}),
    };

    const columns = [
      {
        title: 'Período*',
        field: periodField,
        lookup,
        initialEditValue: 'select',
      },
      { title: 'Fertilidade Baixa', field: 'low', type: 'numeric' },
      { title: 'Fertilidade Média', field: 'medium', type: 'numeric' },
      { title: 'Fertilidade Alta', field: 'high', type: 'numeric' },
    ];

    const data = state.data.filter(
      (line) => some(
        recommendationPeriods,
        ['id', line[periodField]],
      ),
    );
    setState({ columns, data });
  }, [recommendationPeriods]);

  useEffect(() => {
    const { data } = state;
    hasData(data.length > 0);
  }, [state]);

  const validateRow = (data) => {
    if (!data[periodField] || data[periodField] === 'select') {
      setMessage('Período é obrigatório!');
      return false;
    }

    if (!(data.low || data.medium || data.high)) {
      setMessage(
        // eslint-disable-next-line max-len
        'Informe a recomendação de plantas/ha para, ao menos, uma das fertilidades (baixa, média ou alta)!',
      );
      return false;
    }

    return true;
  };

  const validatePeriod = (newData) => {
    const { data } = state;
    const periodId = newData[periodField];

    const invalid = data.some((element) => element[periodField] === periodId);
    if (invalid) {
      setMessage('Período já informado! Selecione outro período disponivel.');
      return false;
    }

    return true;
  };

  const onRowAdd = (newData) => new Promise((resolve, reject) => {
    if (!validateRow(newData) || !validatePeriod(newData)) {
      return reject();
    }

    setState((prevState) => {
      const data = [...prevState.data];
      data.push(newData);
      return { ...prevState, data };
    });
    return resolve();
  });

  const onRowUpdate = (newData, oldData) => new Promise((resolve, reject) => {
    if (!validateRow(newData)) {
      return reject();
    }

    if (oldData) {
      setState((prevState) => {
        const data = [...prevState.data];
        data[data.indexOf(oldData)] = newData;
        return { ...prevState, data };
      });
    }
    return resolve();
  });

  const onRowDelete = (oldData) => new Promise((resolve) => {
    setState((prevState) => {
      const data = [...prevState.data];
      data.splice(data.indexOf(oldData), 1);
      return { ...prevState, data };
    });
    return resolve();
  });

  const icons = {
    Add,
    Check,
    ThirdStateCheck: Remove,
    Edit,
    Delete: DeleteOutline,
    Cancel,
    Clear,
  };

  return (
    <>
      <Grid item xs={12}>
        <MaterialTable
          title="Recomendação Plantas/ha"
          columns={state.columns}
          data={state.data}
          icons={icons}
          options={{
            search: false,
            paging: false,
            actionsColumnIndex: -1,
            draggable: false,
            sorting: false,
            thirdSortClick: false,
          }}
          components={{
            Row: (rowProps) => (
              <>
                <MTableBodyRow {...rowProps} />
                <tr style={{ display: 'none' }}>
                  {state.columns.map(({ field }) => (
                    <td
                      style={{ display: 'none' }}
                      key={field}
                    >
                      <input
                        aria-invalid="false"
                        name={`rows[${rowProps.index}]${field}`}
                        type="hidden"
                        value={rowProps.data[field]}
                      />
                    </td>
                  ))}
                </tr>
              </>
            ),
          }}
          localization={{
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum registro...',
              addTooltip: 'Adicionar',
              deleteTooltip: 'Remover',
              editTooltip: 'Editar',
              editRow: {
                deleteText: 'Você tem certeza de que deseja deletar essa linha?',
                cancelTooltip: 'Cancelar',
                saveTooltip: 'Confirmar',
              },
            },
          }}
          editable={{
            onRowAdd,
            onRowDelete,
            onRowUpdate,
          }}
        />
      </Grid>
      <MessageSnackBar
        variant="error"
        message={message}
        onClose={() => setMessage(null)}
      />
    </>
  );
};

BatchPlantabilityRecommendationTable.propTypes = {
  recommendationPeriods: PropTypes.arrayOf(recommendationPeriodPropType),
  hasData: PropTypes.func.isRequired,
};

export default BatchPlantabilityRecommendationTable;
