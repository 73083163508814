import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const AlertDialog = ({ options }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="inherit" onClick={handleClickOpen}>
        {options.caption}
        {options.icon}
      </Button>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{options.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {options.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={options.handleClickClose || handleClickClose} color="primary">
            Não
          </Button>
          <Button onClick={options.handleClickOk} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.propTypes = {
  options: PropTypes.shape({
    caption: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    icon: PropTypes.element,
    handleClickOk: PropTypes.func,
    handleClickClose: PropTypes.func,
  }),
};

export default AlertDialog;
