import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import { userProfileAccessPropType } from '../userProfileAccess/UserProfilesAccess';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const Users = (props) => {
  const {
    users,
    query,
    routes,
    actionsPermitted,
  } = props;

  const columns = [
    {
      header: 'Nome',
      cell: ({ name }) => name,
    },
    {
      header: 'CPF',
      cell: ({ cpf }) => cpf,
    },
    {
      header: 'Perfil do usuário',
      cell: ({ userProfileAccess }) => userProfileAccess.description,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.userPath}
          editUrl={routes.editUserPath}
          deleteUrl={routes.userPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir o usuário <b>${row.name}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div>
          <Typography component="h1" variant="h2" align="center">
            Usuários
          </Typography>
          <SearchBar
            url={routes.usersPath}
            query={query}
          />

          <ListTable
            columns={columns}
            data={users}
          />
        </div>
      </Container>
    </>
  );
};

export const userPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  admin: PropTypes.bool.isRequired,
  userProfileAccess: userProfileAccessPropType.isRequired,
});

export const simpleUserPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  customers: PropTypes.arrayOf(PropTypes.number),
});

Users.propTypes = {
  users: PropTypes.arrayOf(userPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default Users;
