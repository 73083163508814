import React from 'react';

const IconUnidadeDeMedidas = () => {
  const styles = {
    fillcolor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="unidadeDeMedidas" style={styles.fillcolor} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 79.5 78.36">
      <path d="M76.44,28.84,67.19,13.1a3.75,3.75,0,0,0-5.06-1.34l-8,4.55,6.93,11.81a.91.91,0,0,1-.33,1.25l-1.61.9a.92.92,0,0,1-1.26-.33L50.91,18.13l-6.42,3.64,3.25,5.5a.92.92,0,0,1-.34,1.25l-1.6.9a.93.93,0,0,1-1.27-.33l-3.24-5.5-6.42,3.64,7,11.81a.91.91,0,0,1-.33,1.25l-1.61.9a.92.92,0,0,1-1.26-.33L31.68,29l-6.41,3.64,3.24,5.5a.92.92,0,0,1-.34,1.25l-1.6.9A.93.93,0,0,1,25.3,40l-3.24-5.52-6.42,3.64,6.94,11.81a.9.9,0,0,1-.34,1.25l-1.6.9a.92.92,0,0,1-1.27-.34L12.44,40l-8,4.55a3.61,3.61,0,0,0-1.36,5l9.25,15.75a3.73,3.73,0,0,0,5,1.34L75.09,33.82a3.62,3.62,0,0,0,1.35-5Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconUnidadeDeMedidas;
