import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { simpleUserPropType } from '../user/Users';
import { regionPropType } from '../region/Regions';
import LandingPageTheme from '../landing/LandingPageTheme';
import { speciePropType } from '../species/Species';
import { varietyPropType } from '../variety/Varieties';
import SalesCalculator from './SalesCalculator';
import { unitConversionPropType } from '../unit_conversion/UnitConversions';

const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: '13.7em',
    marginTop: theme.spacing(4),
    minHeight: theme.spacing(5),
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    letterSpacing: '14px',
    textDecoration: 'none',
  },
  h2: {
    fontSize: '2.25rem',
    marginTop: 0,
    marginBottom: '8px',
  },
  gridContainer: {
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
  },
}));

const SalesCalculatorPage = (props) => {
  const {
    config,
    routes,
    user,
    regions,
    species,
    varieties,
    variety,
    unitConversions,
  } = props;

  const { salesCalculator } = config.content;
  const classes = useStyles();

  if (!salesCalculator) {
    return (
      <>
        <LandingPageTheme
          config={config}
          routes={routes}
          user={user}
        >
          <Grid container style={{ width: 'auto' }} className={classes.gridContainer}>
            <Grid item xs={12}>
              <Typography component="h1" className={classes.h1} align="center">
                404
              </Typography>
              <Typography component="h2" className={classes.h2} align="center">
                Página não encontrada :(
              </Typography>
            </Grid>
          </Grid>
        </LandingPageTheme>
      </>
    );
  }

  return (
    <>
      <LandingPageTheme
        config={config}
        routes={routes}
        user={user}
      >
        <SalesCalculator
          config={config}
          routes={routes}
          user={user}
          regions={regions}
          species={species}
          varieties={varieties}
          variety={variety}
          unitConversions={unitConversions}
        />
      </LandingPageTheme>
    </>
  );
};

SalesCalculatorPage.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.objectOf(PropTypes.string),
  user: simpleUserPropType,
  regions: PropTypes.arrayOf(regionPropType),
  species: PropTypes.arrayOf(speciePropType),
  varieties: PropTypes.arrayOf(varietyPropType),
  variety: varietyPropType,
  unitConversions: PropTypes.arrayOf(unitConversionPropType),
};

export default withStyles({ withTheme: true })(SalesCalculatorPage);
