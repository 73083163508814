import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { get, truncate } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Button from '@material-ui/core/Button';
import CardAvatar from '../../components/Card/CardAvatar';
import CardTitle from '../../components/Card/CardTitle';
import { SeedLotPropType } from '../seedLot/SeedLots';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  tile: {
    width: '100%',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const AttachmentsCard = (props) => {
  const {
    title,
    seedLot,
    attribute,
    subComponents,
  } = props;
  const attachments = get(seedLot, attribute).filter((attachment) => attachment.fileUrl);
  const classes = useStyles();

  const truncateDescription = (attachment) => truncate(attachment.description, { length: 50 });

  if (attachments.length === 0) return null;

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          avatar={(
            <CardAvatar>
              <AttachmentIcon />
            </CardAvatar>
          )}
          title={<CardTitle title={title || 'Imagens'} />}
        />
        <CardContent>
          <div className={classes.root}>
            {attachments.map((attachment, index) => (
              <Button
                variant="outlined"
                color="secondary"
                href={attachment.fileUrl}
                target="_blank"
                key={attachment.id}
                className={classes.button}
                title={attachment.description}
              >
                {truncateDescription(attachment) || `Arquivo ${index + 1}`}
              </Button>
            ))}
          </div>
        </CardContent>
      </Card>
      {subComponents}
    </Grid>
  );
};

AttachmentsCard.propTypes = {
  title: PropTypes.string,
  seedLot: SeedLotPropType,
  attribute: PropTypes.string.isRequired,
  subComponents: PropTypes.arrayOf(PropTypes.element),
};

export default AttachmentsCard;
