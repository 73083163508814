import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import searchBackground from '../../../images/search_background.jpg';
import SearchCard from './SearchCard';
import { simpleUserPropType } from '../user/Users';
import { SeedLotPropType } from '../seedLot/SeedLots';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const LandingPageTheme = ({
  routes,
  config,
  user,
  lotNumber,
  lots,
}) => {
  const { searchPage } = config.content;

  const backgroundImage = (searchPage || {}).image || searchBackground;

  const BackgroundHead = {
    backgroundImage: `url(${backgroundImage})`,
  };

  const classes = useStyles();

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className={classes.image}
          style={BackgroundHead}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <SearchCard
            routes={routes}
            config={config}
            user={user}
            lotNumber={lotNumber}
            lots={lots}
          />
        </Grid>
      </Grid>
    </>
  );
};

LandingPageTheme.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  user: simpleUserPropType,
  lotNumber: PropTypes.string,
  lots: PropTypes.arrayOf(SeedLotPropType),
};

const SearchLandingPage = (props) => {
  const {
    config,
    routes,
    user,
    lotNumber,
    lots,
  } = props;

  const style = {
    overrides: {
      MuiCard: {
        root: {
          marginTop: '30px',
          overflow: 'visible',
        },
      },
      MuiCardHeader: {
        root: {
          padding: '0 16px',
        },
      },
    },
    ...config.style,
  };

  const theme = createMuiTheme(style);

  return (
    <>
      <ThemeProvider theme={theme}>
        <LandingPageTheme
          config={config}
          routes={routes}
          user={user}
          lots={lots}
          lotNumber={lotNumber}
        />
      </ThemeProvider>
    </>
  );
};


SearchLandingPage.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  user: simpleUserPropType,
  lotNumber: PropTypes.string,
  lots: PropTypes.arrayOf(SeedLotPropType),
};

export default SearchLandingPage;
