import React from 'react';

const IconPerfisUsuarios = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="perfisUsuarios" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.36">
      <path d="M72.63,50.58a13,13,0,0,0,0-4.82l2.92-1.69a.82.82,0,0,0,.38-1,17,17,0,0,0-3.76-6.5.83.83,0,0,0-1-.16l-2.92,1.69a13.23,13.23,0,0,0-4.18-2.41V32.36a.83.83,0,0,0-.64-.81,17.2,17.2,0,0,0-7.49,0,.83.83,0,0,0-.65.81v3.37a13.34,13.34,0,0,0-4.18,2.41l-2.92-1.69a.82.82,0,0,0-1,.16,17,17,0,0,0-3.76,6.5.83.83,0,0,0,.37,1l2.92,1.69a13.46,13.46,0,0,0,0,4.82l-2.92,1.69a.81.81,0,0,0-.37,1,17,17,0,0,0,3.76,6.49.8.8,0,0,0,1,.16l2.92-1.68a13.53,13.53,0,0,0,4.18,2.41V64a.81.81,0,0,0,.65.8,16.9,16.9,0,0,0,7.49,0,.82.82,0,0,0,.64-.8V60.61a13.42,13.42,0,0,0,4.18-2.41l2.92,1.68a.81.81,0,0,0,1-.16,17,17,0,0,0,3.76-6.49.83.83,0,0,0-.38-1Zm-13,3.08a5.49,5.49,0,1,1,5.49-5.49A5.5,5.5,0,0,1,59.67,53.66ZM14.4,37.3a7.24,7.24,0,1,0-7.24-7.24A7.24,7.24,0,0,0,14.4,37.3Zm25.35,3.63c.22,0,.42-.06.64-.07a20.75,20.75,0,0,1,4.11-6.7,4.43,4.43,0,0,1,3.27-1.43,4.33,4.33,0,0,1,2.22.6l.89.52.27-.16a12.53,12.53,0,0,0,1.27-5.43A12.67,12.67,0,1,0,39.75,40.93Zm11.91,22-.77-.44a5.62,5.62,0,0,1-3.11,1.11,4.47,4.47,0,0,1-3.27-1.43A20.59,20.59,0,0,1,40,54.3c-1.21-3.9,2.82-5.62,2.92-5.69,0-.29,0-.59,0-.88l-.9-.52a4.73,4.73,0,0,1-1.11-.92c-.37,0-.73.07-1.1.07A17.92,17.92,0,0,1,32,44.55h-.94a13,13,0,0,0-13,13v3.26a5.44,5.44,0,0,0,5.44,5.43h28.9a4.35,4.35,0,0,1-.7-2.3v-1ZM23.13,43A7.25,7.25,0,0,0,18,40.93H10.78a7.24,7.24,0,0,0-7.24,7.24v3.62a3.61,3.61,0,0,0,3.62,3.62h7.46A16.59,16.59,0,0,1,23.13,43Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconPerfisUsuarios;
