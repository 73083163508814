import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { FormFor } from 'react-rails-form-helpers';
import { Container, Typography, Paper } from '@material-ui/core';

const Customers = ({ customers, routes }) => {
  const [custumerId, setId] = React.useState('');

  const handleChange = (event) => {
    setId(event.target.value);
  };

  const customersMenus = customers.map(({ id, name }) => (
    <MenuItem value={id} key={id}> {name} </MenuItem>
  ));

  const inputLabel = React.useRef(null);
  const [labelWidth] = React.useState(155);

  const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: theme.spacing(7),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paperBox: {
      width: 600,
      marginTop: theme.spacing(3),
      padding: theme.spacing(9),
      boxShadow: theme.shadows[3],
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
  }));

  const getUrlForm = () => {
    const path = routes.changeContextCustomerPath;
    return path.replace(':id', custumerId);
  };

  const classes = useStyles();

  const validInputs = () => !!custumerId;

  return (
    <Container component="main" className={classes.root}>
      <div>
        <Typography component="h1" variant="h5" align="center">
          Rastreabilidade
        </Typography>
        <Paper className={classes.paperBox}>
          <FormFor method="post" name="customers" url={getUrlForm()}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={inputLabel}
                    id="label-for-context"
                  >
                    Selecione o contexto
                  </InputLabel>
                  <Select
                    labelId="select-label-for-context"
                    id="select-label"
                    value={custumerId}
                    key={custumerId}
                    onChange={handleChange}
                    labelWidth={labelWidth}
                  >
                    {customersMenus}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={!validInputs()}
                  >
                    Entrar
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </FormFor>
        </Paper>
      </div>
    </Container>
  );
};

export const customerPropType = PropTypes.shape({
  table: PropTypes.shape({
    codeGpa: PropTypes.number,
    id: PropTypes.object,
    name: PropTypes.string,
  }),
});

Customers.propTypes = {
  customers: PropTypes.arrayOf(customerPropType).isRequired,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Customers;
