import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import HeaderToolbar from './HeaderToolbar';
import Footer from './Footer';
import { simpleUserPropType } from '../user/Users';


const LandingPageTheme = ({
  config,
  routes,
  user,
  children,
}) => {
  const useStyles = makeStyles((pageTheme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: pageTheme.palette.background.default,
      paddingBottom: pageTheme.spacing(1),
      minHeight: 'calc(100vh - 152px)',
    },
  }));

  const style = {
    overrides: {
      MuiCard: {
        root: {
          marginTop: '30px',
          overflow: 'visible',
        },
      },
      MuiCardHeader: {
        root: {
          padding: '0 16px',
        },
      },
    },
    ...config.style,
  };

  const theme = createMuiTheme(style);


  const classes = useStyles();
  const { header } = config.content;

  return (
    <>
      <ThemeProvider theme={theme}>
        <HeaderToolbar
          config={config}
          routes={routes}
          user={user}
        />
        <div className={classes.root}>
          <Container maxWidth="lg">
            {children}
          </Container>
        </div>
        {header.showAqilaReferences && <Footer />}
      </ThemeProvider>
    </>
  );
};

LandingPageTheme.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.objectOf(PropTypes.string),
  user: simpleUserPropType,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default withStyles({ withTheme: true })(LandingPageTheme);
