import React, { useState } from 'react';
import ViewModal from '../../components/ViewModal';
import { rulePropType } from './SeedQualityRules';
import { fields, buildSeedQualityRule } from './EditSeedQualityRuleModal';

const ViewSeedQualityRuleModal = (props) => {
  const {
    seedQualityRule,
  } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ViewModal
      open={open}
      title="Regra"
      actionsButtons={{
        close: {
          onClick: handleClose,
        },
      }}
      fields={fields}
      data={buildSeedQualityRule(seedQualityRule)}
    />
  );
};


ViewSeedQualityRuleModal.propTypes = {
  seedQualityRule: rulePropType,
};

export default ViewSeedQualityRuleModal;
