import React from 'react';

const IconLote = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="lote" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Lotes</title>
      <path d="M73.5,29.7,66.8,9.5a6.5,6.5,0,0,0-6.1-4.4H41.9V30.7H73.7A3,3,0,0,0,73.5,29.7ZM37.6,5.1H18.8a6.5,6.5,0,0,0-6.1,4.4L6,29.7a3,3,0,0,0-.2,1H37.6ZM5.7,34.9v32A6.4,6.4,0,0,0,12,73.3H67.5a6.4,6.4,0,0,0,6.3-6.4v-32Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconLote;
