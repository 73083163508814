import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import EcoIcon from '@material-ui/icons/Eco';
import ListTable from '../../components/ListTable';
import RedirectListItem from '../../components/RedirectListItem';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const Fields = (props) => {
  const {
    fields,
    query,
    routes,
    actionsPermitted,
  } = props;

  const urlNewField = routes.newFieldPath;
  const urlFields = routes.fieldsPath;

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Cultivar',
      cell: (row) => `${row.variety.description} (${row.specie.description})`,
    },
    {
      header: 'Categoria',
      cell: (row) => `${(row.varietyCategory || {}).description || ''}`,
    },
    {
      header: 'Safra',
      cell: (row) => `${row.crop.description}`,
    },
    {
      header: 'Área',
      cell: (row) => `${row.approvedArea}`,
    },
    {
      header: 'Produtor',
      cell: (row) => `${row.producer.name}`,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.fieldPath}
          editUrl={routes.editFieldPath}
          deleteUrl={routes.fieldPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir o campo de produção <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        >
          {routes.fieldHarvestsPath && (
            <RedirectListItem
              path={routes.fieldHarvestsPath}
              itemId={row.id}
              description="Colheitas"
              icon={<EcoIcon fontSize="small" />}
            />
          )}
        </DropdownActionButtons>
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="fields">
          <Typography component="h1" variant="h2" align="center">
            Campos de Produção
          </Typography>
          <SearchBar
            url={urlFields}
            query={query}
          />
          <NewButton
            url={urlNewField}
            description="Novo Campo"
          />
          <ListTable
            columns={columns}
            data={fields}
          />
        </div>
      </Container>
    </>
  );
};


export const fieldPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
  approvedArea: PropTypes.number,
});

Fields.propTypes = {
  fields: PropTypes.arrayOf(fieldPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default Fields;
