import React from 'react';

const IconProdutor = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="produtores" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Produtores</title>
      <path d="M13.2,33.3A7.3,7.3,0,1,0,5.9,26h0A7.3,7.3,0,0,0,13.2,33.3Zm50.9,0a7.3,7.3,0,0,0,0-14.6A7.3,7.3,0,0,0,56.8,26h0A7.3,7.3,0,0,0,64.1,33.3Zm3.6,3.6H60.4A7.5,7.5,0,0,0,55.3,39a16.7,16.7,0,0,1,8.5,12.4h7.5A3.7,3.7,0,0,0,75,47.8V44.2A7.3,7.3,0,0,0,67.7,36.9Zm-29.1,0A12.7,12.7,0,1,0,25.9,24.2,12.7,12.7,0,0,0,38.6,36.9Zm8.8,3.7h-1a17.6,17.6,0,0,1-15.5,0h-1a13.1,13.1,0,0,0-13.1,13h0v3.3a5.5,5.5,0,0,0,5.5,5.5H55a5.5,5.5,0,0,0,5.4-5.5h0V53.6a13,13,0,0,0-13-13ZM21.9,39a7.4,7.4,0,0,0-5.1-2.1H9.6a7.3,7.3,0,0,0-7.3,7.3v3.6a3.7,3.7,0,0,0,3.6,3.7h7.5A16.9,16.9,0,0,1,21.9,39Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconProdutor;
