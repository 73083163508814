import React from 'react';
import PropTypes from 'prop-types';

const IconCultivar = ({ fillColor }) => {
  const style = {
    fillCollor: {
      fill: fillColor || '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="cultivar" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Cultivares</title>
      <path d="M12.1,17.4H3.4A30.5,30.5,0,0,0,33.9,47.9V67.5a2.3,2.3,0,0,0,2.2,2.2h4.3a2.3,2.3,0,0,0,2.2-2.2V47.9A30.5,30.5,0,0,0,12.1,17.4ZM64.4,8.7A30.3,30.3,0,0,0,37.8,24.4a35.5,35.5,0,0,1,8,14.6A30.5,30.5,0,0,0,73.1,8.7Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

IconCultivar.propTypes = {
  fillColor: PropTypes.string,
};

export default IconCultivar;
