import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PdfUtilsService from '../../../services/PdfUtilsService';

const ViewPdfRecommendations = ({ pdf }) => {
  const openWindow = () => (PdfUtilsService.openPdfWindow(pdf));

  useEffect(() => {
    if (pdf) {
      openWindow();
    }
  }, []);

  return <></>;
};

ViewPdfRecommendations.propTypes = {
  pdf: PropTypes.string.isRequired,
};

export default ViewPdfRecommendations;
