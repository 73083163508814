import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';

const RedirectListItem = (props) => {
  const {
    itemId,
    path,
    forwardedRef,
    description,
    icon,
  } = props;
  const urlRedirect = path.replace(':id', itemId);

  return (
    <ListItem
      button
      component="a"
      href={urlRedirect}
      ref={forwardedRef}
    >
      <ListItemIcon>
        {icon ? (
          <>
            {icon}
          </>
        ) : (
          <SettingsIcon fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText primary={description} />
    </ListItem>
  );
};

RedirectListItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  forwardedRef: PropTypes.func,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <RedirectListItem {...props} forwardedRef={ref} />);
