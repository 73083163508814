import SessionStorageService from './SessionStorageService';

const FormService = {
  storeIdentifier: 'formData',

  get storeValue() {
    return SessionStorageService.getItem(this.storeIdentifier);
  },

  set data(data) {
    const storeValue = {
      key: window.location.pathname,
      data,
    };

    SessionStorageService.setItem(this.storeIdentifier, storeValue);
  },

  get data() {
    const { storeValue } = this;

    return storeValue && storeValue.data;
  },

  get storeKey() {
    const { storeValue } = this;

    return storeValue && storeValue.key;
  },

  clearFormStore() {
    SessionStorageService.removeItem(this.storeIdentifier);
  },

  get hasData() {
    return SessionStorageService.hasKey(this.storeIdentifier);
  },
};

export default FormService;
