import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { speciePropType } from './Species';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    visible: true,
    autoFocus: true,
    required: true,
  },
];

const emptyState = {
  description: '',
};

export const buildSpecie = (specie) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  if (specie) {
    return {
      description: specie.description,
    };
  }

  return emptyState;
};

const EditSpecieModal = (props) => {
  const { routes, specie } = props;

  const [state, setState] = useState(() => buildSpecie(specie));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(specie));

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => setOpen(false);

  const onFormSubmit = () => { FormService.data = state; };

  const urlForm = edit ? routes.speciesPath.replace(':id', specie.id) : routes.speciesIndexPath;

  return (
    <>
      <FormModal
        open={open}
        title="Atividade"
        formForProps={{
          url: urlForm,
          method: edit ? 'put' : 'post',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {fields.map(({
          id, label, type, field, validations, placeholder, format, required, autoFocus,
        }) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
          />
        ))}
      </FormModal>
    </>
  );
};

EditSpecieModal.propTypes = {
  specie: speciePropType,
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditSpecieModal;
