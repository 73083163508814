import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { isEmpty, get } from 'lodash';
import { FormFor } from 'react-rails-form-helpers/lib/forms';

import { regionPropType } from '../region/Regions';
import CardAvatar from '../../components/Card/CardAvatar';
import CardTitle from '../../components/Card/CardTitle';
import PlantabilityCalculator from './PlantabilityCalculator';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import { speciePropType } from '../species/Species';
import { varietyPropType } from '../variety/Varieties';
import VarietyIcon from '../../components/icons/Cultivares';
import { unitConversionPropType } from '../unit_conversion/UnitConversions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  gridContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  h1: {
    fontSize: '13.7em',
    marginTop: theme.spacing(4),
    minHeight: theme.spacing(5),
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    letterSpacing: '14px',
    textDecoration: 'none',
  },
  h2: {
    fontSize: '2.25rem',
    marginTop: 0,
    marginBottom: '8px',
  },
  loadButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    margin: theme.spacing(2),
  },
  form: {
    width: '100%',
  },
  svg: {
    fillColor: theme.palette.primary.main,
  },
  grid: {
    overflow: 'hidden',
  },
}));

const SalesCalculator = (props) => {
  const {
    config,
    routes,
    regions,
    species,
    varieties,
    variety: selectedVariety,
    unitConversions,
  } = props;

  const { salesCalculator } = config.content;
  const classes = useStyles();

  const {
    type,
    titleCalculator,
    useVigor,
    titleVarieties,
    efficiency,
    spendType,
  } = salesCalculator.props;

  const getInitialState = () => {
    if (selectedVariety) {
      return {
        variety: varieties.find((variety) => variety.id === selectedVariety.id),
        specie: species.find((specie) => specie.id === selectedVariety.specie.id),
      };
    }

    return {
      specie: {},
      variety: {},
    };
  };

  const [state, setState] = useState(getInitialState());
  const [filteredVarieties, setFilteredVarieties] = useState([]);

  const color = get(config, 'style.toolbar.text.color');

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  useEffect(() => {
    const specieId = (state.specie || {}).id;
    const filtered = (varieties || []).filter((variety) => variety.specie.id === specieId);
    setFilteredVarieties(filtered);
    handleChange('variety', filtered.find((variety) => variety.id === (selectedVariety || {}).id));
  }, [state.specie]);

  const fields = [
    {
      id: 'specie',
      label: 'Selecione uma Atividade',
      type: 'autocomplete',
      field: 'specie_id',
      validations: [
        (v) => !isEmpty(v) || 'Atividade é obrigatória',
      ],
      required: true,
      values: species,
      renderLabel: (specie) => specie.description,
    },
    {
      id: 'variety',
      label: 'Selecione uma Cultivar',
      type: 'autocomplete',
      field: 'variety_id',
      validations: [
        (v) => !isEmpty(v) || 'Cultivar é obrigatória',
      ],
      required: true,
      values: filteredVarieties,
      disabled: !state.specie,
      renderLabel: (variety) => variety.description,
    },
  ];

  const getUrl = () => {
    if (!state.variety) {
      return routes.calculatorIndexPath;
    }

    const url = routes.calculatorPath;
    return url.replace(':variety_id', state.variety.id);
  };

  return (
    <>
      <Grid item xs={12} className={classes.grid}>
        <Card>
          <CardHeader
            avatar={(
              <CardAvatar>
                <VarietyIcon
                  fillColor="currentColor"
                />
              </CardAvatar>
            )}
            title={(
              <CardTitle
                title={titleVarieties || 'Cultivares'}
              />
            )}
          />
          <CardContent>
            <div className={classes.root}>
              <FormFor
                url={getUrl()}
                method="get"
                className={classes.form}
              >
                <Grid container className={classes.gridContainer} spacing={3}>
                  {fields.map((
                    {
                      id,
                      label,
                      type: inputType,
                      field,
                      validations,
                      placeholder,
                      format,
                      required,
                      renderLabel,
                      values,
                    },
                  ) => (
                    <Grid item xs={12} md={3} lg={3} key={id}>
                      <FloatingLabelInput
                        key={field}
                        id={id}
                        label={label}
                        type={inputType}
                        validationRules={validations}
                        value={state[id]}
                        onChange={handleChange}
                        name={field}
                        placeholder={placeholder}
                        format={format}
                        required={required}
                        renderLabel={renderLabel}
                        values={values}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid container className={classes.gridContainer} spacing={3}>
                  <Button
                    style={{ color }}
                    className={classes.loadButton}
                    type="submit"
                    title="Carregar Sugestões de Plantabilidade"
                  >
                    Carregar Sugestões de Plantabilidade
                  </Button>
                </Grid>
              </FormFor>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <PlantabilityCalculator
        regions={regions}
        title={titleCalculator}
        type={type}
        useVigor={useVigor}
        variety={selectedVariety}
        efficiency={efficiency}
        spendType={spendType}
        unitConversions={unitConversions}
      />
    </>
  );
};

SalesCalculator.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.objectOf(PropTypes.string),
  regions: PropTypes.arrayOf(regionPropType),
  species: PropTypes.arrayOf(speciePropType),
  varieties: PropTypes.arrayOf(varietyPropType),
  variety: varietyPropType,
  unitConversions: PropTypes.arrayOf(unitConversionPropType),
};

export default withStyles({ withTheme: true })(SalesCalculator);
