import React, { useState } from 'react';
import ViewModal from '../../components/ViewModal';
import { userPropType } from './Users';
import { fields, buildUser } from './EditUserModal';

const ViewUserModal = (props) => {
  const {
    user,
  } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  return (
    <ViewModal
      open={open}
      title="Usuário"
      actionsButtons={{
        close: {
          onClick: handleClose,
        },
      }}
      fields={fields}
      data={buildUser(user)}
    />
  );
};

ViewUserModal.propTypes = {
  user: userPropType,
};

export default ViewUserModal;
