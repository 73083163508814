import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import { fieldPropType } from '../field/Fields';
import NewButton from '../../components/NewButton';
import BackButton from '../../components/BackButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const Harvests = (props) => {
  const {
    harvests,
    query,
    field,
    routes,
    actionsPermitted,
  } = props;
  const { newFieldHarvestPath, fieldHarvestsPath } = routes;

  const urlNewHarvest = newFieldHarvestPath && newFieldHarvestPath.replace(':id', field.id);
  const urlHarvests = fieldHarvestsPath && fieldHarvestsPath.replace(':id', field.id);
  const urlFields = routes.fieldsPath;

  const useStyles = makeStyles(() => ({
    root: {
      marginBottom: '1rem',
    },
  }));

  const classes = useStyles();

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Data Colheita',
      cell: (row) => moment(row.harvestDate).utcOffset(row.harvestDate).format('DD/MM/YYYY'),
    },
    {
      header: 'Quantidade',
      cell: (row) => `${row.amount} (${(row.measureUnit || {}).abbreviation})`,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.harvestPath}
          editUrl={routes.editHarvestPath}
          deleteUrl={routes.harvestPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a colheita <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="harvests">
          <Typography component="h1" variant="h2" align="center">
            Colheitas
          </Typography>
          <Typography component="h4" variant="h4" align="center">
            Campo de Produção: {field.description}
          </Typography>
          <SearchBar
            url={urlHarvests}
            query={query}
          />
          <Grid
            container
            spacing={3}
            classes={classes}
          >
            <Grid item xs={6}>
              <BackButton
                url={urlFields}
                description="Retornar para Campos de Produção"
              />
            </Grid>
            <Grid item xs={6}>
              <NewButton
                url={urlNewHarvest}
                description="Nova Colheita"
              />
            </Grid>
          </Grid>
          <ListTable
            columns={columns}
            data={harvests}
          />
        </div>
      </Container>
    </>
  );
};


export const harvestPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
  harvestDate: PropTypes.date,
  amount: PropTypes.number,
  field: fieldPropType,
});

Harvests.propTypes = {
  harvests: PropTypes.arrayOf(harvestPropType).isRequired,
  field: fieldPropType,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default Harvests;
