import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import { find } from 'lodash';
import { varietyPropType } from '../variety/Varieties';
import { regionPropType } from '../region/Regions';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import { fertilityHumanize } from '../plantabilityRecommendation/PlantabilityRecommendations';
import { TYPE_SEEDING_RATE } from '../../../services/constants';

const PlantabilitySuggestion = (props) => {
  const {
    variety,
    classes,
    regions,
    type: seedingType,
  } = props;

  const isTypeSeedingRate = () => seedingType === TYPE_SEEDING_RATE;

  const initialInputState = {
    region: null,
    fertility: null,
    period: null,
    suggestion: '',
  };

  const fertilities = Object.entries(fertilityHumanize)
    .map(([name, description]) => ({ name, description }));

  const [inputState, setState] = useState(initialInputState);

  const handleChange = (id, value) => {
    const updateFields = {
      ...inputState,
      [id]: value,
    };

    setState(updateFields);
  };

  const betweenDatesIgnoringYear = (selectedDate, beginString, endString) => {
    const dateFormat = '____-MM-DD';
    const begin = Moment(beginString, dateFormat);
    const end = Moment(endString, dateFormat).add(1, 'd');
    const date = Moment(selectedDate.toDate(), dateFormat);

    if (begin.isAfter(end)) {
      end.year(end.year() + 1);
    }

    return date.isBetween(begin, end) || date.add(1, 'y').isBetween(begin, end);
  };

  useEffect(() => {
    const { period, fertility, region } = inputState;
    if (!period || !fertility || !region) {
      handleChange('suggestion', '');
      return;
    }

    const recommendations = (variety || {}).plantabilityRecommendation || [];

    const filter = (recommendation) => {
      const {
        fertility: recommendationFertility,
        region: recommendationRegion,
        recommendationPeriod,
      } = recommendation;

      return recommendationFertility === fertility.name
        && recommendationRegion.id === region.id
        && betweenDatesIgnoringYear(period,
          recommendationPeriod.startDate, recommendationPeriod.endDate);
    };

    const filtered = find(recommendations, filter);

    handleChange('suggestion', (filtered || {}).plantsNumber || 'Não Recomendado');
  }, [inputState.period, inputState.fertility, inputState.region]);

  const getSuggestionField = () => {
    const { suggestion: plantsSuggestion } = inputState;
    if (typeof plantsSuggestion === 'string') {
      return (
        <Typography gutterBottom variant="h5" className={classes.suggestionFieldError}>
          {plantsSuggestion}
        </Typography>
      );
    }
    let message = '';
    const suggestion = plantsSuggestion.toLocaleString();
    if (isTypeSeedingRate()) {
      message = `${suggestion} plantas/m linear`;
    } else {
      message = `${suggestion} plantas/ha`;
    }

    return (
      <Typography gutterBottom variant="h5" className={classes.suggestionField}>
        {message}
      </Typography>
    );
  };

  return (
    <>
      {regions && regions.length > 0 && (
        <Grid container className={classes.gridContainer} spacing={3}>
          <Grid item xs={12}>
            <FormLabel
              component="legend"
              className={classes.marginBottom1Spacing}
            >
              Selecione Local de Produção e Dados do Plantio:
            </FormLabel>
          </Grid>
          {[
            {
              id: 'region',
              label: 'Região',
              values: regions,
              renderLabel: (element) => element.description,
            },
            {
              id: 'fertility',
              label: 'Fertilidade',
              values: fertilities,
              renderLabel: (element) => element.description,
            },
            {
              id: 'period',
              label: 'Período',
              type: 'datePicker',
              format: 'DD/MM',
              renderLabel: (element) => element.description,
            },
          ].map(({
            id,
            label,
            values,
            renderLabel,
            type,
            format,
          }) => (
            <Grid item xs={12} md={3} lg={3} key={id}>
              <FloatingLabelInput
                key={id}
                id={id}
                label={label}
                type={type || 'autocomplete'}
                value={inputState[id]}
                onChange={handleChange}
                name={id}
                renderLabel={renderLabel}
                values={values}
                format={format}
              />
            </Grid>
          ))}
          <Grid item xs={12} className={classes.gridItem}>
            <Typography gutterBottom variant="h6">
              Sugestão de Plantabilidade
            </Typography>
            {getSuggestionField()}
          </Grid>
        </Grid>
      )}
    </>
  );
};

PlantabilitySuggestion.propTypes = {
  variety: varietyPropType,
  classes: PropTypes.objectOf(PropTypes.string),
  regions: PropTypes.arrayOf(regionPropType),
  type: PropTypes.string.isRequired,
};

export default PlantabilitySuggestion;
