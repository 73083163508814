import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormFor } from 'react-rails-form-helpers';
import PropTypes from 'prop-types';
import {
  Container, Link, makeStyles, Paper,
} from '@material-ui/core';
import * as EmailValidator from 'email-validator';
import { isValidCpf } from '@brazilian-utils/is-valid-cpf';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import ResetPasswordModal from './ResetPasswordModal';

const useStyles = makeStyles((theme) => ({
  paperBox: {
    maxWidth: 600,
    marginTop: theme.spacing(3),
    padding: theme.spacing(9),
    boxShadow: theme.shadows[3],
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },
  submitBtn: {
    marginTop: 20,
  },
}));

const Login = (props) => {
  const { routes } = props;
  const classes = useStyles();
  const initialState = {
    password: '',
    login: '',
  };

  const [state, setState] = useState(initialState);
  const [modalOpen, setModalOpen] = React.useState(false);

  const fields = [
    {
      id: 'login',
      label: 'Usuário',
      type: 'text',
      field: 'login',
      validations: [
        (v) => !!v || 'Usuário é obrigatório',
        (v) => (/^\D/.test(v) ? (EmailValidator.validate(v) || 'E-mail inválido') : true),
        (v) => (/^\d/.test(v) ? (isValidCpf(v) || 'CPF inválido') : true),
      ],
      autoFocus: true,
      required: true,
    },
    {
      id: 'password',
      label: 'Senha',
      type: 'password',
      field: 'password',
      validations: [
        (v) => !!v || 'Senha é obrigatória',
      ],
      autoFocus: false,
      required: true,
    },
  ];

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const validInputs = () => UtilsService.validateState(fields, state);

  return (
    <>
      <Container component="main" className={classes.container}>
        <div>
          <Typography component="h1" variant="h5" align="center">
          Rastreabilidade
          </Typography>
          <Paper className={classes.paperBox}>
            <FormFor method="post" url={routes.loginIndexPath} className={classes.form}>
              <Grid container spacing={3}>
                {fields.map(({
                  id, label, type, field, validations, required, autoFocus,
                }) => (
                  <FloatingLabelInput
                    key={field}
                    id={id}
                    label={label}
                    type={type}
                    value={state[id]}
                    onChange={handleChange}
                    validationRules={validations}
                    name={field}
                    required={required}
                    autoFocus={autoFocus}
                  />
                ))}
                <Grid item xs={12}>
                  <Button
                    onClick={() => setModalOpen(true)}
                    fullWidth
                    component={Link}
                  >
                    Esqueceu sua senha
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.submitBtn}
                    disabled={!validInputs()}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Entrar
                  </Button>
                </Grid>
              </Grid>
            </FormFor>
          </Paper>
        </div>
      </Container>
      <ResetPasswordModal
        open={modalOpen}
        routes={routes}
        handleClose={handleModalClose}
      />
    </>
  );
};

Login.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
};

export default Login;
