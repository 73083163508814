import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { userProfileAccessPropType } from './UserProfilesAccess';
import { fields, buildUserProfileAccess } from './EditUserProfileAccessModal';
import ViewModal from '../../components/ViewModal';
import ViewModalTable from '../../components/ViewModalTable';

const ViewUserProfileAccessModal = (props) => {
  const {
    userProfileAccess,
    modules,
  } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  const buildModuleFields = (module) => (
    module.actions.map((action) => (
      {
        id: action.key,
        label: action.description,
        type: 'checkbox',
      }
    ))
  );

  const buildModudeData = (module) => {
    const response = {};
    const permissions = userProfileAccess.permissions || {};
    module.actions.forEach((action) => {
      response[action.key] = (permissions[module.key] || []).includes(action.key);
    });

    return response;
  };

  return (
    <ViewModal
      open={open}
      title="Perfil de Usuário"
      actionsButtons={{
        close: {
          onClick: handleClose,
        },
      }}
      fields={fields}
      data={buildUserProfileAccess(userProfileAccess)}
    >
      <>
        {modules.map((module) => (
          <ViewModalTable
            key={module.key}
            title={module.description}
            fields={buildModuleFields(module)}
            data={buildModudeData(module)}
          />
        ))}
      </>
    </ViewModal>
  );
};

ViewUserProfileAccessModal.propTypes = {
  userProfileAccess: userProfileAccessPropType,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        description: PropTypes.string,
      })),
    }),
  ),
};

export default ViewUserProfileAccessModal;
