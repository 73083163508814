import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import GrainIcon from '@material-ui/icons/Grain';
import SettingsIcon from '@material-ui/icons/Settings';
import FormModal from '../../components/FormModal';
import TabsModal from '../../components/TabsModal';
import UtilsService from '../../../services/UtilsService';
import { fieldPropType } from './Fields';
import { producerPropType } from '../producer/Producers';
import { speciePropType } from '../species/Species';
import { cropPropType } from '../crop/Crops';
import { varietyPropType, varietyCategoryPropType } from '../variety/Varieties';
import { measureUnitType } from '../../../services/GeneralTypes';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    autoFocus: true,
    required: true,
  },
  {
    id: 'producer',
    label: 'Selecione um Produtor',
    viewLabel: 'Produtor',
    type: 'autocomplete',
    field: 'producer_id',
    required: true,
    renderLabel: (producer) => producer.name,
  },
  {
    id: 'specie',
    label: 'Selecione uma Atividade',
    viewLabel: 'Atividade',
    type: 'autocomplete',
    field: 'specie_id',
    required: true,
    renderLabel: (specie) => specie.description,
  },
  {
    id: 'variety',
    label: 'Selecione uma Cultivar',
    viewLabel: 'Cultivar',
    type: 'autocomplete',
    field: 'variety_id',
    required: true,
    renderLabel: (variety) => variety.description,
    gridSize: 6,
  },
  {
    id: 'varietyCategory',
    label: 'Selecione uma Categoria da Cultivar',
    viewLabel: 'Categoria da Cultivar',
    type: 'autocomplete',
    field: 'variety_category_id',
    required: true,
    renderLabel: (category) => category.description,
    gridSize: 6,
  },
  {
    id: 'crop',
    label: 'Selecione uma Safra',
    viewLabel: 'Safra',
    type: 'autocomplete',
    field: 'crop_id',
    required: true,
    renderLabel: (crop) => crop.description,
  },
  {
    id: 'latitude',
    label: 'Latitude',
    type: 'number',
    field: 'latitude',
    validations: [],
    gridSize: 6,
  },
  {
    id: 'longitude',
    label: 'Longitude',
    type: 'number',
    field: 'longitude',
    validations: [],
    gridSize: 6,
  },
  {
    id: 'approvedArea',
    label: 'Área Aprovada',
    type: 'number',
    field: 'approved_area',
    required: true,
    gridSize: 6,
  },
  {
    id: 'productionEstimate',
    label: 'Estimativa de Produção',
    type: 'number',
    field: 'production_estimate',
    required: true,
    gridSize: 6,
  },
  {
    id: 'plantingDate',
    label: 'Data do Plantio',
    type: 'datePicker',
    field: 'planting[date]',
    placeholder: '01/01/2020',
    format: 'DD/MM/YYYY',
    required: false,
    validations: [],
    tab: 1,
    gridSize: 6,
  },
  {
    id: 'harvestDate',
    label: 'Data de colheita',
    type: 'datePicker',
    field: 'planting[harvest_date]',
    placeholder: '01/01/2020',
    format: 'DD/MM/YYYY',
    required: false,
    validations: [],
    tab: 1,
    gridSize: 6,
  },
  {
    id: 'floweringInspectionReportDate',
    label: 'Data do laudo de vistoria de floração',
    type: 'datePicker',
    field: 'planting[flowering_inspection_report_date]',
    placeholder: '01/01/2020',
    format: 'DD/MM/YYYY',
    required: false,
    validations: [],
    tab: 1,
    gridSize: 6,
  },
  {
    id: 'preHarvestReportDate',
    label: 'Data do laudo de pré-colheita',
    type: 'datePicker',
    field: 'planting[pre_harvest_report_date]',
    placeholder: '01/01/2020',
    format: 'DD/MM/YYYY',
    required: false,
    validations: [],
    tab: 1,
    gridSize: 6,
  },
  {
    id: 'plantingArea',
    label: 'Área Plantada',
    type: 'number',
    field: 'planting[area]',
    required: false,
    validations: [],
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'plantingAmount',
    label: 'Quantidade Plantada',
    type: 'number',
    field: 'planting[amount]',
    validations: [],
    required: false,
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'measureUnit',
    label: 'Selecione uma Unidade de Medida',
    viewLabel: 'Unidade de Medida',
    type: 'autocomplete',
    field: 'planting[measure_unit_id]',
    visible: true,
    renderLabel: (unit) => unit.description,
    tab: 1,
    gridSize: 6,
  },
];


const emptyState = {
  description: '',
  producer: null,
  specie: null,
  variety: null,
  crop: null,
  latitude: '',
  longitude: '',
  approvedArea: '',
  productionEstimate: '',
  plantingArea: '',
  plantingDate: null,
  harvestDate: null,
  floweringInspectionReportDate: null,
  preHarvestReportDate: null,
  plantingAmount: '',
  measureUnit: null,
  varietyCategory: null,
};

const toMoment = (date) => (date ? new Moment(date).utcOffset(date) : null);

export const buildField = (field) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  if (field) {
    return {
      plantingDate: toMoment((field.planting || {}).date),
      harvestDate: toMoment((field.planting || {}).harvestDate),
      floweringInspectionReportDate: toMoment((field.planting || {}).floweringInspectionReportDate),
      preHarvestReportDate: toMoment((field.planting || {}).preHarvestReportDate),
      description: field.description,
      producer: field.producer,
      specie: field.specie,
      variety: field.variety,
      crop: field.crop,
      latitude: (field.geolocation || {}).latitude || '',
      longitude: (field.geolocation || {}).longitude || '',
      approvedArea: field.approvedArea,
      productionEstimate: field.productionEstimate,
      plantingArea: (field.planting || {}).area || '',
      plantingAmount: (field.planting || {}).amount || '',
      measureUnit: (field.planting || {}).measureUnit,
      varietyCategory: field.varietyCategory,
    };
  }

  return emptyState;
};

const EditFieldModal = (props) => {
  const {
    routes,
    field,
    producers,
    species,
    varieties,
    crops,
    measureUnits,
    varietyCategories,
  } = props;

  const urlFields = routes.fieldsPath;

  const [state, setState] = useState(() => buildField(field));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(field));
  const [filteredVarieties, setFilteredVarieties] = useState([]);

  const values = {
    measureUnit: measureUnits,
    crop: crops,
    varietyCategory: varietyCategories,
    variety: filteredVarieties,
    specie: species,
    producer: producers,
  };

  const disabled = {
    variety: !state.specie,
  };

  const validations = {
    description: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    producer: [
      (v) => !!v || 'Produtor é obrigatório',
    ],
    specie: [
      (v) => !!v || 'Atividade é obrigatória',
    ],
    variety: [
      (v) => !!v || 'Cultivar é obrigatória',
    ],
    varietyCategory: [
      (v) => !!v || 'Categoria da Cultivar é obrigatória',
    ],
    approvedArea: [
      (v) => !!v || 'Área aprovada é obrigatória',
    ],
    productionEstimate: [
      (v) => !!v || 'Estimativa de produção é obrigatória',
    ],
    measureUnit: [
      (v) => (!state.plantingAmount ? true : !!v || 'Unidade de Medida é obrigatória'),
    ],
    crop: [
      (v) => !!v || 'Safra é obrigatória',
    ],
  };

  const inputFields = fields.map((f) => {
    const { id } = f;

    return {
      ...f,
      values: values[id],
      validations: validations[id] || [],
      disabled: disabled[id],
    };
  });

  const handleChange = (id, value) => {
    const updateState = {
      ...state,
      [id]: value,
    };

    if (id === 'specie') {
      updateState.variety = null;
    }

    setState(updateState);
  };

  useEffect(() => {
    const specieId = (state.specie || {}).id;
    setFilteredVarieties((varieties || []).filter((variety) => variety.specie.id === specieId));
  }, [state.specie]);


  const validInputs = () => {
    const fieldsWithValidations = fields.map(({ id, ...f }) => ({
      validations: validations[id],
      id,
      ...f,
    }));

    return UtilsService.validateState(fieldsWithValidations, state);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = () => { FormService.data = state; };

  const getUrlForm = () => {
    if (edit) {
      const path = routes.fieldPath;
      return path.replace(':id', field.id);
    }

    return urlFields;
  };

  const tabs = [
    {
      label: 'Básico',
      icon: <SettingsIcon />,
      key: 'basic',
    },
    {
      label: 'Plantio',
      icon: <GrainIcon />,
      key: 'seeding',
    },
  ];

  return (
    <>
      <FormModal
        open={open}
        title="Campo de Produção"
        maxWidth="60%"
        maxHeight="85%"
        formForProps={{
          url: getUrlForm(),
          method: edit ? 'put' : 'post',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        <TabsModal
          tabs={tabs}
          fields={inputFields}
          handleChange={handleChange}
          value={state}
        />
      </FormModal>
    </>
  );
};


EditFieldModal.propTypes = {
  field: fieldPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  producers: PropTypes.arrayOf(producerPropType),
  species: PropTypes.arrayOf(speciePropType),
  varieties: PropTypes.arrayOf(varietyPropType),
  crops: PropTypes.arrayOf(cropPropType),
  measureUnits: PropTypes.arrayOf(PropTypes.shape(measureUnitType)),
  varietyCategories: PropTypes.arrayOf(varietyCategoryPropType),
};

export default EditFieldModal;
