import React, { useState } from 'react';
import { get } from 'lodash';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import FormModal from '../../components/FormModal';
import { seedBeneficiationUnitType } from './SeedBeneficiationUnit';
import { routesPropType } from '../../../services/GeneralTypes';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'name',
    label: 'Nome',
    type: 'text',
    field: 'name',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    autoFocus: true,
    required: true,
  },
  {
    id: 'address',
    label: 'Endereço',
    type: 'text',
    field: 'address',
    validations: [],
  },
  {
    id: 'latitude',
    label: 'Latitude',
    type: 'number',
    field: 'latitude',
    validations: [],
    gridSize: 6,
  },
  {
    id: 'longitude',
    label: 'Longitude',
    type: 'number',
    field: 'longitude',
    validations: [],
    gridSize: 6,
  },
];

export const buildSeedBeneficiationUnit = (seedBeneficiationUnit) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  return {
    name: get(seedBeneficiationUnit, 'name', ''),
    address: get(seedBeneficiationUnit, 'address', ''),
    latitude: get(seedBeneficiationUnit, 'geolocation.latitude', ''),
    longitude: get(seedBeneficiationUnit, 'geolocation.longitude', ''),
  };
};

const EditSeedBeneficiationUnitModal = ({
  seedBeneficiationUnit,
  routes: {
    seedBeneficiationUnitPath: urlShowUbs,
    seedBeneficiationUnitIndexPath: urlNewUbs,
  },
}) => {
  const [state, setState] = useState(() => buildSeedBeneficiationUnit(seedBeneficiationUnit));
  const [open, setOpen] = useState(true);
  const [edit] = useState(!!seedBeneficiationUnit);

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => setOpen(false);

  const urlForm = edit
    ? urlShowUbs.replace(':id', seedBeneficiationUnit.id)
    : urlNewUbs;

  const onFormSubmit = () => { FormService.data = state; };

  return (
    <>
      <FormModal
        title="UBS"
        open={open}
        formForProps={{
          method: edit ? 'put' : 'post',
          url: urlForm,
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
        maxWidth="40%"
      >

        {fields.map(({
          id, label, type, field, validations, placeholder, format, required, autoFocus, gridSize,
        }) => (
          <FloatingLabelInput
            gridSize={gridSize}
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
          />
        ))}
      </FormModal>
    </>
  );
};

EditSeedBeneficiationUnitModal.propTypes = {
  seedBeneficiationUnit: seedBeneficiationUnitType,
  routes: routesPropType,
};

export default EditSeedBeneficiationUnitModal;
