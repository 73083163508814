import React, { useState } from 'react';
import ViewModal from '../../components/ViewModal';
import { harvestPropType } from './Harvests';
import { fields, buildHarvest } from './EditHarvestModal';

const ViewHarvestModal = (props) => {
  const {
    harvest,
  } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ViewModal
      open={open}
      title="Colheita"
      actionsButtons={{
        close: {
          onClick: handleClose,
        },
      }}
      fields={fields}
      data={buildHarvest(harvest)}
    />
  );
};


ViewHarvestModal.propTypes = {
  harvest: harvestPropType,
};

export default ViewHarvestModal;
