import React, { useState } from 'react';
import { seedBulletinPropType } from './SeedBulletins';
import { fields, buildSeedBulletin } from './EditSeedBulletinModal';
import ViewModal from '../../components/ViewModal';

const ViewSeedBulletinModal = ({
  seedBulletin,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <ViewModal
        open={open}
        title="Boletim de Sementes"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildSeedBulletin(seedBulletin)}
      />
    </>
  );
};

ViewSeedBulletinModal.propTypes = {
  seedBulletin: seedBulletinPropType,
};

export default ViewSeedBulletinModal;
