import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const Regions = (props) => {
  const {
    regions,
    query,
    routes,
    actionsPermitted,
  } = props;

  const [allRegions, setRegion] = useState([]);

  const urlNewRegion = routes.newRegionPath;
  const urlRegion = routes.regionsPath;

  useEffect(() => {
    setRegion(regions.map((region) => {
      const updatedRegion = { ...region, id: region.id };
      return updatedRegion;
    }));
  }, [regions]);

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.regionPath}
          editUrl={routes.editRegionPath}
          deleteUrl={routes.regionPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a região <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="region">
          <Typography component="h1" variant="h2" align="center">
            Regiões
          </Typography>
          <SearchBar
            url={urlRegion}
            query={query}
          />
          <NewButton
            url={urlNewRegion}
            description="Nova região"
          />
          <ListTable
            columns={columns}
            data={allRegions}
          />
        </div>
      </Container>
    </>
  );
};

export const regionPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
});

Regions.propTypes = {
  regions: PropTypes.arrayOf(regionPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionsPermitted: actionsPermittedPropType,
};

export default Regions;
