/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import CardAvatar from '../../components/Card/CardAvatar';
import CardTitle from '../../components/Card/CardTitle';
import DynamicComponent from '../DynamicComponent';
import { SeedLotPropType } from '../seedLot/SeedLots';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
  },
  seedSealImage: {
    height: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(8),
    },
  },
  cardHeader: {
    flex: 1,
  },
  cardHeaderBox: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

const InfoCard = (props) => {
  const {
    title,
    cards,
    config,
    seedLot,
    seedSealUrl,
    subComponents,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <div className={classes.cardHeaderBox}>
            <CardHeader
              className={classes.cardHeader}
              avatar={(
                <CardAvatar>
                  <InfoIcon />
                </CardAvatar>
            )}
              title={(
                <CardTitle
                  title={title || 'Dados do Lote'}
                />
            )}
            />
            {seedSealUrl && (
              <span>
                <img src={seedSealUrl} alt="" className={classes.seedSealImage} />
              </span>
            )}
          </div>
          <CardContent>
            <Grid container spacing={3} className={classes.container}>
              {cards.map((component) => (
                <DynamicComponent
                  key={component.uuid}
                  config={config}
                  data={seedLot}
                  block={component}
                />
              ))}
            </Grid>
          </CardContent>
        </Card>
        {subComponents}
      </Grid>
    </>
  );
};

export const CardPropType = PropTypes.arrayOf(PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
}));

InfoCard.propTypes = {
  title: PropTypes.string,
  cards: CardPropType,
  seedLot: SeedLotPropType,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  seedSealUrl: PropTypes.string,
  subComponents: PropTypes.arrayOf(PropTypes.element),
};

export default InfoCard;
