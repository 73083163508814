import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { FormFor } from 'react-rails-form-helpers';
import { makeStyles } from '@material-ui/core/styles';

import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';

const useStyles = makeStyles((theme) => ({
  gridInputs: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    float: 'right',
    marginTop: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(4),
    overflow: 'hidden',
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  block: {
    marginBottom: theme.spacing(4),
  },
}));

const Settings = (props) => {
  const {
    routes,
    settings,
  } = props;

  const getSettingsValue = (path, defaultValue) => (
    UtilsService.getObjectValue(settings, path, defaultValue)
  );

  const getInitialState = () => {
    if (settings) {
      return {
        urlQrCode: getSettingsValue('urlQrCode'),
        urlApi: getSettingsValue('urlApi'),
        portApi: getSettingsValue('portApi'),
        toIntegrate: !!getSettingsValue('toIntegrate', false),
        tokenApi: getSettingsValue('tokenApi'),
        metricIdGoogleAnalytics: getSettingsValue('metricIdGoogleAnalytics'),
      };
    }

    return {
      urlQrCode: '',
      urlApi: '',
      portApi: '',
      toIntegrate: false,
      tokenApi: '',
      metricIdGoogleAnalytics: '',
    };
  };

  const [state, setState] = useState(getInitialState());

  const blocks = [
    {
      title: 'Etiqueta',
      key: 'etiqueta',
      fields: [
        {
          id: 'urlQrCode',
          label: 'URL QRCode',
          type: 'text',
          field: 'url_qr_code',
          validations: [],
          visible: true,
          autoFocus: true,
        },
      ],
    },
    {
      title: 'Integração Aqila',
      key: 'aqila',
      fields: [
        {
          id: 'urlApi',
          label: 'URL Aqila',
          type: 'text',
          field: 'url_api',
          validations: [
            (v) => !state.toIntegrate || !!v || 'URL de integração é obrigatória',
          ],
          gridSize: 9,
        },
        {
          id: 'portApi',
          label: 'Porta',
          type: 'number',
          field: 'port_api',
          validations: [
            (v) => !state.toIntegrate || !!v || 'Porta de integração é obrigatória',
          ],
          gridSize: 3,
        },
        {
          id: 'tokenApi',
          label: 'Token Aqila',
          type: 'text',
          field: 'token_api',
          validations: [
            (v) => !state.toIntegrate || !!v || 'Token de integração é obrigatório',
          ],
          gridSize: 9,
        },
        {
          id: 'toIntegrate',
          label: state.toIntegrate ? 'Integração Ativada' : 'Integração Desativada',
          type: 'checkbox',
          field: 'to_integrate',
          validations: [],
          gridSize: 3,
          checked: state.toIntegrate,
        },
      ],
    },
    {
      title: 'Integração Google Analytics',
      key: 'google-analytics',
      fields: [
        {
          id: 'metricIdGoogleAnalytics',
          label: 'ID Google Analytics',
          type: 'text',
          field: 'metric_id_google_analytics',
          gridSize: 12,
          validations: [],
        },
      ],
    },
  ];

  const validInputs = () => blocks.every((block) => (
    UtilsService.validateState(block.fields, state)
  ));

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const urlSettings = routes.settingsPath;
  const classes = useStyles();

  return (
    <>
      <Container className="rastreabilidade-container">
        <div>
          <Typography component="h1" variant="h2" align="center" className={classes.title}>
            Configurações
          </Typography>
          <FormFor
            url={urlSettings}
            method="put"
          >
            <Paper className={classes.paper}>
              {blocks.map(({ title, key, fields }) => (
                <div key={key} className={classes.block}>
                  <Typography variant="overline" gutterBottom className={classes.subtitle}>
                    {title}
                  </Typography>
                  <Divider />
                  <Grid container className={classes.gridInputs} spacing={3}>
                    {fields.map((
                      {
                        id,
                        label,
                        type,
                        field,
                        validations,
                        placeholder,
                        format,
                        required,
                        autoFocus,
                        gridSize,
                        checked,
                      },
                    ) => (
                      <FloatingLabelInput
                        key={field}
                        id={id}
                        label={label}
                        type={type}
                        validationRules={validations}
                        value={state[id]}
                        onChange={handleChange}
                        name={field}
                        placeholder={placeholder}
                        format={format}
                        required={required}
                        autoFocus={autoFocus}
                        gridSize={gridSize}
                        checked={checked}
                      />
                    ))}
                  </Grid>
                </div>
              ))}
              <Grid item xs={12} className={classes.button}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  id="form-model-confirm-btn"
                  disabled={!validInputs()}
                >
                  Salvar
                </Button>
              </Grid>
            </Paper>
          </FormFor>
        </div>
      </Container>
    </>
  );
};

export const settingsPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  urlQrCode: PropTypes.string,
});

Settings.propTypes = {
  settings: settingsPropType,
  routes: PropTypes.objectOf(PropTypes.string),
};

export default Settings;
