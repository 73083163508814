import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'indentifier',
    label: 'E-mail/CPF',
    type: 'text',
    field: 'identifier',
    visible: true,
    autoFocus: true,
    required: true,
  },
];

const emptyState = {
  identifier: '',
};

const getInitialState = () => (emptyState);

const ResetPasswordModal = (props) => {
  const { routes, open, handleClose } = props;
  const urlResetPassword = routes.sendMailResetPasswordsPath;

  const [state, setState] = useState(getInitialState);

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const validations = {
    identifier: [
      (v) => !!v || 'E-mail/CPF é obrigatório',
    ],
  };

  const onFormSubmit = () => {
    FormService.data = state;
  };

  const validInputs = () => {
    const fieldsWithValidations = fields.map(({ id, ...field }) => ({
      validations: validations[id],
      id,
      ...field,
    }));

    return UtilsService.validateState(fieldsWithValidations, state);
  };

  return (
    <>
      <FormModal
        open={open}
        title="Esqueceu sua senha"
        formForProps={{
          url: urlResetPassword,
          method: 'post',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Enviar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {fields.map((
          {
            id, label, type, field, placeholder, format, required, autoFocus,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations[id]}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
          />
        ))}
      </FormModal>
    </>
  );
};

ResetPasswordModal.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

export default ResetPasswordModal;
