import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import {
  laboratoryType,
  attachmentType,
  actionsPermittedPropType,
} from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const SeedBulletins = (props) => {
  const {
    seedBulletins,
    query,
    routes,
    actionsPermitted,
    currentPage,
    totalPages,
  } = props;
  const urlNewSeedBulletin = routes.newSeedBulletinPath;
  const urlSeedBulletin = routes.seedBulletinsPath;

  const columns = [
    {
      header: 'Número do Boletim',
      cell: ({ description }) => description,
    },
    {
      header: 'Laboratório',
      cell: ({ laboratory }) => (laboratory || {}).name || '',
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.seedBulletinPath}
          editUrl={routes.editSeedBulletinPath}
          deleteUrl={routes.seedBulletinPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir o boletim <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
          currentPage={currentPage}
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="seedBulletin">
          <Typography component="h1" variant="h2" align="center">
            Boletins de sementes
          </Typography>
          <SearchBar
            url={urlSeedBulletin}
            query={query}
          />
          <NewButton
            url={urlNewSeedBulletin}
            description="Novo boletim"
            currentPage={currentPage}
          />
          <ListTable
            columns={columns}
            data={seedBulletins}
            searchQuery={query}
            urlPagination={urlSeedBulletin}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      </Container>
    </>
  );
};

export const seedBulletinPropType = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string,
  laboratoryAttestationNumber: PropTypes.string,
  germinationTestValidity: PropTypes.string,
  analysisEndDate: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(attachmentType)),
  documents: PropTypes.arrayOf(PropTypes.shape(attachmentType)),
  laboratory: PropTypes.shape(laboratoryType),
});

SeedBulletins.propTypes = {
  seedBulletins: PropTypes.arrayOf(seedBulletinPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

export default SeedBulletins;
