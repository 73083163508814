import _ from 'lodash';

export default class FileUtilsService {
  static initialFileState() {
    return {
      file: '',
      description: '',
      id: '',
      destroy: false,
      validFileType: true,
      validFile: true,
      hasFile: false,
    };
  }

  static processInitialFile(objectKey, files) {
    const newFiles = files.reduce((acc, file) => {
      const key = _.uniqueId(`${objectKey}_`);
      const newFile = file;
      newFile.hasFile = !!file.fileName;

      acc[key] = { ...FileUtilsService.initialFileState(), ...newFile };
      return acc;
    }, {});

    newFiles[_.uniqueId(`${objectKey}_`)] = FileUtilsService.initialFileState();

    return newFiles;
  }

  static hasValidFile({ validFile, hasFile, validFileType }) {
    return hasFile && validFile && validFileType;
  }
}
