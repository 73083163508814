export default class CalculatorService {
  static getPercentValue(value) {
    return value / 100;
  }

  static getPurity(purity) {
    return purity ? this.getPercentValue(purity) : 1;
  }

  static getEfficiency(efficiency) {
    return efficiency ? this.getPercentValue(efficiency) : 1;
  }

  static getDividerWithPurityAndEfficiency({
    calculationBasis,
    purity,
    efficiency,
  }) {
    return calculationBasis * this.getPurity(purity) * this.getEfficiency(efficiency);
  }

  static getPlantsByHectare({
    seedingRate,
    spacing,
  }) {
    if (seedingRate > 0 && spacing > 0) {
      return (seedingRate / (spacing / 100)) * 10000;
    }

    return '--';
  }

  static getSeedsByHectare(data) {
    const {
      calculationBasis,
      population,
    } = data;

    if (population > 0 && calculationBasis > 0) {
      return (population * 100) / this.getDividerWithPurityAndEfficiency(data);
    }

    return '--';
  }

  static getSeedingRate({
    population,
    spacing,
  }) {
    if (population > 0 && spacing > 0) {
      return (population * this.getPercentValue(spacing)) / 10000;
    }

    return '--';
  }

  static getUpdatedSeedsByMeter(data) {
    const {
      seedingRate,
      calculationBasis,
    } = data;
    if (seedingRate > 0 && calculationBasis > 0) {
      return (seedingRate * 100) / this.getDividerWithPurityAndEfficiency(data);
    }
    return '--';
  }

  static getKgByHectare(data) {
    const {
      pms,
      area,
      spacing,
    } = data;
    const seedsByMeter = this.getUpdatedSeedsByMeter(data);

    if (seedsByMeter > 0 && area > 0 && spacing > 0) {
      const dividend = pms * area * seedsByMeter;
      const divider = spacing;
      return dividend / divider;
    }
    return '--';
  }

  static getTotalSeeds(data) {
    const {
      calculationBasis,
      population,
      area,
    } = data;

    if (population > 0 && calculationBasis > 0 && area > 0) {
      return this.getSeedsByHectare(data) * area;
    }

    return '--';
  }
}
