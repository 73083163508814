import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import FormModal from '../../components/FormModal';
import { measureUnitType } from '../../../services/GeneralTypes';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    autoFocus: true,
    required: true,
  },
  {
    id: 'abbreviation',
    label: 'Abreviação',
    type: 'text',
    field: 'abbreviation',
    validations: [
      (v) => !!v || 'Abreviação é obrigatória',
    ],
    required: true,
  },
];

const emptyState = {
  abbreviation: '',
  description: '',
};

export const buildMeasureUnit = (measureUnit) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  if (measureUnit) {
    const { abbreviation, description } = measureUnit;
    return { abbreviation, description };
  }

  return emptyState;
};

const EditMeasureUnitModal = ({ routes, measureUnit }) => {
  const urlMeasureUnits = routes.measureUnitsPath;

  const [state, setState] = useState(() => buildMeasureUnit(measureUnit));
  const [open, setOpen] = useState(true);
  const [edit] = useState(!!measureUnit);

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const urlForm = edit ? routes.measureUnitPath.replace(':id', measureUnit.id) : urlMeasureUnits;

  const onFormSubmit = () => { FormService.data = state; };

  return (
    <>
      <FormModal
        open={open}
        title="Unidade de medida"
        formForProps={{
          url: urlForm,
          method: edit ? 'put' : 'post',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {fields.map(({
          id,
          label,
          type,
          field,
          validations,
          placeholder,
          format,
          required,
          autoFocus,
          renderLabel,
          values,
        }) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            renderLabel={renderLabel}
            values={values}
          />
        ))}
      </FormModal>
    </>
  );
};

EditMeasureUnitModal.propTypes = {
  measureUnit: PropTypes.shape(measureUnitType),
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditMeasureUnitModal;
