
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { sievePropType } from './Sieves';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    visible: true,
    autoFocus: true,
    required: true,
  },
];

const emptyState = {
  description: '',
};

export const buildSieve = (sieve) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  if (sieve) {
    return {
      description: sieve.description,
    };
  }

  return emptyState;
};

const EditSieveModal = (props) => {
  const { routes, sieve } = props;
  const urlSieve = routes.sievesPath;

  const [state, setState] = useState(() => buildSieve(sieve));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(sieve));

  const validInputs = () => UtilsService.validateState(fields, state);
  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      return `${urlSieve}/${sieve.id}`;
    }
    return urlSieve;
  };

  const onFormSubmit = () => { FormService.data = state; };

  return (
    <>
      <FormModal
        title="Peneira"
        open={open}
        formForProps={{
          method: edit ? 'put' : 'post',
          url: getUrlForm(),
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >

        {fields.map((
          {
            id, label, type, field, validations, placeholder, format, required, autoFocus,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
          />
        ))}
      </FormModal>
      )
    </>
  );
};

EditSieveModal.propTypes = {
  sieve: sievePropType,
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditSieveModal;
