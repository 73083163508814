import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
  timestampsType,
  measureUnitType,
  attachmentType,
  additionalSeedInfoType,
  groundEmergencyType,
  actionsPermittedPropType,
} from '../../../services/GeneralTypes';
import ListTable from '../../components/ListTable';
import PrintListItem from '../../components/PrintListItem';
import LotListItem from '../../components/LotListItem';
import SearchBar from '../../components/SearchBar';
import { varietyPropType, varietyCategoryPropType } from '../variety/Varieties';
import NewButton from '../../components/NewButton';
import { seedBulletinPropType } from '../seedBulletins/SeedBulletins';
import { fieldPropType } from '../field/Fields';
import { cropPropType } from '../crop/Crops';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const SeedLots = (props) => {
  const {
    seedLots,
    query,
    routes,
    actionsPermitted,
    currentPage,
    totalPages,
  } = props;
  const urlSeedLots = routes.seedLotsPath;

  const columns = [
    {
      header: 'Número do lote',
      cell: ({ lotNumber }) => lotNumber,
    },
    {
      header: 'Data de criação',
      cell: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY'),
    },
    {
      header: 'Quantidade total',
      cell: ({ totalQuantity, measureUnit }) => (
        `${totalQuantity} ${measureUnit.abbreviation}`
      ),
    },
    {
      header: 'Safra',
      cell: ({ crop }) => crop.description,
    },
    {
      header: 'Cultivar ',
      cell: ({ variety }) => variety.description,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.seedLotPath}
          editUrl={routes.editSeedLotPath}
          deleteUrl={routes.seedLotPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir o lote de sementes <b>${row.lotNumber}</b>?
            <br>Esta ação é irreversível!`
          }
          currentPage={currentPage}
        >
          <PrintListItem
            path={routes.printSeedLotPath}
            itemId={row.id}
            currentPage={currentPage}
          />
          <LotListItem
            path={routes.lotePath}
            itemId={row.id}
          />
        </DropdownActionButtons>
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div>
          <Typography component="h1" variant="h2" align="center">
            Lotes de Sementes
          </Typography>
          <SearchBar
            url={urlSeedLots}
            query={query}
          />
          <NewButton
            url={routes.newSeedLotPath}
            description="Novo Lote"
            currentPage={currentPage}
          />
          <ListTable
            columns={columns}
            data={seedLots}
            urlPagination={urlSeedLots}
            searchQuery={query}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      </Container>
    </>
  );
};

export const SeedLotPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  crop: cropPropType,
  lotNumber: PropTypes.string,
  sieveSize: PropTypes.string,
  totalQuantity: PropTypes.number,
  measureUnit: PropTypes.shape(measureUnitType),
  firstProductionField: fieldPropType,
  productionFields: PropTypes.arrayOf(fieldPropType),
  variety: varietyPropType,
  images: PropTypes.arrayOf(PropTypes.shape(attachmentType)),
  terms: PropTypes.arrayOf(PropTypes.shape(attachmentType)),
  packingWeight: PropTypes.number,
  varietyCategory: varietyCategoryPropType,
  seedQuantity: PropTypes.number,
  productionOrderNumber: PropTypes.string,
  productionOrderDate: PropTypes.string,
  bulletin: seedBulletinPropType,
  productionFieldSize: PropTypes.number,
  purity: PropTypes.number,
  pms: PropTypes.number,
  germination: PropTypes.number,
  vigor: PropTypes.number,
  infestedSeeds: PropTypes.number,
  inertMaterial: PropTypes.number,
  averageSeedBed: PropTypes.number,
  seedBedAnalysisDate: PropTypes.string,
  additionalInfo: PropTypes.shape(additionalSeedInfoType),
  groundEmergency: PropTypes.shape(groundEmergencyType),
  tetrazoliumViability: PropTypes.number,
  tetrazoliumVigor: PropTypes.number,
  laboratoryAttestationNumber: PropTypes.string,
  highVigorAndGreaterVigor: PropTypes.number,
  ...timestampsType,
});

SeedLots.propTypes = {
  seedLots: PropTypes.arrayOf(SeedLotPropType),
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

export default SeedLots;
