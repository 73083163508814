import React from 'react';
import PropTypes from 'prop-types';

const IconCalculator = ({ fillColor }) => {
  const style = {
    fillCollor: {
      fill: fillColor || '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="calculadora" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Calculadora</title>
      <path d="M64.3,3.5H15.2a6.8,6.8,0,0,0-6.7,6.7v58a6.8,6.8,0,0,0,6.7,6.7H64.3A6.8,6.8,0,0,0,71,68.2v-58A6.8,6.8,0,0,0,64.3,3.5ZM26.4,64.2A1.9,1.9,0,0,1,24.6,66H19.2a1.9,1.9,0,0,1-1.8-1.8V58.8A1.9,1.9,0,0,1,19.2,57h5.4a1.9,1.9,0,0,1,1.8,1.8Zm0-17.9a1.9,1.9,0,0,1-1.8,1.8H19.2a1.9,1.9,0,0,1-1.8-1.8V41a1.9,1.9,0,0,1,1.8-1.8h5.4A1.9,1.9,0,0,1,26.4,41ZM44.2,64.2A1.9,1.9,0,0,1,42.4,66H37.1a1.9,1.9,0,0,1-1.8-1.8V58.8A1.9,1.9,0,0,1,37.1,57h5.3a1.9,1.9,0,0,1,1.8,1.8v5.4Zm0-17.9a1.9,1.9,0,0,1-1.8,1.8H37.1a1.9,1.9,0,0,1-1.8-1.8V41a1.9,1.9,0,0,1,1.8-1.8h5.3A1.9,1.9,0,0,1,44.2,41v5.3ZM62.1,64.2A1.9,1.9,0,0,1,60.3,66H54.9a1.9,1.9,0,0,1-1.8-1.8V41a1.9,1.9,0,0,1,1.8-1.8h5.4A1.9,1.9,0,0,1,62.1,41V64.2Zm0-35.7a1.9,1.9,0,0,1-1.8,1.8H19.2a1.9,1.9,0,0,1-1.8-1.8V14.2a1.9,1.9,0,0,1,1.8-1.8H60.3a1.9,1.9,0,0,1,1.8,1.8Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

IconCalculator.propTypes = {
  fillColor: PropTypes.string,
};

export default IconCalculator;
