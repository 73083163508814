/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import { amber, green } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import convert from 'htmr';
import { v4 as uuidv4 } from 'uuid';

class SnackBarController {
  static open(key, openFunction) {
    const snackMessage = {
      key,
      open: openFunction,
    };

    if (SnackBarController.snack) {
      SnackBarController.snacks.push(snackMessage);
    } else {
      SnackBarController.updateSnack(snackMessage, false);
    }
  }

  static close(key) {
    const { snack } = SnackBarController;
    if ((snack || {}).key === key) {
      snack.open(false);

      const nextSnack = SnackBarController.snacks.shift();
      if (nextSnack) {
        SnackBarController.updateSnack(nextSnack);
      } else {
        SnackBarController.snack = null;
      }
    } else {
      SnackBarController.snacks = SnackBarController.snacks.filter((s) => s.key !== key);
    }
  }

  static updateSnack(snack, timeout = true) {
    SnackBarController.snack = snack;

    if (timeout) {
      setTimeout(() => snack.open(true), 300);
    } else {
      snack.open(true);
    }
  }
}

SnackBarController.stack = {};
SnackBarController.snacks = [];

/**
 * Exibe uma mensagem de aviso ao usuário
 */
const MessageSnackBar = (props) => {
  const {
    className,
    message,
    onClose,
    variant,
  } = props;

  const [open, setOpen] = useState(false);
  const [uuid] = useState(uuidv4());
  const position = {
    vertical: 'top',
    horizontal: 'right',
  };

  useEffect(() => {
    if (message && message !== '') {
      SnackBarController.open(uuid, setOpen);
    } else {
      SnackBarController.close(uuid);
    }
  }, [message]);

  const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
    alert: ErrorIcon,
    notice: CheckCircleIcon,
  };

  const useStyles = makeStyles((theme) => ({
    success: {
      backgroundColor: green[600],
    },
    notice: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    alert: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

  const classes = useStyles();

  const messageClassNames = classNames(classes[variant], className);
  const iconClassNames = classNames(classes.icon, classes.iconVariant);
  const Icon = variantIcon[variant];

  const handleClose = () => {
    SnackBarController.close(uuid);
  };

  const snackbarContent = () => (
    <span id="client-snackbar" className={classes.message}>
      <Icon className={iconClassNames} />
      <span>{message && convert(message)}</span>
    </span>
  );

  return (
    <Snackbar
      anchorOrigin={position}
      open={open}
      autoHideDuration={6000}
      onClose={() => {
        handleClose();
        if (onClose) {
          onClose();
        }
      }}
    >
      <SnackbarContent
        className={messageClassNames}
        aria-describedby="client-snackbar"
        message={snackbarContent()}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default MessageSnackBar;

MessageSnackBar.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'alert', 'notice']).isRequired,
};
