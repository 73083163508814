import PropTypes from 'prop-types';
import { measureUnitType } from '../../../services/GeneralTypes';

export const SupplyPropType = PropTypes.shape({
  activeIngredient: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  supplyGroup: PropTypes.string,
  toxicologicalClassification: PropTypes.string,
});

export const SeedTreatmentItemPropType = PropTypes.shape({
  dose: PropTypes.number,
  measureUnit: PropTypes.shape(measureUnitType),
  quantity: PropTypes.number,
  supply: SupplyPropType,
});

export const SeedTreatmentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(SeedTreatmentItemPropType),
  treatmentNumber: PropTypes.string,
  treatmentDate: PropTypes.date,
});
