import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { red } from '@material-ui/core/colors';
import PlantabilitySuggestion from './PlantabilitySuggestion';
import PlantabilityData from './PlantabilityData';
import PlantabilityCalculatorResult from './PlantabilityCalculatorResult';
import CardAvatar from '../../components/Card/CardAvatar';
import CardTitle from '../../components/Card/CardTitle';
import { regionPropType } from '../region/Regions';
import {
  unitConversionPropType,
  SEED_SPEND_TYPE,
  WEIGHT_SPEND_TYPE,
} from '../unit_conversion/UnitConversions';
import { varietyPropType } from '../variety/Varieties';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import CalculatorService from '../../../services/CalculatorService';
import CalculatorIcon from '../../components/icons/Calculator';
import { TYPE_SEEDING_RATE, TYPE_POPULATION } from '../../../services/constants';
import PlantsInput from './PlantsInput';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  gridContainer: {
    margin: theme.spacing(4, 1, 0, 1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  gridOptionsContainer: {
    margin: theme.spacing(4, 1, 0, 1),
    display: 'flex',
    flexDirection: 'row',
  },
  gridItem: {
    textAlign: 'center',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  tile: {
    width: '100%',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  marginBottom1Spacing: {
    marginBottom: theme.spacing(1),
  },
  marginBottom3Spacing: {
    marginBottom: theme.spacing(3),
  },
  suggestionField: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  suggestionFieldError: {
    color: red[500],
    fontWeight: 700,
  },
  centerContent: {
    margin: 'auto 0',
  },
}));

const PlantabilityCalculator = (props) => {
  const {
    title,
    pms: pmsProp,
    germination: germinationProp,
    vigor,
    purity: purityProp,
    useVigor,
    type,
    regions,
    variety,
    efficiency,
    unitConversions,
    spendType,
  } = props;

  const filterUnitConversionsBySpecies = unitConversions.filter((conversion) => (
    !variety || (isNil(conversion.species) || conversion.species.id === variety.specie.id)
  ));

  const filterConversions = (spendOption) => (
    filterUnitConversionsBySpecies.filter((conversion) => (
      conversion.type === spendOption
    ))
  );

  const purity = purityProp || 100;
  const isTypeSeedingRate = useMemo(() => type === TYPE_SEEDING_RATE, [type]);
  const isTypePopulation = () => type === TYPE_POPULATION;
  const isValid = (value) => !Number.isNaN(value) && value;

  const initialInputState = {
    area: null,
    plants: null,
    spacing: null,
    efficiency: efficiency || 90,
  };

  const getInitialSpendOption = () => {
    let initialSpendOption = spendType;

    if (!isEmpty(filterConversions(SEED_SPEND_TYPE))
        && (isEmpty(filterConversions(WEIGHT_SPEND_TYPE)) || !isValid(pmsProp))) {
      initialSpendOption = SEED_SPEND_TYPE;
    }

    return initialSpendOption || WEIGHT_SPEND_TYPE;
  };

  const [inputState, setState] = useState(initialInputState);
  const [calculationOption, setCalculationOption] = useState('germination');
  const [spendOption, setSpendOption] = useState(getInitialSpendOption());
  const [pms, setPms] = useState(pmsProp);
  const [germination, setGermination] = useState(germinationProp);
  const [calculationBasis, setCalculationBasis] = useState(germinationProp);
  const [population, setPopulation] = useState(null);
  const [seedingRate, setSeedingRate] = useState(null);
  const [availableConversions, setAvailableConversions] = useState(filterConversions(spendOption));
  const [conversion, setConversion] = useState({
    conversion: 40,
    description: 'Sacos 40kg',
  });

  const classes = useStyles();

  const handleChange = (id, value) => {
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleChangeNumber = (id, value) => {
    const number = Number.parseFloat(value);
    handleChange(id, Number.isNaN(number) ? null : number);
  };

  const handleChangeCalculationOption = (event) => {
    const { value } = event.target;
    setCalculationOption(value);
    if (value === 'germination') {
      setCalculationBasis(germination);
    } else {
      setCalculationBasis(vigor);
    }
  };

  const handleChangeSpendOption = (event) => {
    const { value } = event.target;
    setSpendOption(value);
  };

  const getAreaField = () => (
    {
      id: 'area',
      label: 'Área',
      adornment: 'ha',
    }
  );

  const getSpacingField = () => (
    {
      id: 'spacing',
      label: 'Espaçamento',
      adornment: 'cm',
    }
  );

  const getEfficiency = () => (
    {
      id: 'efficiency',
      label: 'Eficiência de Plantio',
      adornment: '%',
    }
  );

  const getSuggestionFields = () => [
    getSpacingField(),
    getAreaField(),
    getEfficiency(),
  ];

  useEffect(() => {
    const { plants, spacing } = inputState;
    if (isTypeSeedingRate) {
      setPopulation(CalculatorService.getPlantsByHectare({ seedingRate: plants, spacing }));
      setSeedingRate(plants);
    } else {
      setPopulation(plants);
      setSeedingRate(CalculatorService.getSeedingRate({ population: plants, spacing }));
    }
  }, [inputState.plants, inputState.spacing]);

  useEffect(() => {
    if (!isEmpty(availableConversions)) {
      setConversion(availableConversions[0]);
    }
  }, [availableConversions]);

  useEffect(() => {
    if (!isValid(pms) && filterConversions(SEED_SPEND_TYPE).length >= 1) {
      setSpendOption(SEED_SPEND_TYPE);
    }
  }, [pms]);

  useEffect(() => {
    setAvailableConversions(filterConversions(spendOption));
  }, [spendOption]);

  const getAdornment = (adornment) => {
    const children = (
      <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
        {adornment}
      </Typography>
    );
    return (
      <InputAdornment
        position="end"
        disableTypography
        style={{ marginTop: '8px', alignItems: 'end' }}
      >
        {children}
      </InputAdornment>
    );
  };

  const getInputProps = (adornment, inputComponent) => {
    const inputProps = {
      endAdornment: getAdornment(adornment),
    };

    if (inputComponent) {
      inputProps.inputComponent = inputComponent;
    }

    return inputProps;
  };

  const showSpentRadioGroup = () => (
    !isEmpty(filterConversions(WEIGHT_SPEND_TYPE)) && !isEmpty(filterConversions(SEED_SPEND_TYPE))
    && isValid(pms)
  );

  const showSpentSelect = () => availableConversions.length > 1;

  const showSpentOptions = () => (
    isValid(germination)
    && (showSpentRadioGroup() || showSpentSelect())
  );

  const showCalculatorOptions = () => vigor > 0 && useVigor !== false;
  const showCalculatorEdit = () => !germinationProp;
  const showCalculator = () => (
    (isValid(pms) || spendOption === SEED_SPEND_TYPE)
    && isValid(germination)
  );

  useEffect(() => {
    if (!showCalculatorOptions() || !calculationBasis) {
      setCalculationBasis(germination);
    }
  }, [germination]);

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={(
              <CardAvatar>
                <CalculatorIcon fillColor="currentColor" />
              </CardAvatar>
            )}
            title={(
              <CardTitle
                title={title || 'Calculadora de Plantabilidade'}
              />
            )}
          />
          <CardContent>
            <div className={classes.root}>
              {showCalculatorEdit() && (
                <PlantabilityData
                  pms={pms}
                  germination={germination}
                  updatePms={setPms}
                  updateGermination={setGermination}
                />
              )}

              <PlantabilitySuggestion
                classes={classes}
                variety={variety}
                regions={regions}
                type={type}
              />

              {showCalculator() && (
                <>
                  <Grid container className={classes.gridContainer} spacing={3}>
                    <PlantsInput
                      isTypeSeedingRate={isTypeSeedingRate}
                      onChange={(value) => handleChange('plants', value)}
                    />
                    {getSuggestionFields().map(({
                      id,
                      label,
                      adornment,
                      inputComponent,
                      type: inputType,
                    }) => (
                      <Grid item xs={12} md={4} lg={3} key={id}>
                        <FloatingLabelInput
                          key={id}
                          id={id}
                          label={label}
                          type={inputType || 'number'}
                          value={inputState[id] || ''}
                          onChange={handleChangeNumber}
                          name={id}
                          InputProps={getInputProps(adornment, inputComponent)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <PlantabilityCalculatorResult
                    isTypePopulation={isTypePopulation()}
                    isTypeSeedingRate={isTypeSeedingRate}
                    population={population}
                    area={inputState.area}
                    spacing={inputState.spacing}
                    efficiency={inputState.efficiency}
                    calculationBasis={calculationBasis}
                    seedingRate={seedingRate}
                    pms={pms}
                    purity={purity}
                    spendType={spendOption}
                    conversionUnit={conversion}
                  />
                </>
              )}

              <Grid container className={classes.gridOptionsContainer} spacing={3}>
                {showCalculatorOptions() && (
                  <Grid item xs={12} md={6}>
                    <FormLabel
                      component="legend"
                      className={classes.marginBottom1Spacing}
                    >
                      Calcular com:
                    </FormLabel>
                    <RadioGroup
                      value={calculationOption}
                      aria-label="calculator"
                      name="calculated-radios"
                      onChange={handleChangeCalculationOption}
                    >
                      <FormControlLabel
                        value="germination"
                        control={<Radio />}
                        label="Germinação"
                      />
                      <FormControlLabel
                        value="vigor"
                        control={<Radio />}
                        label="Envelhecimento Acelerado (EA)"
                      />
                    </RadioGroup>
                  </Grid>
                )}
                {showSpentOptions() && (
                  <>
                    {showSpentRadioGroup() && (
                      <Grid item xs={12} md={3}>
                        <FormLabel
                          component="legend"
                          className={classes.marginBottom1Spacing}
                        >
                          Gastos em:
                        </FormLabel>
                        <RadioGroup
                          value={spendOption}
                          aria-label="spend-calculator"
                          name="spend-calculated-radios"
                          onChange={handleChangeSpendOption}
                        >
                          <FormControlLabel value="kg" control={<Radio />} label="Kg" />
                          <FormControlLabel
                            value="seed"
                            control={<Radio />}
                            label="Quantidade de sementes"
                          />
                        </RadioGroup>
                      </Grid>
                    )}
                    {showSpentSelect() && (
                      <Grid item xs={12} md={3} className={classes.centerContent}>
                        {!showSpentRadioGroup() && (
                          <FormLabel
                            component="legend"
                            className={classes.marginBottom3Spacing}
                          >
                            Gastos em:
                          </FormLabel>
                        )}
                        <FloatingLabelInput
                          key="measureUnit"
                          id="measureUnit"
                          label="Unidade de Medida"
                          type="autocomplete"
                          value={conversion}
                          onChange={(_id, value) => setConversion(value)}
                          name="measureUnit"
                          values={availableConversions}
                          renderLabel={({ description }) => description}
                          disableClearable
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

PlantabilityCalculator.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  pms: PropTypes.number,
  germination: PropTypes.number,
  vigor: PropTypes.number,
  purity: PropTypes.number,
  useVigor: PropTypes.bool,
  regions: PropTypes.arrayOf(regionPropType),
  variety: varietyPropType,
  efficiency: PropTypes.number,
  unitConversions: PropTypes.arrayOf(unitConversionPropType),
  spendType: PropTypes.string,
};

export default PlantabilityCalculator;
