import React, { useEffect } from 'react';
import FormService from '../../services/FormService';

const Application = () => {
  useEffect(() => {
    if (window.location.pathname !== FormService.storeKey) {
      FormService.clearFormStore();
    }
  }, []);

  return <></>;
};

export default Application;
