import React from 'react';
import PropTypes from 'prop-types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import { FormFor } from 'react-rails-form-helpers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonRight: {
    float: 'right',
    marginLeft: 10,
  },
}));

const NewButton = (props) => {
  const {
    url, description, icon, currentPage,
  } = props;

  const classes = useStyles();

  return (
    <FormFor
      url={url || ''}
      method="get"
      hidden={!url}
    >
      <input
        name="page"
        value={currentPage}
        type="hidden"
      />
      <div className={classes.buttonRight}>
        <Button
          className="button is-primary"
          startIcon={icon || (<AddCircleIcon />)}
          type="submit"
        >
          {description || 'Novo Registro'}
        </Button>
      </div>
    </FormFor>
  );
};

NewButton.propTypes = {
  url: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element,
  currentPage: PropTypes.number,
};

export default NewButton;
