import React, { useState } from 'react';

import { plantabilityRecommendationPropType } from './PlantabilityRecommendations';
import { fields, buildPlantabilityRecommendation } from './EditPlantabilityRecommendationModal';
import ViewModal from '../../components/ViewModal';

const ViewPlantabilityRecommendationModal = (props) => {
  const { plantabilityRecommendation } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ViewModal
      open={open}
      title="Recomendação de Plantabilidade"
      actionsButtons={{
        close: {
          onClick: handleClose,
        },
      }}
      fields={fields}
      data={buildPlantabilityRecommendation(plantabilityRecommendation)}
    />
  );
};

ViewPlantabilityRecommendationModal.propTypes = {
  plantabilityRecommendation: plantabilityRecommendationPropType,
};

export default ViewPlantabilityRecommendationModal;
