import React from 'react';

const IconLaboratorio = () => {
  const style = {
    fillcolor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="laboratorio" style={style.fillcolor} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 79.5 78.36">
      <title>Laboratorio</title>
      <path d="M11.88,18.14h2.7v27a9,9,0,1,0,18,0v-27h2.7a.89.89,0,0,0,.89-.9V11.85a.9.9,0,0,0-.89-.9H11.88a.91.91,0,0,0-.9.9v5.39A.9.9,0,0,0,11.88,18.14Zm8.09,0h7.19V28.93H20ZM73.91,61.29H5.59a1.81,1.81,0,0,0-1.8,1.8v3.6a1.81,1.81,0,0,0,1.8,1.8H73.91a1.81,1.81,0,0,0,1.8-1.8v-3.6A1.81,1.81,0,0,0,73.91,61.29ZM44.24,18.14h2.7v27a9,9,0,1,0,18,0v-27h2.7a.9.9,0,0,0,.9-.9V11.85a.91.91,0,0,0-.9-.9H44.24a.9.9,0,0,0-.89.9v5.39A.89.89,0,0,0,44.24,18.14Zm8.1,0h7.19V28.93H52.34Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconLaboratorio;
