import React, { useState } from 'react';
import { cropPropType } from './Crops';
import { fields, buildCrop } from './EditCropModal';
import ViewModal from '../../components/ViewModal';

const ViewCropModal = (props) => {
  const { crop } = props;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Safra"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildCrop(crop)}
      />
    </>
  );
};

ViewCropModal.propTypes = {
  crop: cropPropType,
};

export default ViewCropModal;
