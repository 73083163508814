import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { laboratoryPropType } from './Laboratories';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'name',
    label: 'Nome',
    type: 'text',
    field: 'name',
    validations: [
      (v) => !!v || 'Nome é obrigatória',
    ],
    autoFocus: true,
    required: true,
  },
];

const emptyState = {
  name: '',
};

export const buildLaboratory = (laboratory) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  if (laboratory) {
    const { name } = laboratory;
    return {
      name,
    };
  }

  return emptyState;
};

const EditLaboratoryModal = (props) => {
  const {
    routes, laboratory,
  } = props;
  const urlLaboratories = routes.laboratoriesPath;

  const [state, setState] = useState(() => buildLaboratory(laboratory));
  const [open, setOpen] = useState(true);
  const [edit] = useState(!!laboratory);

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = () => { FormService.data = state; };

  const getUrlForm = () => {
    if (edit) {
      const path = routes.laboratoryPath;
      return path.replace(':id', laboratory.id);
    }

    return urlLaboratories;
  };

  return (
    <>
      <FormModal
        open={open}
        title="Laboratório"
        formForProps={{
          url: getUrlForm(),
          method: edit ? 'put' : 'post',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {fields.map(({
          id,
          label,
          type,
          field,
          validations,
          placeholder,
          format,
          required,
          autoFocus,
          renderLabel,
          values,
        }) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            renderLabel={renderLabel}
            values={values}
          />
        ))}
      </FormModal>
    </>
  );
};

EditLaboratoryModal.propTypes = {
  laboratory: laboratoryPropType,
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditLaboratoryModal;
