import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import {
  routesPropType,
  geolocationPropType,
  actionsPermittedPropType,
} from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const SeedBeneficiationUnit = ({
  seedBeneficiationUnits,
  query,
  routes: {
    newSeedBeneficiationUnitPath: urlNewUbs,
    seedBeneficiationUnitIndexPath: urlUbs,
    editSeedBeneficiationUnitPath: urlEditUbs,
    seedBeneficiationUnitPath: urlShowUbs,
  },
  actionsPermitted,
}) => {
  const columns = [
    {
      header: 'Nome',
      cell: (row) => row.name,
    },
    {
      header: 'Endereço',
      cell: (row) => row.address || 'N/d',
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={urlShowUbs}
          editUrl={urlEditUbs}
          deleteUrl={urlShowUbs}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a UBS <b>${row.name}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="region">
          <Typography component="h1" variant="h2" align="center">
            UBS
          </Typography>
          <SearchBar
            url={urlUbs}
            query={query}
          />
          <NewButton
            url={urlNewUbs}
            description="Nova UBS"
          />
          <ListTable
            columns={columns}
            data={seedBeneficiationUnits}
          />
        </div>
      </Container>
    </>
  );
};

export const seedBeneficiationUnitType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  geolocation: geolocationPropType,
});

SeedBeneficiationUnit.propTypes = {
  seedBeneficiationUnits: PropTypes.arrayOf(seedBeneficiationUnitType).isRequired,
  query: PropTypes.string,
  routes: routesPropType,
  actionsPermitted: actionsPermittedPropType,
};

export default SeedBeneficiationUnit;
