import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ImageIcon from '@material-ui/icons/Image';
import CardAvatar from '../../../components/Card/CardAvatar';
import CardTitle from '../../../components/Card/CardTitle';
import { SeedLotPropType } from '../../seedLot/SeedLots';
import ImagesCarousel from './carousel';
import ImagesList from './list';

const IMAGES_COMPONENTES = {
  carousel: ImagesCarousel,
  list: ImagesList,
};

const ImageCard = (props) => {
  const {
    title,
    seedLot,
    attribute,
    subComponents,
    viewMode = 'carousel',
  } = props;

  const ImagesComponent = IMAGES_COMPONENTES[viewMode];

  const images = get(seedLot, attribute).filter((image) => image.fileName);

  return (
    <>
      {images && images.length > 0 && (
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={(
              <CardAvatar>
                <ImageIcon />
              </CardAvatar>
            )}
            title={<CardTitle title={title || 'Imagens'} />}
          />
          <CardContent>
            <ImagesComponent images={images} />
          </CardContent>
        </Card>
        {subComponents}
      </Grid>
      )}
    </>
  );
};

ImageCard.propTypes = {
  title: PropTypes.string,
  seedLot: SeedLotPropType,
  attribute: PropTypes.string.isRequired,
  viewMode: PropTypes.string,
  subComponents: PropTypes.arrayOf(PropTypes.element),
};

export default ImageCard;
