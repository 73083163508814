import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const useStyles = makeStyles(() => ({
  buttonGroup: {
    flex: 1,
    flexDirection: 'column',
  },
}));

export const fertilityHumanize = {
  low: 'Baixa',
  medium: 'Média',
  high: 'Alta',
};

const PlantabilityRecommendations = (props) => {
  const {
    plantabilityRecommendations,
    query,
    routes,
    currentPage,
    totalPages,
    actionsPermitted,
  } = props;


  const [allPlantabilityRecommendation, setPlantabilityRecommendation] = useState([]);

  const urlNewPlantabilityRecommendation = routes.newPlantabilityRecommendationPath;
  const urlPlantabilityRecommendation = routes.plantabilityRecommendationsPath;
  const urlReportRecommendations = routes.reportPlantabilityRecommendationsPath;

  useEffect(() => {
    setPlantabilityRecommendation(plantabilityRecommendations.map((plantabilityRecommendation) => {
      const updated = { ...plantabilityRecommendation, id: plantabilityRecommendation.id };
      return updated;
    }));
  }, [plantabilityRecommendations]);

  const columns = [
    {
      header: 'Cultivar (Atividade)',
      cell: (row) => `${row.variety.description} (${row.specie.description})`,
    },
    {
      header: 'Região',
      cell: (row) => row.region.description,
    },
    {
      header: 'Fertilidade',
      cell: (row) => fertilityHumanize[row.fertility],
    },
    {
      header: 'Período de Recomendação',
      cell: (row) => row.recommendationPeriod.description,
    },
    {
      header: 'Nº de plantas',
      cell: (row) => row.plantsNumber,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.plantabilityRecommendationPath}
          editUrl={routes.editPlantabilityRecommendationPath}
          deleteUrl={routes.plantabilityRecommendationPath}
          itemId={row.id}
          currentPage={currentPage}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a recomendação de plantabilidade?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  const style = useStyles();

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="plantabilityRecommendation">
          <Typography component="h1" variant="h2" align="center">
            Recomendação de Plantabilidade
          </Typography>
          <SearchBar
            url={urlPlantabilityRecommendation}
            query={query}
          />
          <div className={style.buttonGroup}>
            <NewButton
              url={urlNewPlantabilityRecommendation}
              description="Nova recomendação"
              currentPage={currentPage}
            />
            <NewButton
              url={urlReportRecommendations}
              description="Gerar PDF das recomendações"
              icon={<PictureAsPdfIcon />}
              currentPage={currentPage}
            />
          </div>
          <ListTable
            columns={columns}
            data={allPlantabilityRecommendation}
            urlPagination={urlPlantabilityRecommendation}
            searchQuery={query}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      </Container>
    </>
  );
};

export const plantabilityRecommendationPropType = PropTypes.shape({
  description: PropTypes.string,
  fertility: PropTypes.oneOf(['low', 'medium', 'high']),
  plantsNumber: PropTypes.number,
  id: PropTypes.string,
});

PlantabilityRecommendations.propTypes = {
  plantabilityRecommendations: PropTypes.arrayOf(plantabilityRecommendationPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  actionsPermitted: actionsPermittedPropType,
};

export default PlantabilityRecommendations;
