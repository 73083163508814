import React, { useState } from 'react';
import { producerPropType } from './Producers';
import { fields, buildProducer } from './EditProducerModal';
import ViewModal from '../../components/ViewModal';

const ViewProcucerModal = (props) => {
  const {
    producer,
  } = props;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Produtor"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildProducer(producer)}
      />
    </>
  );
};

ViewProcucerModal.propTypes = {
  producer: producerPropType,
};

export default ViewProcucerModal;
