import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconAtividade from './icons/Atividade';
import IconBoletim from './icons/Boletins';
import IconConfiguracao from './icons/Configuracoes';
import IconCultivar from './icons/Cultivares';
import IconEtiqueta from './icons/Etiquetas';
import IconLaboratorio from './icons/Laboratorio';
import IconLote from './icons/Lotes';
import IconPeneira from './icons/Peneiras';
import IconPeriodo from './icons/Periodos';
import IconProdutor from './icons/Produtores';
import IconSafra from './icons/Safras';
import IconUnidadeDeBeneficiamentoDeSementes from './icons/UnidadesDeBeneficiamentoDeSementes';
import IconUnidadeDeMedidas from './icons/UnidadesDeMedidas';
import IconCampo from './icons/Campos';
import IconPlantabilityRecommendations from './icons/PlantabilityRecommendations';
import IconRegions from './icons/Regions';
import IconConversao from './icons/ConvercaoUnidades';
import IconPerfisUsuarios from './icons/PerfisDeUsuarios';
import IconUsuarios from './icons/Usuarios';
import IconSeedQualityPattern from './icons/SeedQualityPattern';

const MenuLateral = ({ open, routes, handleClose }) => {
  const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
      paddingTop: 'unset',
    },
    closeBtn: {
      padding: theme.spacing(0.5),
    },
  }));
  const classes = useStyles();

  const menuItems = {
    key: 'menu',
    items: [
      {
        router: routes.speciesIndexPath,
        label: 'Atividades',
        icon: <IconAtividade />,
      },
      {
        router: routes.seedBulletinsPath,
        label: 'Boletins de sementes',
        icon: <IconBoletim />,
      },
      {
        router: routes.fieldsPath,
        label: 'Campos de Produção',
        icon: <IconCampo />,
      },
      {
        router: routes.varietiesPath,
        label: 'Cultivares',
        icon: <IconCultivar />,
      },
      {
        router: routes.laboratoriesPath,
        label: 'Laboratórios',
        icon: <IconLaboratorio />,
      },
      {
        router: routes.seedLotsPath,
        label: 'Lotes de sementes',
        icon: <IconLote />,
      },
      {
        router: routes.sievesPath,
        label: 'Peneiras',
        icon: <IconPeneira />,
      },
      {
        router: routes.producersPath,
        label: 'Produtores',
        icon: <IconProdutor />,
      },
      {
        router: routes.cropsPath,
        label: 'Safras',
        icon: <IconSafra />,
      },
      {
        router: routes.seedBeneficiationUnitIndexPath,
        label: 'UBS',
        icon: <IconUnidadeDeBeneficiamentoDeSementes />,
        title: 'Unidade de Beneficiamento de Sementes',
      },
      {
        router: routes.measureUnitsPath,
        label: 'Unidades de Medidas',
        icon: <IconUnidadeDeMedidas />,
      },
    ],
  };

  const calculatorMenuItems = {
    key: 'calculator',
    items: [
      {
        router: routes.unitConversionsPath,
        label: 'Conversão de Unidades',
        icon: <IconConversao />,
      },
      {
        router: routes.seedQualityPatternsPath,
        label: 'Padrões de Qualidade de Sementes',
        icon: <IconSeedQualityPattern />,
      },
      {
        router: routes.recommendationPeriodsPath,
        label: 'Períodos de Recomendação',
        icon: <IconPeriodo />,
      },
      {
        router: routes.plantabilityRecommendationsPath,
        label: 'Recomendações de Plantabilidade',
        icon: <IconPlantabilityRecommendations />,
      },
      {
        router: routes.regionsPath,
        label: 'Regiões',
        icon: <IconRegions />,
      },
    ],
  };

  const configMenuItems = {
    key: 'settings',
    items: [
      {
        router: routes.settingsPath,
        label: 'Configurações',
        icon: <IconConfiguracao />,
      },
      {
        router: routes.labelsPath,
        label: 'Etiquetas',
        icon: <IconEtiqueta />,
      },
      {
        router: routes.userProfilesAccessPath,
        label: 'Perfis de usuários',
        icon: <IconPerfisUsuarios />,
      },
      {
        router: routes.usersPath,
        label: 'Usuários',
        icon: <IconUsuarios />,
      },
    ],
  };

  const filterMenus = (menu) => {
    const newMenu = menu;
    newMenu.items = menu.items.filter(({ router }) => !!router);

    return !!newMenu.items.length;
  };

  const permittedMenus = [
    menuItems,
    calculatorMenuItems,
    configMenuItems,
  ].filter(filterMenus);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      className={classes.root}
    >
      <div className={classes.closeBtn}>
        <IconButton onClick={handleClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <List className={classes.list}>
        {permittedMenus.map((menu) => (
          <div key={menu.key}>
            <Divider />
            {menu.items.map(({
              router, label, icon, title,
            }) => (
              <ListItem
                title={title}
                button
                component="a"
                href={router}
                key={label}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </Drawer>
  );
};


MenuLateral.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MenuLateral;
