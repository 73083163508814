import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Typography, Grid, Paper, ButtonGroup, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ViewModalTable, { fieldsPropType, dataPropType } from './ViewModalTable';

/**
 * Exibe uma modal para exibição de dados.
 *
 * As childrens devem estar com englobadas em tags <Grid item> </Grid>
 *
 * Define quando a modal está aberta/fechada
 * @open
 *
 * Título da modal
 * @title
 *
 * Parâmetros utilizados pelos botões de fechar
 * @actionsButtons
 */

const ViewModal = ({
  title, actionsButtons, open, maxWidth, maxHeight, fields, data, children,
}) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      maxHeight: maxHeight || '70%',
      backgroundColor: theme.palette.background.paper,
      maxWidth: maxWidth || '600px',
      padding: theme.spacing(2, 4, 3),
      outline: 'none',
      boxShadow: theme.shadows[5],
      overflowY: 'auto',
    },
    buttonGrid: {
      display: 'flex',
    },
    buttonGroup: {
      margin: 'auto',
    },
  }));

  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="form-model-title"
      className={classes.modal}
      open={open}
      onEscapeKeyDown={actionsButtons.close.onClick}
      onBackdropClick={actionsButtons.close.onClick}
    >
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              align="center"
              id="form-model-title"
              className={classes.title}
            >
              {title}
            </Typography>
          </Grid>

          <ViewModalTable
            fields={fields}
            data={data}
          />

          {children}

          <Grid item xs={12} className={classes.buttonGrid}>
            <ButtonGroup className={classes.buttonGroup}>
              <Button
                variant="text"
                color="primary"
                id="modal-close-btn"
                type={actionsButtons.close.type}
                onClick={actionsButtons.close.onClick}
              >
                {actionsButtons.close.label || 'Fechar'}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export const ButtonPropType = {
  disabled: PropTypes.bool,
  onClick: PropTypes.void,
  type: PropTypes.string,
};

ViewModal.defaultProps = {
  actionsButtons: {
    close: {},
  },
};

const actionsButtonsPropTypes = {
  close: PropTypes.shape({ label: PropTypes.string, ...ButtonPropType }),
};

ViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  actionsButtons: PropTypes.shape(actionsButtonsPropTypes),
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  fields: fieldsPropType.isRequired,
  data: dataPropType.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewModal;
