import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import GavelIcon from '@material-ui/icons/Gavel';

import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';
import RedirectListItem from '../../components/RedirectListItem';

const SeedQualityPatterns = (props) => {
  const {
    seedQualityPatterns,
    query,
    routes,
    actionsPermitted,
  } = props;

  const [allSeedQualityPatterns, setSeedQualityPattern] = useState([]);

  const urlNewSeedQualityPattern = routes.newSeedQualityPatternPath;
  const urlSeedQualityPattern = routes.seedQualityPatternsPath;

  useEffect(() => {
    setSeedQualityPattern(seedQualityPatterns.map((seedQualityPattern) => {
      const updatedSeedQualityPattern = { ...seedQualityPattern, id: seedQualityPattern.id };
      return updatedSeedQualityPattern;
    }));
  }, [seedQualityPatterns]);

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Prioridade',
      cell: (row) => row.priority,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.seedQualityPatternPath}
          editUrl={routes.editSeedQualityPatternPath}
          deleteUrl={routes.seedQualityPatternPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir o padrão de qualidade <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        >
          {routes.seedQualityPatternRulesPath && (
            <RedirectListItem
              path={routes.seedQualityPatternRulesPath}
              itemId={row.id}
              description="Regras"
              icon={<GavelIcon fontSize="small" />}
            />
          )}
        </DropdownActionButtons>
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="seedQualityPattern">
          <Typography component="h1" variant="h2" align="center">
            Padrões de Qualidade de Sementes
          </Typography>
          <SearchBar
            url={urlSeedQualityPattern}
            query={query}
          />
          <NewButton
            url={urlNewSeedQualityPattern}
            description="Novo padrão"
          />
          <ListTable
            columns={columns}
            data={allSeedQualityPatterns}
          />
        </div>
      </Container>
    </>
  );
};

export const seedQualityPatternPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
});

SeedQualityPatterns.propTypes = {
  seedQualityPatterns: PropTypes.arrayOf(seedQualityPatternPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionsPermitted: actionsPermittedPropType,
};

export default SeedQualityPatterns;
