import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ListTable from '../../components/ListTable';
import DropdownMenu from '../../components/DropdownMenu';
import DeleteListItem from '../../components/DeleteListItem';
import EditListItem from '../../components/EditListItem';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import BackButton from '../../components/BackButton';
import { labelPropType } from '../label/Labels';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '1rem',
  },
}));

const PrintFields = (props) => {
  const {
    printFields,
    query,
    routes,
    label,
    availableFields,
  } = props;

  const [allPrintFields, setPrintField] = useState([]);

  const urlNewPrintField = routes.newLabelPrintFieldPath.replace(':id', label.id);
  const urlPrintFields = routes.labelPrintFieldsPath.replace(':id', label.id);
  const urlLabels = routes.labelsPath;

  useEffect(() => {
    setPrintField(printFields.map((printField) => {
      const updatedPrintField = { ...printField, id: printField.id };
      return updatedPrintField;
    }));
  }, [printFields]);

  const getPrintFieldDescription = (fieldId) => {
    const field = availableFields.find((availableField) => fieldId === availableField.id);
    return (field || {}).description || fieldId;
  };

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Campo de Impressão',
      cell: (row) => getPrintFieldDescription(row.field),
    },
    {
      header: 'Largura',
      cell: (row) => row.width,
    },
    {
      header: 'Altura',
      cell: (row) => row.height,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <>
          <DropdownMenu>
            <EditListItem
              path={routes.editPrintFieldPath}
              itemId={row.id}
            />
            <DeleteListItem
              path={routes.printFieldPath}
              itemId={row.id}
              message={
                `Você tem certeza de que deseja excluir o campo <b>${row.description}</b>?
                <br>Esta ação é irreversível!`
              }
            />
          </DropdownMenu>
        </>
      ),
      alignRight: true,
    },
  ];

  const classes = useStyles();

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="harvests">
          <Typography component="h1" variant="h2" align="center">
            Campos de Impressão
          </Typography>
          <Typography component="h4" variant="h4" align="center">
            Etiqueta: {label.description}
          </Typography>
          <SearchBar
            url={urlPrintFields}
            query={query}
          />
          <Grid
            container
            spacing={3}
            classes={classes}
          >
            <Grid item xs={6}>
              <BackButton
                url={urlLabels}
                description="Retornar para Etiquetas"
              />
            </Grid>
            <Grid item xs={6}>
              <NewButton
                url={urlNewPrintField}
                description="Novo Campo de Impressão"
              />
            </Grid>
          </Grid>
          <ListTable
            columns={columns}
            data={allPrintFields}
          />
        </div>
      </Container>

    </>
  );
};

export const printFieldPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
});

PrintFields.propTypes = {
  printFields: PropTypes.arrayOf(printFieldPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: labelPropType,
  availableFields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })),
};

export default PrintFields;
