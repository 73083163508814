import React from 'react';

const IconRegions = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="regions" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Localização</title>
      <path d="M36.9,75C15.9,44.5,12,41.4,12,30.2a27.8,27.8,0,0,1,55.5,0c0,11.2-3.9,14.3-24.9,44.8a3.5,3.5,0,0,1-5.7,0Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconRegions;
