import React from 'react';

const IconCampo = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="cultivar" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Safras</title>
      <path d="M4.1,64.6l1.1-1.1c1.8-1.7,3.5-3.4,5.4-5s3.9-3.3,5.8-5a146.6,146.6,0,0,1,13-9.3c2.9-1.8,5.9-3.5,8.8-5.1A107.4,107.4,0,0,1,52,32.7c2.5-1,5.1-1.8,7.6-2.6h.5l-2.2,1.5c-1.5,1.1-3,2.2-4.4,3.4a62.6,62.6,0,0,0-5.3,5c-2.2,2.3-4.3,4.7-6.4,7.1a128.3,128.3,0,0,0-9.2,12.7c-1.9,2.8-3.6,5.7-5.3,8.5s-2.6,4.6-3.8,7a.8.8,0,0,1-.9.4H4.1Z" />
      <path d="M4.1,37.3l3.1-1.1a53.4,53.4,0,0,1,9.6-3L27.1,31c2.3-.4,4.5-.6,6.8-.8l6.3-.5a40.3,40.3,0,0,1,4.9-.3h5.1l-2.9.8c-2.4.8-4.9,1.5-7.2,2.5a93.7,93.7,0,0,0-8.6,3.8,90.9,90.9,0,0,0-11.6,6.9c-2.7,1.9-5.3,3.9-7.9,5.9s-2.6,2.4-4,3.6L4,56.7C4.1,50.2,4.1,43.7,4.1,37.3Z" />
      <path d="M23.3,4.7c1,1.9,1.9,3.8,2.9,5.6l.9,1.3a3,3,0,0,0,3.1,1.1,30.4,30.4,0,0,0,6-2.1l.7-.3a42.9,42.9,0,0,0-1.5,4.2,19.8,19.8,0,0,0-.8,3.3c-.3,1.4.4,2.4,2,3.2l5.5,2.6h.3l-7,3.3a4.7,4.7,0,0,1-1.7.3l-2.9.4a8.5,8.5,0,0,0,.3-7.6,7.9,7.9,0,0,0-4.2-4.2,8.6,8.6,0,0,0-11.5,4.2,8.7,8.7,0,0,0,1.9,10l-5.8,1.7a6.8,6.8,0,0,1,.2-1.5,1.7,1.7,0,0,1,.1-.7,2.6,2.6,0,0,0-1.5-2.8L4.3,24H4.1v-.3l3.1-1.4a38.7,38.7,0,0,0,4.3-2.7,2.4,2.4,0,0,0,.5-1.4A18.7,18.7,0,0,0,10.8,13c-.4-.9-.7-1.9-1.1-2.9l3.4,1.4c1.1.5,2.2.7,3.3,1.1a2.8,2.8,0,0,0,3.3-1.1,33.2,33.2,0,0,0,3.4-6.8Z" />
      <path d="M31.2,75.7A105.4,105.4,0,0,1,69.4,31.1h.2c0,.2-.1.3-.1.4q-2.9,5.9-5.4,11.7c-1.9,4.4-3.6,8.9-5.3,13.4a140.8,140.8,0,0,0-5,16.8l-.4,1.6a.5.5,0,0,1-.6.5H31.2Z" />
      <path d="M75,75.7H61.3l1.8-7.6c1-4,1.9-8.1,3.1-12s2.2-7.5,3.4-11.2a91,91,0,0,1,5.1-12.5c.1-.2.2-.3.2-.5Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconCampo;
