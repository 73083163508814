import React, { useMemo } from 'react';
import {
  Grid,
  Divider,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { HiddenField } from 'react-rails-form-helpers/lib/fields';

const useFields = (seedLot) => useMemo(() => [
  {
    id: 'lotNumber',
    label: 'Número do lote',
    type: 'text',
    autoFocus: true,
    field: 'lot_number',
    validations: [(v) => !!v || 'Número do lote é obrigatório'],
    required: true,
    tab: 0,
  },
  {
    id: 'traceabilityCode',
    label: 'Código de rastreabilidade',
    type: 'text',
    field: 'traceability_code',
    tab: 0,
  },
  {
    id: 'totalQuantity',
    label: 'Quantidade total',
    field: 'total_quantity',
    type: 'number',
    gridSize: 6,
    validations: [
      (v) => v > 0 || 'A quantidade total deve ser maior que 0',
      (v) => !_.isNaN(parseFloat(v)) || 'Quantidade total é obrigatória',
    ],
    required: true,
    tab: 0,
  },
  {
    id: 'measureUnit',
    label: 'Unidade',
    type: 'autocomplete',
    field: 'measure_unit_id',
    validations: [
      (v) => !!v || 'Unidade é obrigatória',
    ],
    gridSize: 6,
    renderLabel: ({ description }) => description,
    required: true,
    tab: 0,
  },
  {
    id: 'packingWeight',
    label: 'Peso da Embalagem (Kg)',
    field: 'packing_weight',
    type: 'number',
    gridSize: 6,
    validations: [
      (v) => !v || v >= 0 || 'O peso embalagem não pode ser menor do que 0',
    ],
    tab: 0,
  },

  {
    id: 'sieve',
    label: 'Tamanho da peneira',
    type: 'autocomplete',
    field: 'sieve_id',
    gridSize: 6,
    validations: [
      (v) => !_.isEmpty(v) || 'Tamanho da peneira é obrigatório',
    ],
    required: true,
    tab: 0,
    renderLabel: ({ description }) => description,
  },
  {
    id: 'producer',
    label: seedLot.repacker ? 'Reembalador' : 'Produtor',
    type: 'autocomplete',
    field: 'producer_id',
    renderLabel: ({ name }) => name,
    tab: 0,
    gridSize: 6,
  },
  {
    id: 'seedBeneficiationUnit',
    label: 'UBS',
    type: 'autocomplete',
    field: 'seed_beneficiation_unit_id',
    renderLabel: ({ name }) => name,
    tab: 0,
    gridSize: 6,
  },
  {
    id: 'productionFields',
    label: 'Campos de produção',
    type: 'autocomplete',
    field: '',
    multiple: true,
    // eslint-disable-next-line react/prop-types
    renderLabel: ({ description, id }) => (
      <>
        {description}
        <HiddenField name="production_field_ids[]" defaultValue={id} />
      </>
    ),
    renderViewLabel: (field) => (field || {}).description,
    tab: 0,
  },
  {
    id: 'crop',
    label: 'Safra',
    type: 'autocomplete',
    field: 'crop_id',
    validations: [
      (v) => !_.isEmpty(v) || 'Safra é obrigatória',
    ],
    required: true,
    renderLabel: ({ description }) => description,
    gridSize: 6,
    tab: 0,
  },
  {
    id: 'cropPeriod',
    label: 'Período',
    type: 'autocomplete',
    field: 'crop_period',
    gridSize: 6,
    renderLabel: ({ description }) => description,
    tab: 0,
  },
  {
    id: 'variety',
    label: 'Cultivar',
    type: 'autocomplete',
    field: 'variety_id',
    validations: [
      (v) => !_.isEmpty(v) || 'Cultivar é obrigatória',
    ],
    required: true,
    renderLabel: ({ description }) => description,
    gridSize: 6,
    tab: 0,
  },
  {
    id: 'varietyCategory',
    label: 'Categoria da Cultivar',
    type: 'autocomplete',
    field: 'variety_category_id',
    validations: [
      (v) => !_.isEmpty(v) || 'Categoria da Cultivar é obrigatória',
    ],
    required: true,
    renderLabel: (category) => category.description,
    gridSize: 6,
    tab: 0,
  },
  {
    id: 'seedQuantity',
    label: 'Quantidade de Sementes',
    type: 'number',
    field: 'seed_quantity',
    validations: [
      (v) => !v || v >= 0 || 'Quantidade de sementes não pode ser menor do que 0',
    ],
    gridSize: 6,
    tab: 0,
  },
  {
    id: 'invoiceCodes',
    label: 'Nota fiscal',
    type: 'autocomplete',
    multiple: true,
    freeSolo: true,
    field: '',
    renderLabel: (value) => (
      <>
        {value}
        <HiddenField name="invoice_codes[]" defaultValue={value} />
      </>
    ),
    gridSize: 6,
    tab: 0,
    values: [],
  },
  {
    id: 'productionOrderNumber',
    label: 'Número da OPS',
    type: 'text',
    field: 'production_order_number',
    gridSize: 6,
    tab: 0,
  },
  {
    id: 'productionOrderDate',
    label: 'Data da OPS',
    type: 'datePicker',
    field: 'production_order_date',
    validations: [
      (v) => (
        _.isEmpty(v)
        || (_.isFunction(v.isValid) && v.isValid())
        || 'Data inválida'
      ),
    ],
    gridSize: 6,
    tab: 0,
  },
  {
    id: 'repacker',
    label: 'Reembalador',
    type: 'checkbox',
    field: 'repacker',
    tab: 0,
    gridSize: 12,
  },
  // Campos utilizados para gerar o array vazio quando nenhum item for selecionado
  {
    id: 'productionField',
    label: '',
    type: 'hidden',
    fieldStyle: { display: 'none' },
    field: 'production_field_ids[]',
    tab: 0,
  },
  {
    id: 'invoiceCode',
    label: '',
    type: 'hidden',
    fieldStyle: { display: 'none' },
    field: 'invoice_codes[]',
    tab: 0,
  },
  // Tab 1
  {
    id: 'purity',
    label: 'Pureza',
    type: 'number',
    field: 'purity',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'inertMaterial',
    label: 'Material Inerte',
    type: 'number',
    field: 'inert_material',
    gridSize: 6,
    tab: 1,
  },

  // outras sementes por número
  {
    id: 'additionalInfo.others',
    label: 'Outras espécies',
    type: 'number',
    field: 'additional_info[others]',
    validations: [
      (v) => Number.isNaN(parseFloat(v)) || v >= 0 || 'O valor não pode ser menor do que 0',
    ],
    gridSize: 6,
    tab: 1,
    elementHeader: (
      <Grid item xs={12}>
        <Typography
          color="textSecondary"
          display="block"
          variant="caption"
        >
          Outras sementes por número
        </Typography>
        <Divider />
      </Grid>
    ),
  },
  {
    id: 'additionalInfo.wild',
    label: 'Sementes silvestres',
    type: 'number',
    field: 'additional_info[wild]',
    validations: [
      (v) => Number.isNaN(parseFloat(v)) || v >= 0 || 'O valor não pode ser menor do que 0',
    ],
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'additionalInfo.harmfulTolerated',
    label: 'Sementes nocivas toleradas',
    type: 'number',
    field: 'additional_info[harmful_tolerated]',
    validations: [
      (v) => Number.isNaN(parseFloat(v)) || v >= 0 || 'O valor não pode ser menor do que 0',
    ],
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'additionalInfo.harmfulForbidden',
    label: 'Sementes nocivas proibidas',
    type: 'number',
    field: 'additional_info[harmful_forbidden]',
    validations: [
      (v) => Number.isNaN(parseFloat(v)) || v >= 0 || 'O valor não pode ser menor do que 0',
    ],
    gridSize: 6,
    elementFooter: <Grid item xs={12}> <Divider /> </Grid>,
    tab: 1,
  },
  // Fim outras sementes por número

  {
    id: 'germination',
    label: 'Germinação',
    type: 'number',
    field: 'germination',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'vigor',
    label: 'Vigor',
    type: 'number',
    field: 'vigor',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'higherAndGreaterVigor',
    label: 'Mais alto e alto vigor',
    type: 'number',
    field: 'higher_and_greater_vigor',
    validations: [
      (v) => Number.isNaN(parseFloat(v)) || v >= 0 || 'O valor não pode ser menor do que 0',
    ],
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'pms',
    label: 'PMS',
    type: 'number',
    field: 'pms',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'infestedSeeds',
    label: 'Sementes infestadas',
    type: 'number',
    field: 'infested_seeds',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'averageSeedBed',
    label: 'Média Canteiro',
    type: 'number',
    field: 'average_seed_bed',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'seedBedAnalysisDate',
    label: 'Data Análise Canteiro',
    type: 'datePicker',
    validations: [
      (v) => (
        _.isEmpty(v)
        || (_.isFunction(v.isValid) && v.isValid())
        || 'Data inválida'
      ),
    ],
    field: 'seed_bed_analysis_date',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'tetrazoliumViability',
    label: 'Viabilidade do Tetrazólio',
    type: 'number',
    field: 'tetrazolium_viability',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'tetrazoliumVigor',
    label: 'Vigor do Tetrazólio',
    type: 'number',
    field: 'tetrazolium_vigor',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'laboratoryAttestationNumber',
    label: 'Número do Termo/Certificado',
    type: 'text',
    field: 'laboratory_attestation_number',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'specialSeeds',
    label: 'Lote com Sementes Especiais',
    type: 'checkbox',
    field: 'special_seeds',
    gridSize: 6,
    tab: 1,
  },
  // Emergência em terra
  {
    id: 'groundEmergency.firstReadDate',
    label: '1ª Leitura',
    type: 'datePicker',
    validations: [
      (v) => (
        _.isEmpty(v) || (_.isFunction(v.isValid) && v.isValid()) || 'Data inválida'
      ),
    ],
    field: 'ground_emergency[first_read_date]',
    gridSize: 6,
    elementHeader: (
      <Grid item xs={12}>
        <Typography
          color="textSecondary"
          display="block"
          variant="caption"
        >
          Emergência em terra
        </Typography>
        <Divider />
      </Grid>
    ),
    tab: 1,
  },
  {
    id: 'groundEmergency.firstReadVigor',
    label: 'Vigor',
    type: 'number',
    field: 'ground_emergency[first_read_vigor]',
    validations: [
      (v) => Number.isNaN(parseFloat(v)) || v >= 0 || 'O valor não pode ser menor do que 0',
    ],
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'groundEmergency.secondReadDate',
    label: '2ª Leitura',
    type: 'datePicker',
    validations: [
      (v) => (
        _.isEmpty(v)
        || (_.isFunction(v.isValid) && v.isValid())
        || 'Data inválida'
      ),
    ],
    field: 'ground_emergency[second_read_date]',
    gridSize: 6,
    tab: 1,
  },
  {
    id: 'groundEmergency.secondReadViabilidade',
    label: 'Viabilidade',
    type: 'number',
    field: 'ground_emergency[second_read_viabilidade]',
    validations: [
      (v) => Number.isNaN(parseFloat(v)) || v >= 0 || 'O valor não pode ser menor do que 0',
    ],
    gridSize: 6,
    tab: 1,
  },
  // fim Emergência em terra
], [seedLot.repacker]);

export default useFields;
