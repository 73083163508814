import React from 'react';

const IconSafra = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="produtores" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Safras</title>
      <path d="M8.6,68.1a6.6,6.6,0,0,0,6.7,6.6H64.2a6.6,6.6,0,0,0,6.7-6.6h0V30.3H8.6ZM53.1,40.8a1.6,1.6,0,0,1,1.6-1.6h5.6A1.7,1.7,0,0,1,62,40.8v5.6a1.8,1.8,0,0,1-1.7,1.7H54.7a1.7,1.7,0,0,1-1.6-1.7Zm0,17.8A1.6,1.6,0,0,1,54.7,57h5.6A1.7,1.7,0,0,1,62,58.6v5.6a1.7,1.7,0,0,1-1.7,1.6H54.7a1.6,1.6,0,0,1-1.6-1.6ZM35.3,40.8A1.7,1.7,0,0,1,37,39.2h5.5a1.7,1.7,0,0,1,1.7,1.6v5.6a1.8,1.8,0,0,1-1.7,1.7H37a1.8,1.8,0,0,1-1.7-1.7Zm0,17.8A1.7,1.7,0,0,1,37,57h5.5a1.7,1.7,0,0,1,1.7,1.6v5.6a1.7,1.7,0,0,1-1.7,1.6H37a1.7,1.7,0,0,1-1.7-1.6ZM17.5,40.8a1.7,1.7,0,0,1,1.7-1.6h5.6a1.6,1.6,0,0,1,1.6,1.6v5.6a1.7,1.7,0,0,1-1.6,1.7H19.2a1.8,1.8,0,0,1-1.7-1.7Zm0,17.8A1.7,1.7,0,0,1,19.2,57h5.6a1.6,1.6,0,0,1,1.6,1.6v5.6a1.6,1.6,0,0,1-1.6,1.6H19.2a1.7,1.7,0,0,1-1.7-1.6ZM64.2,12.5H57.5V5.8a2.2,2.2,0,0,0-2.2-2.2H50.9a2.2,2.2,0,0,0-2.3,2.2v6.7H30.9V5.8a2.2,2.2,0,0,0-2.3-2.2H24.2A2.2,2.2,0,0,0,22,5.8v6.7H15.3a6.7,6.7,0,0,0-6.7,6.7v6.6H70.9V19.2a6.7,6.7,0,0,0-6.7-6.7Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconSafra;
