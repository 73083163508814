import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CalculatorService from '../../../services/CalculatorService';
import { SEED_SPEND_TYPE, TO_KILOGRAMS, TO_SEED } from '../unit_conversion/UnitConversions';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    margin: theme.spacing(4, 1, 0, 1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  gridItem: {
    textAlign: 'center',
  },
}));

const PlantabilityCalculatorResult = (props) => {
  const {
    population,
    isTypeSeedingRate,
    isTypePopulation,
    area,
    spacing,
    efficiency,
    calculationBasis,
    seedingRate,
    purity,
    pms,
    spendType,
    conversionUnit,
  } = props;

  const [updatedSeedsByMeter, setUpdatedSeedsByMeter] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [seedsByHectare, setSeedsByHectare] = useState(null);

  const recalculateInfo = () => {
    const data = {
      seedingRate,
      calculationBasis,
      purity,
      pms,
      area,
      spacing,
      efficiency,
      population,
    };

    setUpdatedSeedsByMeter(CalculatorService.getUpdatedSeedsByMeter(data));

    if (spendType === SEED_SPEND_TYPE) {
      setTotalAmount(CalculatorService.getTotalSeeds(data));
    } else {
      setTotalAmount(CalculatorService.getKgByHectare(data));
    }

    setSeedsByHectare(CalculatorService.getSeedsByHectare(data));
  };

  useEffect(() => {
    recalculateInfo();
  }, [
    area,
    spacing,
    efficiency,
    calculationBasis,
    population,
    seedingRate,
    spendType,
    pms,
  ]);

  const renderNumber = (value) => {
    if (!Number.isFinite(value)) {
      return '--';
    }

    return value.toLocaleString('pt-BR', {
      maximumFractionDigits: 3,
    });
  };

  const getCustomSpent = () => {
    if (!Number.isFinite(totalAmount)) {
      return null;
    }

    return (totalAmount * ((pms / TO_SEED) / TO_KILOGRAMS)) / conversionUnit.conversion;
  };

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.gridContainer} spacing={3}>
        <Grid item xs={6} md={3} lg={3} className={classes.gridItem}>
          <Typography variant="caption" component="p">
            Ajuste
          </Typography>
          <Typography variant="body1" component="p">
            {renderNumber(updatedSeedsByMeter)}
          </Typography>
          <Typography variant="caption" component="p">
            sementes por metro
          </Typography>
        </Grid>
        {isTypeSeedingRate && (
          <Grid item xs={6} md={3} lg={3} className={classes.gridItem}>
            <Typography variant="caption" component="p">
              População
            </Typography>
            <Typography variant="body1" component="p">
              {renderNumber(population)}
            </Typography>
            <Typography variant="caption" component="p">
              plantas/ha
            </Typography>
          </Grid>
        )}
        {isTypePopulation && (
          <Grid item xs={6} md={3} lg={3} className={classes.gridItem}>
            <Typography variant="caption" component="p">
              Total
            </Typography>
            <Typography variant="body1" component="p">
              {renderNumber(seedsByHectare)}
            </Typography>
            <Typography variant="caption" component="p">
              sementes/ha
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} md={3} lg={3} className={classes.gridItem}>
          <Typography variant="caption" component="p">
            Gasto
          </Typography>
          <Typography variant="body1" component="p">
            {renderNumber(totalAmount)}
          </Typography>
          <Typography variant="caption" component="p">
            {spendType === SEED_SPEND_TYPE ? 'sementes' : 'kg'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3} lg={3} className={classes.gridItem}>
          <Typography variant="caption" component="p">
            Gasto
          </Typography>
          <Typography variant="body1" component="p">
            {renderNumber(getCustomSpent())}
          </Typography>
          <Typography variant="caption" component="p">
            {(conversionUnit.description || 'sacos 40kg')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const valueProp = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

PlantabilityCalculatorResult.propTypes = {
  population: valueProp,
  isTypeSeedingRate: PropTypes.bool,
  isTypePopulation: PropTypes.bool,
  area: valueProp,
  spacing: valueProp,
  efficiency: valueProp,
  calculationBasis: valueProp,
  seedingRate: valueProp,
  purity: valueProp,
  pms: valueProp,
  spendType: PropTypes.string.isRequired,
  conversionUnit: PropTypes.shape({
    description: PropTypes.string,
    conversion: PropTypes.number.isRequired,
    measureUnit: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
  }),
};

export default PlantabilityCalculatorResult;
