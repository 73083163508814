import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, Button } from '@material-ui/core';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { GetApp } from '@material-ui/icons';
import Moment from 'moment';
import { HiddenField } from 'react-rails-form-helpers/lib/fields';
import UtilsService from '../../../services/UtilsService';
import FileUtilsService from '../../../services/FileUtilsService';
import { seedBulletinPropType } from './SeedBulletins';
import { SeedLotPropType } from '../seedLot/SeedLots';
import FormModal from '../../components/FormModal';
import TabsModal from '../../components/TabsModal';
import TraceabilityDivider from '../../components/TraceabilityDivider';
import CreateLaboratoryModal from '../laboratory/CreateLaboratoryModal';
import { laboratoryType, actionsPermittedPropType } from '../../../services/GeneralTypes';
import FormService from '../../../services/FormService';
import MessageSnackBar from '../../components/MessageSnackBar';

export const fields = [
  // Tab 0
  {
    id: 'description',
    label: 'Número do Boletim',
    type: 'text',
    field: 'bulletin[description]',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    autoFocus: true,
    required: true,
    tab: 0,
  },
  {
    id: 'selectedSeedLots',
    label: 'Lote de sementes',
    type: 'autocomplete',
    field: '',
    multiple: true,
    optionSelected: ({ id: newId }, { id: currentId }) => newId === currentId,
    renderViewLabel: (seedLot) => seedLot.lotNumber,
    tab: 0,
  },
  {
    id: 'seedLotIds',
    label: '',
    type: 'hidden',
    fieldStyle: { display: 'none' },
    field: 'bulletin[seed_lot_ids][]',
    tab: 0,
  },
  {
    id: 'laboratory',
    label: 'Laboratório',
    type: 'autocomplete',
    field: '',
    gridSize: 11,
    renderLabel: ({ name }) => name,
    optionSelected: ({ id: newId }, { id: currentId }) => newId === currentId,
    tab: 0,
  },
  {
    id: 'germinationTestValidity',
    label: 'Validade Teste Germinação',
    type: 'datePicker',
    validations: [
      (v) => (
        _.isEmpty(v)
        || (_.isFunction(v.isValid) && v.isValid())
        || 'Data inválida'
      ),
    ],
    field: 'bulletin[germination_test_validity]',
    gridSize: 6,
    tab: 0,
  },
  {
    id: 'analysisEndDate',
    label: 'Data de conclusão da análise',
    type: 'datePicker',
    validations: [
      (v) => (
        _.isEmpty(v)
        || (_.isFunction(v.isValid) && v.isValid())
        || 'Data inválida'
      ),
    ],
    field: 'bulletin[analysis_end_date]',
    gridSize: 6,
    tab: 0,
  },
];

const getInitialDate = (date) => (
  _.isNil(date)
    ? null
    : new Moment(date).utcOffset(date)
);

const initialImage = (images = []) => FileUtilsService.processInitialFile('images', images);

const initialDocuments = (documents = []) => (
  FileUtilsService.processInitialFile('documents', documents)
);

export const buildSeedBulletin = (seedBulletin) => {
  const images = initialImage(UtilsService.getObjectValue(seedBulletin, 'images', []));
  const documents = initialDocuments(UtilsService.getObjectValue(seedBulletin, 'documents', []));

  if (FormService.hasData) {
    const {
      hasImages,
      hasDocuments,
      ...filtredData
    } = FormService.data;

    return {
      ...filtredData,
      images,
      documents,
    };
  }

  return {
    images,
    documents,
    description: UtilsService.getObjectValue(seedBulletin, 'description'),
    selectedSeedLots: UtilsService.getObjectValue(seedBulletin, 'seedLots', []),
    laboratory: UtilsService.getObjectValue(seedBulletin, 'laboratory', {}),
    germinationTestValidity: getInitialDate(
      UtilsService.getObjectValue(
        seedBulletin,
        'germinationTestValidity',
        null,
      ),
    ),
    analysisEndDate: getInitialDate(
      UtilsService.getObjectValue(
        seedBulletin,
        'analysisEndDate',
        null,
      ),
    ),
  };
};

const EditseedBulletinModal = ({
  routes,
  seedBulletin,
  seedLots,
  laboratories,
  actionsPermittedLaboratory,
}) => {
  const [laboratoryModalOpen, setLaboratoryModalOpen] = useState(false);
  const [state, setState] = useState(() => buildSeedBulletin(seedBulletin));
  const [messageSnackBar, setMessageSnackBar] = useState({ variant: '', message: '' });
  const [open, setOpen] = useState(true);
  const [edit] = useState(!!seedBulletin);
  const [imagesFields, setImagesFields] = useState([]);
  const [documentsFields, setDocumentsFields] = useState([]);
  const [laboratoryFields, setLaboratoryFields] = useState([]);
  const [forceUpdate, setForceUpdate] = useState({
    images: false,
    documents: false,
  });

  const closeLaboratoryModal = () => setLaboratoryModalOpen(false);

  const dowloadBtn = (url, disabled) => (
    <Grid item xs={1} style={{ paddingLeft: 0 }}>
      <Button fullWidth style={{ padding: 0, height: 36 }} disabled={disabled}>
        <Link href={url} color="inherit" download>
          <GetApp />
        </Link>
      </Button>
    </Grid>
  );

  const removeFile = (key, objectKey) => {
    const newState = { ...state };
    const currentObj = newState[objectKey][key];

    if (currentObj.id) {
      currentObj.destroy = true;

      setForceUpdate({
        ...forceUpdate,
        [objectKey]: true,
      });
    } else {
      delete newState[objectKey][key];
    }

    setState(newState);
  };

  const getFileFields = (key, objectKey, isLast, fileLabel, descriptionLabel, tab) => {
    const currentObj = _.get(state, [objectKey, key]);

    if (typeof currentObj !== 'object') return [];
    const index = Object.keys(state[objectKey]).indexOf(key);

    const hiddenClass = { display: 'none' };

    const fileFields = [
      {
        elementHeader: (
          <Grid item xs={12}>
            <TraceabilityDivider
              onClose={() => removeFile(key, objectKey)}
              disabled={isLast}
            />
          </Grid>
        ),
        id: `${objectKey}.${key}.file`,
        label: fileLabel,
        type: 'file',
        field: `bulletin[${objectKey}_attributes[${index}][file]]`,
        validations: [
          () => (currentObj.hasFile || !currentObj.description) || 'Arquivo é obrigatório',
          () => currentObj.validFile || 'Arquivo inválido',
        ],
        gridSize: 11,
        tab,
        valueDefault: '',
        elementFooter: dowloadBtn(currentObj.fileUrl, !currentObj.fileUrl),
        fileName: currentObj.fileName,
        currentObjKey: key,
      },
      {
        id: `${objectKey}.${key}.description`,
        label: descriptionLabel,
        type: 'text',
        field: `bulletin[${objectKey}_attributes[${index}][description]]`,
        tab,
        valueDefault: '',
      },
      {
        id: `${objectKey}.${key}.id`,
        label: '',
        type: 'text',
        field: currentObj.id ? `bulletin[${objectKey}_attributes[${index}][id]]` : '',
        fieldStyle: hiddenClass,
        tab,
        valueDefault: '',
      },
      {
        id: `${objectKey}.${key}.destroy`,
        label: '',
        type: 'text',
        field: `bulletin[${objectKey}_attributes[${index}][_destroy]]`,
        fieldStyle: hiddenClass,
        tab,
        valueDefault: '',
      },
    ];

    if (currentObj.destroy) {
      fileFields.forEach((field) => {
        const newField = field;

        newField.validations = [];
        newField.elementHeader = null;
        newField.elementFooter = null;
        newField.fieldStyle = hiddenClass;
      });
    }

    if (isLast) {
      fileFields.forEach((field) => {
        const newField = field;

        newField.field = '';
      });
    }

    return fileFields;
  };

  const setNewFile = (objectKey) => {
    setState((oldState) => {
      const newState = { ...oldState };
      const key = _.uniqueId(`${objectKey}_`);

      newState[objectKey][key] = FileUtilsService.initialFileState();
      return newState;
    });
  };

  const validateHasFile = (event) => event.currentTarget.files.length > 0;

  const validateFileType = ({ currentTarget }) => {
    const type = _.get(currentTarget, 'files[0].type');
    const { accept } = currentTarget;

    if (type && accept) {
      const regex = new RegExp(`^${accept.replace('*', '.*')}$`);
      return regex.test(type);
    }

    return true;
  };

  const updateFileUrl = (fileObject, objectKey) => {
    const currentObj = fileObject;

    if (currentObj.fileUrl) {
      currentObj.fileUrl = '';

      setForceUpdate({
        ...forceUpdate,
        [objectKey]: true,
      });
    }
  };

  const onChangeFile = (objectKey, key, event, isLast) => {
    if (isLast) setNewFile(objectKey, key);

    const hasFile = validateHasFile(event);
    const validFileType = validateFileType(event);

    setState((prevState) => {
      const currentObj = prevState[objectKey][key];

      currentObj.hasFile = hasFile;
      currentObj.validFileType = validFileType;
      currentObj.id = '';

      updateFileUrl(currentObj, objectKey);

      return prevState;
    });
  };

  const createFileFields = (objectKey, fileLabel, descriptionLabel, tab) => (
    Object.keys(state[objectKey]).reduce((acc, key, index, objectKeys) => {
      const isLast = objectKeys.length === index + 1;
      const newFileFields = getFileFields(
        key,
        objectKey,
        isLast,
        fileLabel,
        descriptionLabel,
        tab,
      );

      const fileField = newFileFields.find(({ type }) => type === 'file');

      if (objectKey === 'images') fileField.accept = 'image/*';

      fileField.onChange = (_id, _value, event) => onChangeFile(objectKey, key, event, isLast);

      return acc.concat(newFileFields);
    }, [])
  );

  const createImagesFields = () => (
    createFileFields('images', 'Imagem', 'Descrição da imagem', 1)
  );

  const createDocumentsFields = () => (
    createFileFields('documents', 'Documento', 'Descrição do documento', 2)
  );

  useEffect(() => {
    setImagesFields(createImagesFields());
  }, [Object.keys(state.images).length]);

  useEffect(() => {
    if (forceUpdate.images) {
      setImagesFields(createImagesFields());

      setForceUpdate({
        ...forceUpdate,
        images: false,
      });
    }
  }, [forceUpdate.images]);

  useEffect(() => {
    setDocumentsFields(createDocumentsFields());
  }, [Object.keys(state.documents).length]);

  useEffect(() => {
    if (forceUpdate.documents) {
      setDocumentsFields(createDocumentsFields());

      setForceUpdate({
        ...forceUpdate,
        documents: false,
      });
    }
  }, [forceUpdate.documents]);

  const onChangeLaboratory = (_id, value) => {
    if (_.isEmpty(value)) {
      setLaboratoryFields([]);
    } else if (value.id) {
      setLaboratoryFields([{
        field: 'bulletin[laboratory_id]',
        defaultValue: value.id,
        id: 'laboratory.id',
      }]);
    }
  };

  useEffect(() => {
    const { data, hasData } = FormService;

    if (!hasData) return;

    let message = '';

    if (data.hasImages && data.hasDocuments) message = 'Não foi possível recuperar os arquivos';
    else if (data.hasImages) message = 'Não foi possível recuperar as imagens';
    else if (data.hasDocuments) message = 'Não foi possível recuperar os documentos';

    if (message) {
      setMessageSnackBar({
        message,
        variant: 'warning',
      });
    }
  }, []);

  const additionalInputsInfo = {
    selectedSeedLots: {
      // eslint-disable-next-line react/prop-types
      renderLabel: ({ lotNumber, id }) => {
        const lodashSeedLot = _(seedLots.find((lot) => lot.id === id)).omitBy(_.isNil);

        return (
          <>
            {`${lotNumber} (Safra: ${lodashSeedLot.get('crop.description', '')})`}
            <HiddenField name="bulletin[seed_lot_ids][]" defaultValue={id} />
          </>
        );
      },
      values: seedLots,
    },
    laboratory: {
      elementFooter: (
        <Grid item xs={1} style={{ paddingLeft: 0 }}>
          <Button
            disabled={!actionsPermittedLaboratory.includes('create')}
            fullWidth
            style={{
              padding: 0,
              height: '100%',
              minWidth: 'auto',
            }}
            color="primary"
            onClick={() => setLaboratoryModalOpen(true)}
          >
            <AddIcon />
          </Button>
        </Grid>
      ),
      onChange: onChangeLaboratory,
      values: laboratories,
    },
  };

  const generalFields = UtilsService.updateFields(fields, additionalInputsInfo);

  const validInputs = () => UtilsService.validateState([
    ...generalFields,
    ...imagesFields,
    ...documentsFields,
  ], state);

  const handleChange = (id, value) => {
    const newState = { ...state };
    _.set(newState, id, value);
    setState(newState);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = () => {
    const { images, documents, ...filtedState } = state;
    const hasFile = (file) => !(file.id && !file.destroy) && FileUtilsService.hasValidFile(file);

    FormService.data = {
      ...filtedState,
      hasImages: Object.values(images).some(hasFile),
      hasDocuments: Object.values(documents).some(hasFile),
    };
  };

  const onSubmitLaboratory = (laboratoryState, newLaboratoryFields, event) => {
    event.preventDefault();

    setLaboratoryFields(newLaboratoryFields.map(
      ({ field, id }) => ({
        field: `laboratory[${field}]`,
        id: `laboratory.${id}`,
        defaultValue: _.get(laboratoryState, id),
      }),
    ));

    const newState = { ...state };
    newState.laboratory = laboratoryState;

    setState(newState);
    closeLaboratoryModal();
  };

  const urlForm = edit
    ? routes.seedBulletinPath.replace(':id', seedBulletin.id)
    : routes.seedBulletinsPath;

  const tabs = [
    {
      label: 'Básico',
      icon: <AssignmentOutlinedIcon />,
      key: 'basic',
    },
    {
      label: 'Imagens',
      icon: <PhotoLibraryOutlinedIcon />,
      key: 'image',
    },
    {
      label: 'Documentos',
      icon: <DescriptionOutlinedIcon />,
      key: 'document',
    },
  ];

  return (
    <>
      <CreateLaboratoryModal
        open={laboratoryModalOpen}
        routes={routes}
        onCancel={closeLaboratoryModal}
        onSubmit={onSubmitLaboratory}
        laboratories={laboratories}
      />
      <FormModal
        open={open}
        title="Boletim"
        formForProps={{
          url: urlForm,
          method: edit ? 'put' : 'post',
          encType: 'multipart/form-data',
          id: 'seedBulletinForm',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            disabled: !validInputs(),
            label: 'Salvar',
            form: 'seedBulletinForm',
          },
          cancel: {
            onClick: handleClose,
          },
        }}
        maxWidth="50%"
        maxHeight="85%"
      >
        <TabsModal
          tabs={tabs}
          fields={[
            ...generalFields,
            ...imagesFields,
            ...documentsFields,
          ]}
          handleChange={handleChange}
          value={state}
        />

        {laboratoryFields.map(({
          defaultValue,
          field,
          id,
        }) => (
          <HiddenField
            defaultValue={defaultValue}
            name={field}
            id={id}
            key={id}
          />
        ))}
      </FormModal>
      {messageSnackBar.message && messageSnackBar.variant && (
        <MessageSnackBar
          message={messageSnackBar.message}
          variant={messageSnackBar.variant}
        />
      )}
    </>
  );
};

EditseedBulletinModal.propTypes = {
  seedBulletin: seedBulletinPropType,
  seedLots: PropTypes.arrayOf(SeedLotPropType).isRequired,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  laboratories: PropTypes.arrayOf(PropTypes.shape(laboratoryType)),
  actionsPermittedLaboratory: actionsPermittedPropType,
};

export default EditseedBulletinModal;
