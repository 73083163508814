import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const cardIconStyle = {
  cardTitle: {
    color: '#3C4858',
    marginTop: '5px',
    minHeight: 'auto',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 300,
    marginBottom: '0px',
    textDecoration: 'none',
    fontSize: '1.5em',
    lineHeight: '1.4em',
  },
};

function CardTitle({ ...props }) {
  const { classes, className, title } = props;
  const cardTitleClasses = classNames({
    [classes.cardTitle]: true,
    [className]: className !== undefined,
  });
  return (
    <Typography variant="h6" component="h3" className={cardTitleClasses}>
      {title}
    </Typography>
  );
}

CardTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(cardIconStyle)(CardTitle);
