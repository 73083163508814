import React, { useState } from 'react';
import { laboratoryPropType } from './Laboratories';
import { fields, buildLaboratory } from './EditLaboratoryModal';

import ViewModal from '../../components/ViewModal';

const ViewLaboratoryModal = (props) => {
  const {
    laboratory,
  } = props;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Laboratório"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildLaboratory(laboratory)}
      />
    </>
  );
};

ViewLaboratoryModal.propTypes = {
  laboratory: laboratoryPropType,
};

export default ViewLaboratoryModal;
