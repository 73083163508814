import React, { useState } from 'react';
import { varietyPropType } from './Varieties';
import { fields, buildVariety } from './EditVarietyModal';
import ViewModal from '../../components/ViewModal';

const ViewVarietyModal = (props) => {
  const { variety } = props;

  const data = buildVariety(variety);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Cultivar"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={data}
      />
    </>
  );
};

ViewVarietyModal.propTypes = {
  variety: varietyPropType,
};

export default ViewVarietyModal;
