import React from 'react';

const IconConfiguracao = () => {
  const styles = {
    fillcolor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="configuracoes" style={styles.fillcolor} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 79.5 78.36">
      <path d="M75.38,48.28l-6.51-3.75a29.27,29.27,0,0,0,0-10.72l6.51-3.76a1.86,1.86,0,0,0,.84-2.14,38.14,38.14,0,0,0-8.36-14.44,1.83,1.83,0,0,0-2.26-.35l-6.5,3.75a28.88,28.88,0,0,0-9.29-5.36V4a1.82,1.82,0,0,0-1.43-1.79,38.46,38.46,0,0,0-16.68,0A1.82,1.82,0,0,0,30.27,4v7.51A29.58,29.58,0,0,0,21,16.89l-6.49-3.76a1.81,1.81,0,0,0-2.26.35A37.93,37.93,0,0,0,3.88,27.93a1.83,1.83,0,0,0,.84,2.14l6.51,3.75a29.27,29.27,0,0,0,0,10.72L4.72,48.3a1.85,1.85,0,0,0-.84,2.14,38.12,38.12,0,0,0,8.35,14.44,1.83,1.83,0,0,0,2.26.35L21,61.48a28.83,28.83,0,0,0,9.28,5.36v7.51a1.85,1.85,0,0,0,1.44,1.79,38.41,38.41,0,0,0,16.67,0,1.85,1.85,0,0,0,1.44-1.79V66.84a29.92,29.92,0,0,0,9.28-5.36l6.51,3.75a1.81,1.81,0,0,0,2.26-.35,37.77,37.77,0,0,0,8.35-14.44A1.9,1.9,0,0,0,75.38,48.28ZM40,51.38A12.22,12.22,0,1,1,52.26,39.17,12.24,12.24,0,0,1,40,51.38Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconConfiguracao;
