import React from 'react';
import PropTypes from 'prop-types';
import PrintIcon from '@material-ui/icons/Print';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FormFor } from 'react-rails-form-helpers/lib/forms';

const PrintListItem = (props) => {
  const {
    itemId, path, forwardedRef, currentPage,
  } = props;
  const urlPrint = path.replace(':id', itemId);

  return (
    <FormFor
      url={urlPrint}
      method="get"
      id={urlPrint}
      name={urlPrint}
    >
      <input
        name="page"
        value={currentPage}
        type="hidden"
      />
      <ListItem
        button
        type="submit"
        component="button"
        form={urlPrint}
        className="viewButton"
        ref={forwardedRef}
      >
        <ListItemIcon>
          <PrintIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Imprimir" />
      </ListItem>
    </FormFor>
  );
};

PrintListItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  forwardedRef: PropTypes.func,
  currentPage: PropTypes.number,
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <PrintListItem {...props} forwardedRef={ref} />);
