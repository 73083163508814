import React, { useState } from 'react';
import { recommendationPeriodPropType } from './RecommendationPeriods';
import { fields, buildRecommendationPeriod } from './EditRecommendationPeriodModal';
import ViewModal from '../../components/ViewModal';

const ViewRecommendationPeriodModal = (props) => {
  const { recommendationPeriod } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Período de Recomendação"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildRecommendationPeriod(recommendationPeriod)}
      />
    </>
  );
};

ViewRecommendationPeriodModal.propTypes = {
  recommendationPeriod: recommendationPeriodPropType,
};

export default ViewRecommendationPeriodModal;
