import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  Button, ButtonGroup, TextField, Grid, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { Label } from 'react-rails-form-helpers/lib/fields';
import moment from 'moment';
import CustomAutocomplete from './CustomAutocomplete';
import UtilsService from '../../services/UtilsService';

/**
 *Exibe um input com label flutuante, de acordo com os padrões do
 * Google Material Design. Também tem suporte a regras de validação
 * com mensagens de erros personalizadas abaixo do campo. Todas as
 * props válidas para um input também são aceitas neste componente,
 * como `type` e outros.
 *
 * @visibleName Floating Label Input
 * @export
 * @class FloatingLabelInput
 * @extends {React.Component<PropTypes, StateTypes>}
 */

const FloatingLabelInput = (props) => {
  const {
    fieldClasses,
    id,
    name,
    type,
    inputClasses,
    validationRules,
    label,
    value,
    gridSize,
    format,
    placeholder,
    required,
    autoFocus,
    values,
    renderLabel,
    warningsRules,
    multiple,
    accept,
    fieldStyle,
    optionSelected,
    InputProps,
    fileName: fileNameProp,
    checked,
    disabled,
    disableClearable,
    helperMessage,
    freeSolo,
  } = props;

  /**
   * Valida o input de acordo com as regras passadas no `validationRules`.
   *
   * @param {string} value Valor atual do campo.
   * @param {ValidationRule[]} rules Regras passadas pela prop.
   * @returns {ValidationResult} `true` se estiver válido, ou uma string com mensagem dentro.
   */

  const validateInput = (
    valueInput,
    idInput,
    rules,
  ) => (rules || []).reduce((result, rule) => {
    if (result === true) {
      return rule(valueInput, idInput);
    }
    return result;
  }, true);

  const [dirty, setDirty] = useState(false);
  const [fileName, setFileName] = useState(fileNameProp || '');

  const getFilesName = (files) => (
    Array.from(files).map((file) => file.name).join(', ')
  );

  const onChangeCallback = (idChanged, valueChanged, event) => {
    const { onChange } = props;

    if (value !== valueChanged) {
      setDirty(true);
    }

    onChange(idChanged, valueChanged, event);
  };

  const onChangeInput = (event) => {
    const { id: idCurrent, value: valueCurrent, files } = event.currentTarget || event.target;

    if (type === 'file') {
      setFileName(getFilesName(files));
    }
    onChangeCallback(idCurrent, valueCurrent, event);
  };

  const onChangeCheckbox = (event) => {
    const { id: idCurrent, checked: valueCurrent } = event.currentTarget || event.target;

    onChangeCallback(idCurrent, valueCurrent, event);
  };

  const getFieldClassName = () => {
    const names = {
      datePicker: 'fieldDatePicker',
      autocomplete: 'fieldAutocomplete',
      file: 'typeFile',
      default: 'inputField',
    };
    if (names[type]) {
      return names[type];
    }
    return names.default;
  };

  useEffect(() => {
    UtilsService.setMomentLocale();
  }, []);

  useEffect(() => {
    if (!fileNameProp && fileName !== fileNameProp) {
      setFileName('');
    }
  }, [fileNameProp]);

  const fieldClassNames = classNames(getFieldClassName(), fieldClasses);
  const message = useMemo(() => {
    const inputValidation = validateInput(value, id, validationRules);
    const inputWarning = validateInput(value, id, warningsRules);

    if (dirty && inputValidation !== true) {
      return {
        value: inputValidation,
        type: 'error',
      };
    }

    if (dirty && inputWarning !== true) {
      return {
        value: inputWarning,
        type: 'warning',
      };
    }

    if (helperMessage) {
      return {
        value: helperMessage,
        type: 'help',
      };
    }

    return null;
  }, [value, id, validationRules, warningsRules, helperMessage, dirty]);
  const isWarningMessage = (message || {}).type === 'warning';
  const isErrorMessage = (message || {}).type === 'error';
  const inputClassNames = classNames('input', {
    'is-danger': isErrorMessage,
    'is-warning': isWarningMessage,
  }, inputClasses);
  const messageClassNames = classNames('help', {
    'is-danger': isErrorMessage,
    'is-warning': isWarningMessage,
  });

  const datePicker = () => (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="pt-br">
      <KeyboardDatePicker
        id={id}
        inputVariant="outlined"
        value={value}
        onChange={(changedValue) => onChangeCallback(id, changedValue)}
        format={format || 'DD/MM/YYYY'}
        placeholder={placeholder}
        label={label}
        name={name}
        cancelLabel="Cancelar"
        required={required}
        autoFocus={!!autoFocus}
        fullWidth
        invalidDateMessage=""
      />
    </MuiPickersUtilsProvider>
  );

  const autocomplete = () => (
    <CustomAutocomplete
      values={values}
      renderLabel={renderLabel}
      label={label}
      name={name}
      id={id}
      selected={value}
      required={required}
      multiple={multiple}
      onChange={(changedValue) => onChangeCallback(id, changedValue)}
      optionSelected={optionSelected}
      disableClearable={disableClearable}
      freeSolo={freeSolo}
    />
  );

  const typeFile = () => (
    <>
      <input
        style={{ display: 'none' }}
        id={id}
        name={name}
        value={value}
        required={required}
        onChange={onChangeInput}
        multiple={multiple}
        accept={accept}
        type="file"
      />
      <Label htmlFor={id}>
        <ButtonGroup
          fullWidth
          title={fileName}
          style={{ height: 36, overflow: 'hidden', WebkitLineClamp: 1 }}
        >
          <Button component="div" variant="contained" color="primary" style={{ width: '50%' }}>
            {label}
          </Button>
          <Button component="div">
            <p style={{ display: '-webkit-box', height: '-webkit-fill-available' }}>
              {fileName || 'Nenhum arquivo selecionado'}
            </p>
          </Button>
        </ButtonGroup>
      </Label>
    </>
  );

  const defaultInput = () => (
    <TextField
      id={id}
      label={label}
      name={name}
      type={type}
      fullWidth
      className={inputClassNames}
      onChange={onChangeInput}
      value={value}
      variant="outlined"
      required={required}
      autoFocus={!!autoFocus}
      InputProps={InputProps}
      disabled={disabled}
    />
  );

  const checkbox = () => (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checked}
          onChange={onChangeCheckbox}
          name={name}
          value={value}
          id={id}
          disabled={disabled}
          color="primary"
        />
      )}
      label={label}
    />
  );

  const checkboxAccess = () => (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checked}
          onChange={onChangeInput}
          name={name}
          value={value}
          id={id}
          disabled={disabled}
          color="primary"
        />
      )}
      label={label}
    />
  );

  const inputTypes = {
    datePicker,
    autocomplete,
    file: typeFile,
    default: defaultInput,
    checkbox,
    checkboxAccess,
  };

  const renderInput = () => {
    if (inputTypes[type]) {
      return inputTypes[type]();
    }
    return inputTypes.default();
  };

  return (
    <>
      <Grid item xs={gridSize || 12} className={fieldClassNames} style={fieldStyle}>
        {renderInput()}
        {message && <p className={messageClassNames}>{message.value}</p>}
      </Grid>
    </>
  );
};

FloatingLabelInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldClasses: PropTypes.any,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inputClasses: PropTypes.string,
  validationRules: PropTypes.arrayOf(PropTypes.func),
  warningsRules: PropTypes.arrayOf(PropTypes.func),
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  gridSize: PropTypes.number,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  renderLabel: PropTypes.func,
  multiple: PropTypes.bool,
  fileName: PropTypes.string,
  accept: PropTypes.string,
  fieldStyle: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
  optionSelected: PropTypes.func,
  InputProps: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  helperMessage: PropTypes.string,
  freeSolo: PropTypes.bool,
};

export default FloatingLabelInput;
