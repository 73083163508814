import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ListTable from '../../components/ListTable';
import { seedQualityPatternPropType } from '../seedQualityPattern/SeedQualityPatterns';
import NewButton from '../../components/NewButton';
import BackButton from '../../components/BackButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const SeedQualityRules = (props) => {
  const {
    seedQualityRules,
    seedQualityPattern,
    routes,
    actionsPermitted,
  } = props;

  const { newSeedQualityPatternRulePath: newRulePath } = routes;

  const urlNewRule = newRulePath && newRulePath.replace(':id', seedQualityPattern.id);
  const urlSeedQualityPatterns = routes.seedQualityPatternsPath;

  const useStyles = makeStyles(() => ({
    root: {
      marginBottom: '1rem',
    },
  }));

  const classes = useStyles();

  const columns = [
    {
      header: 'Atributo',
      cell: (row) => row.attributeHumanized,
    },
    {
      header: 'Operação',
      cell: (row) => row.operationHumanized,
    },
    {
      header: 'Valor',
      cell: (row) => row.value,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.rulePath}
          editUrl={routes.editRulePath}
          deleteUrl={routes.rulePath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir esta regra?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="harvests">
          <Typography component="h1" variant="h2" align="center">
            Regras
          </Typography>
          <Typography component="h4" variant="h4" align="center">
            Padrão de Qualidade: {seedQualityPattern.description}
          </Typography>
          <Grid
            container
            spacing={3}
            classes={classes}
          >
            <Grid item xs={6}>
              <BackButton
                url={urlSeedQualityPatterns}
                description="Retornar para Padrões de Qualidade"
              />
            </Grid>
            <Grid item xs={6}>
              <NewButton
                url={urlNewRule}
                description="Nova Regra"
              />
            </Grid>
          </Grid>
          <ListTable
            columns={columns}
            data={seedQualityRules}
          />
        </div>
      </Container>
    </>
  );
};


export const rulePropType = PropTypes.shape({
  id: PropTypes.string,
  attribute: PropTypes.string,
  operation: PropTypes.string,
  value: PropTypes.number,
  seedQualityPattern: seedQualityPatternPropType,
});

SeedQualityRules.propTypes = {
  seedQualityRules: PropTypes.arrayOf(rulePropType).isRequired,
  seedQualityPattern: seedQualityPatternPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default SeedQualityRules;
