import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ContactsIcon from '@material-ui/icons/Contacts';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  buttonContact: {
    marginRight: theme.spacing(4),
  },
  list: {
    width: 250,
  },
  logo: {
    alignItems: 'center',
  },
  img: {
    margin: theme.spacing(2, 0),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  paper: {
    margin: theme.spacing(2, 1),
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    minWidth: '250px',
    height: '100%',
  },
  content: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
}));

const Contact = ({ config }) => {
  const { header } = config.content;
  const { contact, company, links } = header;
  const color = get(config, 'style.toolbar.text.color');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isDrawerOpen = Boolean(anchorEl);

  const handleContactOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContactClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <>
      <div
        className={classes.buttonContact}
        role="presentation"
      >
        <Button
          style={{ color }}
          onClick={handleContactOpen}
          startIcon={<ContactsIcon />}
        >
          <Box display={{ xs: 'none', md: 'block' }} m={1}>
            Contato
          </Box>
        </Button>
      </div>
      <Drawer
        open={isDrawerOpen}
        onClose={handleContactClose}
        anchor="right"
      >
        <Grid container component="main" className={classes.root}>
          <Grid item xs={12} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Grid container className={classes.logo}>
                <Grid item style={{ width: '100px' }}>
                  <Avatar className={classes.avatar}>
                    <img
                      src={header.logo}
                      alt={header.title}
                      className={classes.img}
                    />
                  </Avatar>
                </Grid>
                <Grid item xs className={classes.logo}>
                  <Typography component="h6" variant="h6">
                    {company.name}
                  </Typography>
                </Grid>

              </Grid>
              {contact.phones && contact.phones.length > 0 && (
                <Grid container className={classes.content}>
                  <Grid item xs={12}>
                    <Typography component="h6" variant="h6" color="textSecondary">
                      Contato:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {contact.phones.map((phone) => (
                      <div key={phone.number}>
                        {phone.description && <p className={classes.item}>phone.description</p>}
                        <p className={classes.item}>
                          {phone.type === 'whatsapp' ? (
                            <WhatsAppIcon />
                          ) : (
                            <PhoneIcon />
                          )}
                          {' '}
                          {phone.number}
                        </p>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              )}
              <Grid container className={classes.content}>
                <Grid item xs={12}>
                  <Typography component="h6" variant="h6" color="textSecondary">
                    Links:
                  </Typography>
                </Grid>

                {links && links.length > 0 && (
                  <Grid item xs={12}>
                    {links.map((link) => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={link.url}
                        key={link.url}
                        className={classes.item}
                      >
                        {link.description}
                      </a>
                    ))}
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

Contact.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Contact;
