export default class PdfUtilsService {
  static b64toBlob(content, contentType = '') {
    const sliceSize = 512;
    // method which converts base64 to binary
    const byteCharacters = window.atob(content);

    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType,
    });

    return blob;
  }

  static openPdfWindow(base64Content) {
    const type = 'application/pdf';
    let blob = null;
    blob = PdfUtilsService.b64toBlob(base64Content, type);
    const blobURL = URL.createObjectURL(blob);
    return window.open(blobURL);
  }
}
