import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const ProfileAccess = (props) => {
  const {
    userProfilesAccess,
    query,
    routes,
    actionsPermitted,
  } = props;

  const getUsersNames = ({ users }) => {
    let result = '';
    const concatUsersNames = () => users.map(({ name }) => name).join(', ');

    if (users.length > 7) {
      const usersRemovedCount = users.splice(7).length;
      result = concatUsersNames();

      result += `... + ${usersRemovedCount}`;
    } else {
      result = concatUsersNames();
    }

    return result;
  };

  const columns = [
    {
      header: 'Descrição',
      cell: ({ description }) => description,
    },
    {
      header: 'Usuários',
      cell: getUsersNames,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.userProfileAccessPath}
          editUrl={routes.editUserProfileAccessPath}
          deleteUrl={routes.userProfileAccessPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir o perfil de usuário <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <Typography component="h1" variant="h2" align="center">
          Perfis de usuários
        </Typography>
        <SearchBar
          url={routes.userProfilesAccessPath}
          query={query}
        />
        <NewButton
          url={routes.newUserProfileAccessPath}
          description="Novo Perfil"
        />
        <ListTable
          columns={columns}
          data={userProfilesAccess}
        />
      </Container>
    </>
  );
};

export const userProfileAccessPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  permissions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
});

ProfileAccess.propTypes = {
  userProfilesAccess: PropTypes.arrayOf(userProfileAccessPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionsPermitted: actionsPermittedPropType,
};

export default ProfileAccess;
