import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import FloatingLabelInput from './FloatingLabelInput';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    flexGrow: 1,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
  },
}));

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const TabsModal = ({
  tabs,
  fields,
  handleChange,
  value,
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  const classes = useStyles();

  const handleChangeTab = (_event, newValue) => {
    setTabValue(newValue);
  };

  const onChangeCallback = (onChange, ...params) => {
    handleChange(...params);
    if (typeof onChange === 'function') onChange(...params);
  };

  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab.label}
              key={tab.key}
              icon={tab.icon}
              aria-label={tab.key}
              id={`scrollable-prevent-tab-${index}`}
              aria-controls={`scrollable-prevent-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </Paper>

      {tabs.map((tab, index) => (
        <TabPanel key={tab.key} value={tabValue} index={index} className={classes.tabContent}>
          <Grid container spacing={3}>
            {fields.filter((field) => field.tab === index || (!field.tab && index === 0)).map((
              {
                elementHeader,
                fieldClasses,
                id,
                field: inputField,
                type,
                inputClasses,
                validations,
                warnings,
                label,
                gridSize,
                format,
                placeholder,
                required,
                autoFocus,
                values,
                renderLabel,
                multiple,
                fileName,
                accept,
                fieldStyle,
                optionSelected,
                elementFooter,
                onChange,
                valueDefault,
                checked,
                freeSolo,
              },
            ) => (
              <React.Fragment key={id}>
                {elementHeader}
                <FloatingLabelInput
                  fieldClasses={fieldClasses}
                  id={id}
                  name={inputField}
                  type={type}
                  inputClasses={inputClasses}
                  validationRules={validations}
                  warningsRules={warnings}
                  label={label}
                  value={_.get(value, id, valueDefault)}
                  onChange={(...params) => onChangeCallback(onChange, ...params)}
                  gridSize={gridSize}
                  format={format}
                  placeholder={placeholder}
                  required={required}
                  autoFocus={autoFocus}
                  values={values}
                  renderLabel={renderLabel}
                  multiple={multiple}
                  fileName={fileName}
                  accept={accept}
                  fieldStyle={fieldStyle}
                  optionSelected={optionSelected}
                  checked={checked}
                  freeSolo={freeSolo}
                />
                {elementFooter}
              </React.Fragment>
            ))}
          </Grid>
        </TabPanel>
      ))}
    </>
  );
};

export const TabFormPropType = {
  label: PropTypes.string,
  icon: PropTypes.node,
  key: PropTypes.key,
};

export default TabsModal;
TabsModal.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape(TabFormPropType)),
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    tab: PropTypes.number,
    label: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  })),
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object]),
};
