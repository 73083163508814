import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, Grid, Typography, makeStyles,
} from '@material-ui/core';
import { snakeCase } from 'lodash';
import { HiddenField } from 'react-rails-form-helpers/lib/fields';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { userProfileAccessPropType } from './UserProfilesAccess';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    autoFocus: true,
    required: true,
  },
];

const emptyState = {
  description: '',
  permissions: {},
};

export const buildUserProfileAccess = (userProfileAccess) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  if (userProfileAccess) {
    const { description, permissions } = userProfileAccess;

    return { description, permissions };
  }

  return emptyState;
};

const EditUserProfileAccessModal = (props) => {
  const {
    routes: { userProfilesAccessPath },
    userProfileAccess,
    modules,
  } = props;

  const useStyles = makeStyles((theme) => ({
    permissionsContainer: {
      margin: theme.spacing(1),
    },
    fullHeight: {
      height: '100%',
    },
  }));

  const classes = useStyles();

  const [state, setState] = useState(() => buildUserProfileAccess(userProfileAccess));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(userProfileAccess));

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleChangePermissions = (id, value) => {
    const permissionKey = id.split('_')[0];
    const permission = state.permissions[permissionKey] || [];
    const valueIndex = permission.indexOf(value);

    if (valueIndex < 0) {
      permission.push(value);
    } else {
      permission.splice(valueIndex, 1);
    }

    state.permissions[permissionKey] = permission;

    setState({ ...state });
  };

  const handleClose = () => setOpen(false);

  const urlForm = edit
    ? `${userProfilesAccessPath}/${userProfileAccess.id}`
    : userProfilesAccessPath;

  const getModuleFieldName = (moduleKey) => `permissions[${snakeCase(moduleKey)}][]`;

  const onFormSubmit = () => { FormService.data = state; };

  return (
    <>
      <FormModal
        title="Perfil de usuário"
        open={open}
        formForProps={{
          method: edit ? 'put' : 'post',
          url: urlForm,
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
        maxWidth="70%"
      >

        {fields.map(({
          id,
          type,
          label,
          field,
          format,
          required,
          autoFocus,
          validations,
          placeholder,
        }) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
          />
        ))}

        <Grid container spacing={2} className={classes.permissionsContainer}>
          {modules.map((module) => (
            <Grid item xs={3} key={module.key}>
              <Card className={classes.fullHeight}>
                <CardContent>
                  <Typography>
                    {module.description}
                  </Typography>
                  {module.actions.map((action) => (
                    <FloatingLabelInput
                      key={action.key}
                      id={`${module.key}_${action.key}`}
                      label={action.description}
                      type="checkboxAccess"
                      onChange={handleChangePermissions}
                      value={action.key}
                      checked={(state.permissions[module.key] || []).includes(action.key)}
                      name={getModuleFieldName(module.key)}
                    />
                  ))}
                  <HiddenField
                    name={getModuleFieldName(module.key)}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </FormModal>
    </>
  );
};

EditUserProfileAccessModal.propTypes = {
  userProfileAccess: userProfileAccessPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        description: PropTypes.string,
      })),
    }),
  ),
};

export default EditUserProfileAccessModal;
