import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { regionPropType } from './Regions';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    visible: true,
    autoFocus: true,
    required: true,
  },
];

const emptyState = {
  description: '',
};

export const buildRegion = (region) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  if (region) {
    return {
      description: region.description,
    };
  }

  return emptyState;
};

const EditRegionModal = (props) => {
  const { routes, region } = props;
  const urlRegion = routes.regionsPath;

  const [state, setState] = useState(() => buildRegion(region));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(region));

  const validInputs = () => UtilsService.validateState(fields, state);
  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      return `${urlRegion}/${region.id}`;
    }
    return urlRegion;
  };

  const onFormSubmit = () => { FormService.data = state; };

  return (
    <>
      <FormModal
        title="Região"
        open={open}
        formForProps={{
          method: edit ? 'put' : 'post',
          url: getUrlForm(),
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >

        {fields.map((
          {
            id, label, type, field, validations, placeholder, format, required, autoFocus,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
          />
        ))}
      </FormModal>
      )
    </>
  );
};

EditRegionModal.propTypes = {
  region: regionPropType,
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditRegionModal;
