import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import { FormFor } from 'react-rails-form-helpers';
import { get } from 'lodash';
import classNames from 'classnames';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import { simpleUserPropType } from '../user/Users';
import { SeedLotPropType } from '../seedLot/SeedLots';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  h1: {
    fontSize: '13.7em',
    marginTop: theme.spacing(4),
    minHeight: theme.spacing(5),
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    letterSpacing: '14px',
    textDecoration: 'none',
  },
  h2: {
    fontSize: '2.25rem',
    marginTop: 0,
    marginBottom: '8px',
  },
  loginContainer: {
    padding: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  td: {
    padding: theme.spacing(1, 0),
  },
  tableContainer: {
    alignItems: 'center',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 0, 0, 1),
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
  },
  selectLotContainer: {
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  logo: {
    maxWidth: 160,
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  searchLogo: {
    marginBottom: theme.spacing(2),
    width: 'auto',
  },
}));

const SearchCard = ({
  routes,
  config,
  user,
  lotNumber: typedLotNumber,
  lots,
}) => {
  const { allowSearch } = config.content.header;
  const { searchPage } = config.content;
  const urlForm = routes.searchLotesPath;

  const { searchInput = {}, invoiceCodeRequired, invoiceCodeEnabled } = searchPage || {};
  const [lotNumber, setLotNumber] = useState(typedLotNumber || '');
  const [invoiceCode, setInvoiceCode] = useState('');
  const classes = useStyles();
  const color = get(config, 'style.toolbar.text.color');
  const name = (user || {}).name || 'Login';

  const submitDisabled = useMemo(() => {
    if (!lotNumber) return true;
    if (invoiceCodeRequired) return !invoiceCode;

    return false;
  }, [lotNumber, invoiceCodeRequired, invoiceCode]);

  return (
    <>
      <Grid container justify="flex-end" className={classes.loginContainer}>
        <Grid item>
          <FormFor
            url={routes.loginIndexPath}
            method="get"
          >
            <Button
              type="submit"
              fullWidth
              variant="text"
              color="primary"
            >
              {name}
            </Button>
          </FormFor>
        </Grid>
      </Grid>
      <div className={classes.paper}>
        {((searchPage || {}).logo || {}).img && (
          <Grid container className={classes.searchLogo}>
            <Grid item xs={12}>
              <a
                href={searchPage.logo.url}
                className={classNames({ 'cursor-default': !searchPage.logo.url })}
              >
                <img
                  src={searchPage.logo.img}
                  alt={searchPage.logo.alt || 'Logo'}
                  className={classes.logo}
                />
              </a>
            </Grid>
          </Grid>
        )}
        {allowSearch ? (
          <>
            <Avatar className={classes.avatar}>
              <SearchIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Consulta de lotes
            </Typography>

            <FormFor
              url={urlForm}
              method="get"
              className={classes.form}
            >
              <div className="field">
                <FloatingLabelInput
                  id="lot_number"
                  label="Número do lote"
                  type="text"
                  validationRules={[
                    (v) => !!v || 'Número do lote é obrigatório',
                  ]}
                  helperMessage={searchInput.helperMessage}
                  value={lotNumber}
                  onChange={(_id, value) => setLotNumber(value)}
                  name="lot_number"
                  placeholder="Número do lote"
                  autoFocus
                  required
                />
              </div>
              {invoiceCodeEnabled && (
                <div className="field">
                  <FloatingLabelInput
                    id="invoiceCode"
                    label="Nota fiscal"
                    type="text"
                    value={invoiceCode}
                    onChange={(_id, value) => setInvoiceCode(value)}
                    name="invoice"
                    placeholder="Nota fiscal"
                    validationRules={
                      invoiceCodeRequired ? [(v) => !!v || 'Nota fiscal é obrigatória'] : undefined
                    }
                    required={invoiceCodeRequired}
                  />
                </div>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ color }}
                className={classes.submit}
                disabled={submitDisabled}
              >
                Buscar
              </Button>
            </FormFor>
            <ListLots
              lots={lots}
              routes={routes}
              classes={classes}
            />
            <Box mt={5}>
              <Copyright />
            </Box>
          </>
        ) : (
          <Grid container style={{ width: 'auto' }}>
            <Grid item xs={12}>
              <Typography component="h1" className={classes.h1} align="center">
                404
              </Typography>
              <Typography component="h2" className={classes.h2} align="center">
                Página não encontrada :(
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

function ListLots({ lots, routes, classes }) {
  const getUrlForm = (lotId) => {
    const path = routes.lotePath;
    return path.replace(':seed_lot_id', lotId);
  };


  return (
    <>
      {lots && lots.length > 0 && (
        <Grid container className={classes.selectLotContainer}>
          <Typography component="h2" variant="h6" className={classes.marginBottom2}>
            Selecione um Lote
          </Typography>
          {lots.map((lot) => (
            <Grid item key={lot.id} xs={12}>
              <Paper>
                <Grid container className={classes.tableContainer}>
                  <Grid item xs={3}>
                    {lot.lotNumber}
                  </Grid>
                  <Grid item xs={3}>
                    {lot.crop.description}
                  </Grid>
                  <Grid item xs={4}>
                    {lot.variety.description}
                  </Grid>
                  <Grid item xs={2}>
                    <FormFor
                      url={getUrlForm(lot.id)}
                      method="get"
                    >
                      <IconButton
                        color="primary"
                        aria-label="selecionar"
                        type="submit"
                        style={{ float: 'right' }}
                      >
                        <SendIcon />
                      </IconButton>
                    </FormFor>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

ListLots.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
  lots: PropTypes.arrayOf(SeedLotPropType),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://agro1.inf.br/">
        Agro1
      </Link>{' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

SearchCard.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  user: simpleUserPropType,
  lotNumber: PropTypes.string,
  lots: PropTypes.arrayOf(SeedLotPropType),
};

export default SearchCard;
