/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import GrainIcon from '@material-ui/icons/Grain';

import { makeStyles } from '@material-ui/core/styles';
import CardAvatar from '../../components/Card/CardAvatar';
import CardTitle from '../../components/Card/CardTitle';
import { SeedTreatmentPropType, SeedTreatmentItemPropType } from '../seedTreatment/SeedTreatments';
import { CardPropType } from './InfoCard';
import DynamicComponent from '../DynamicComponent';

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'center',
  },
  cardHeader: {
    flex: 1,
  },
  cardHeaderBox: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  tableHead: {
    textAlign: 'center',
  },
}));

const SeedTreatmentItem = ({ supplyCards, config, item }) => (
  <>
    {supplyCards.map(({
      uuid, props: componentProps, component, subComponents,
    }) => (
      <DynamicComponent
        key={uuid}
        config={config}
        data={item}
        block={{
          uuid,
          component,
          subComponents,
          props: {
            ...componentProps,
            title: item.supply.description,
          },
        }}
      />
    ))}
  </>
);

const SeedTreatmentCard = (props) => {
  const {
    title,
    seedTreatment,
    config,
    cards,
    supplyCards,
  } = props;

  const classes = useStyles();

  return (
    <>
      {seedTreatment && (
        <Grid item xs={12}>
          <Card>
            <div className={classes.cardHeaderBox}>
              <CardHeader
                className={classes.cardHeader}
                avatar={(
                  <CardAvatar>
                    <GrainIcon />
                  </CardAvatar>
              )}
                title={(
                  <CardTitle
                    title={title || 'Tratamento de Sementes'}
                  />
              )}
              />
            </div>
            <CardContent>
              <Grid container spacing={3} className={classes.container}>
                {cards.map((component) => (
                  <DynamicComponent
                    key={component.uuid}
                    config={config}
                    data={seedTreatment}
                    block={component}
                  />
                ))}
                {seedTreatment.items.map((item) => (
                  <SeedTreatmentItem
                    key={item.supply.id}
                    item={item}
                    supplyCards={supplyCards}
                    config={config}
                  />
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

SeedTreatmentCard.propTypes = {
  title: PropTypes.string,
  seedTreatment: SeedTreatmentPropType,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  cards: CardPropType,
  supplyCards: CardPropType,
};

SeedTreatmentItem.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  supplyCards: CardPropType,
  item: SeedTreatmentItemPropType,
};

export default SeedTreatmentCard;
