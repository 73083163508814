import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FormFor } from 'react-rails-form-helpers';
import DialogConfirmation from './DialogConfirmation';

const DeleteListItem = ({
  itemId, path, message, currentPage,
}) => {
  const [open, setOpen] = React.useState(false);

  const urlDelete = path.replace(':id', itemId);
  const deleteNameForm = `delete-${itemId}`;

  const handleOnDelete = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FormFor
        url={urlDelete}
        method="delete"
        id={deleteNameForm}
        name={deleteNameForm}
      >
        <input
          name="page"
          value={currentPage}
          type="hidden"
        />
        <ListItem
          button
          className="deleteButton"
          onClick={handleOnDelete}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Apagar" />
        </ListItem>
      </FormFor>
      <DialogConfirmation
        title="Confirma exclusão?"
        message={message || 'Tem certeza de que deseja excluir este item?'}
        open={open}
        actionsButtons={{
          confirm: {
            form: deleteNameForm,
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      />
    </>
  );
};

DeleteListItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  message: PropTypes.string,
  currentPage: PropTypes.number,
};

export default DeleteListItem;
