import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { isFunction, isArray, isNil } from 'lodash';
import UtilsService from '../../services/UtilsService';

const useStyles = makeStyles({
  cell: {
    width: '60%',
  },
  cellValue: {
    width: '40%',
  },
});

/**
 * Renderiza uma linha da tabela de visualização de models.
 */
const ViewModalRow = ({
  id, label, value, type, renderLabel, renderViewLabel, format, viewLabel,
}) => {
  const renderFunction = (render) => {
    try {
      if (isArray(value)) {
        return value.map((v) => render(v)).join(', ');
      }

      return render(value);
    } catch (e) {
      console.error(`Erro ao renderizar attributo ${id}, com o valor ${value}`, e);
    }

    return '';
  };

  const render = {
    number: () => {
      if (isNil(value) || value === '') {
        return '';
      }

      return UtilsService.formatNumber(value);
    },
    autocomplete: () => '',
    datePicker: () => {
      if (!value) {
        return '';
      }
      return moment(value).format(format || 'DD/MM/YYYY');
    },
    checkbox: () => (value ? 'Sim' : 'Não'),
    text: () => value,
    default: () => value,
  };

  const formatValue = () => {
    if (isFunction(renderViewLabel)) {
      return renderFunction(renderViewLabel, value);
    }

    if (isFunction(renderLabel)) {
      return renderFunction(renderLabel, value);
    }

    return (render[type] || render.default)();
  };

  const classes = useStyles();

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row" className={classes.cellLabel}>
        {viewLabel || label}
      </TableCell>
      <TableCell className={classes.cellValue}>{formatValue(value)}</TableCell>
    </TableRow>
  );
};

ViewModalRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  viewLabel: PropTypes.string,
  type: PropTypes.string, // TODO - adicionar testes conforme tipo
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  renderLabel: PropTypes.func,
  renderViewLabel: PropTypes.func,
  format: PropTypes.string,
};

export default ViewModalRow;
