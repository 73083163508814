/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import convert from 'htmr';

/**
 * Renderiza html como componentes react
 */
const HTMLComponent = (props) => {
  const {
    html,
  } = props;

  return (
    <>
      {convert(html)}
    </>
  );
};

export default HTMLComponent;

HTMLComponent.propTypes = {
  html: PropTypes.string.isRequired,
};
