import React from 'react';

const IconEtiqueta = () => {
  const styles = {
    fillcolor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="etiqueta" style={styles.fillcolor} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 79.5 78.36">
      <path d="M60.89,35.61,35.72,10.44a5.71,5.71,0,0,0-4-1.67H7.43a5.7,5.7,0,0,0-5.7,5.7V38.72a5.7,5.7,0,0,0,1.67,4L28.57,67.93a5.7,5.7,0,0,0,8.07,0L60.89,43.68a5.7,5.7,0,0,0,0-8.07ZM15,27.78a5.71,5.71,0,1,1,5.7-5.71A5.71,5.71,0,0,1,15,27.78ZM76.1,43.68,51.85,67.93a5.7,5.7,0,0,1-8.07,0l0,0L64.42,47.2a10.69,10.69,0,0,0,0-15.12L41.1,8.77h5.79a5.71,5.71,0,0,1,4,1.67L76.1,35.61a5.7,5.7,0,0,1,0,8.07Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconEtiqueta;
