import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import FormModal from '../../components/FormModal';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { harvestPropType } from './Harvests';
import { fieldPropType } from '../field/Fields';
import { measureUnitType } from '../../../services/GeneralTypes';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    visible: true,
    autoFocus: true,
    required: true,
  },
  {
    id: 'harvestDate',
    label: 'Data da Colheita',
    type: 'datePicker',
    field: 'harvest_date',
    placeholder: '01/01/2020',
    format: 'DD/MM/YYYY',
    validations: [
      (v) => !!v || 'Data da Colheita é obrigatória',
    ],
    required: true,
  },
  {
    id: 'amount',
    label: 'Quantidade',
    type: 'number',
    field: 'amount',
    validations: [
      (v) => !!v || 'Quantidade é obrigatória',
    ],
    required: true,
  },
  {
    id: 'measureUnit',
    label: 'Selecione uma Unidade de Medida',
    viewLabel: 'Unidade de Medida',
    type: 'autocomplete',
    field: 'measure_unit_id',
    validations: [
      (v) => !!v || 'Unidade de Medida é obrigatória',
    ],
    visible: true,
    renderLabel: (unit) => unit.description,
    tab: 1,
    gridSize: 6,
    required: true,
  },
];

const emptyState = {
  description: '',
  harvestDate: new Moment(),
  amount: '',
  measureUnit: null,
};

export const buildHarvest = (harvest) => {
  if (harvest) {
    return {
      description: harvest.description,
      harvestDate: new Moment(harvest.harvestDate).utcOffset(harvest.harvestDate),
      amount: harvest.amount,
      measureUnit: harvest.measureUnit,
    };
  }

  return emptyState;
};

const EditHarvestModal = (props) => {
  const {
    routes,
    harvest,
    field,
    measureUnits,
  } = props;

  const [state, setState] = useState(emptyState);
  const [open, setOpen] = useState(false);
  const [edit] = useState(Boolean(harvest));

  const handleChange = (id, value) => {
    const updateState = {
      ...state,
      [id]: value,
    };

    if (id === 'specie') {
      updateState.variety = null;
    }

    setState(updateState);
  };

  useEffect(() => {
    setState(buildHarvest(harvest));
    setOpen(true);
  }, []);

  const values = {
    measureUnit: measureUnits,
  };

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      const path = routes.harvestPath;
      return path.replace(':id', harvest.id);
    }

    return routes.fieldHarvestsPath.replace(':id', field.id);
  };

  return (
    <>
      <FormModal
        open={open}
        title="Colheita"
        formForProps={{
          url: getUrlForm(),
          method: edit ? 'put' : 'post',
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {fields.map((
          {
            id,
            label,
            type,
            field: inputField,
            validations,
            placeholder,
            format,
            required,
            autoFocus,
            renderLabel,
          },
        ) => (
          <FloatingLabelInput
            key={inputField}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={inputField}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            values={values[id]}
            renderLabel={renderLabel}
          />
        ))}
      </FormModal>
    </>
  );
};


EditHarvestModal.propTypes = {
  harvest: harvestPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  field: fieldPropType,
  measureUnits: PropTypes.arrayOf(PropTypes.shape(measureUnitType)),
};

export default EditHarvestModal;
