import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { VariableSizeList } from 'react-window';
import _ from 'lodash';

const LISTBOX_PADDING = 8;

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];

const CustomAutocomplete = (props) => {
  const {
    values,
    gridSize,
    renderLabel,
    label,
    name,
    onChange,
    selected,
    disabled,
    id,
    required,
    multiple,
    optionSelected,
    disableClearable,
    freeSolo,
  } = props;

  const [elementId, setElementId] = useState('');
  const [selectedElement, setSelectedElement] = useState(selected);
  const [selectedValue, setSelectedValue] = useState('');

  const getOptionSelected = (newValue, currentValue) => {
    if (typeof optionSelected === 'function') return optionSelected(newValue, currentValue);

    if (_.has(newValue, 'id') && _.has(currentValue, 'id')) return newValue.id === currentValue.id;

    return newValue === currentValue;
  };

  useEffect(() => {
    const selectedId = (selectedElement || {}).id || '';
    setElementId(selectedId);
    setSelectedValue(selectedElement || '');

    if (onChange) {
      onChange(selectedElement);
    }
  }, [selectedElement]);

  useEffect(() => {
    setSelectedElement(selected);
  }, [selected]);

  const useStyles = makeStyles({
    listbox: {
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  });

  const classes = useStyles();
  const displayLabel = label || 'Selecione';

  const renderLabelValue = (element) => (_.isEmpty(element) ? '' : renderLabel(element));

  return (
    <Grid item xs={gridSize || 12}>
      <Autocomplete
        id={id}
        options={values}
        classes={classes}
        disableListWrap
        disabled={disabled}
        getOptionLabel={renderLabelValue}
        ListboxComponent={ListboxComponent}
        renderGroup={renderGroup}
        filterSelectedOptions
        renderOption={renderLabelValue}
        value={selectedValue}
        noOptionsText="Nenhuma opção"
        loadingText="Carregando..."
        clearText="Limpar"
        openText="Abrir"
        multiple={multiple}
        getOptionSelected={getOptionSelected}
        onChange={(_event, value) => {
          setSelectedElement(value);
        }}
        disableClearable={disableClearable}
        freeSolo={freeSolo}
        renderInput={(params) => (
          <>
            <TextField
              name={name}
              value={elementId}
              type="hidden"
            />
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label={displayLabel}
              variant="outlined"
              fullWidth
              required={!!required}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
            />
          </>
        )}
      />
    </Grid>
  );
};

const optionValue = PropTypes.shape({
  id: PropTypes.string,
});

CustomAutocomplete.propTypes = {
  values: PropTypes.arrayOf(optionValue).isRequired,
  gridSize: PropTypes.number,
  renderLabel: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  optionSelected: PropTypes.func,
  disableClearable: PropTypes.bool,
  freeSolo: PropTypes.bool,
};

export default CustomAutocomplete;
