import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';

import FloatingLabelInput from '../../components/FloatingLabelInput';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    margin: theme.spacing(4, 1, 0, 1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  marginBottom1Spacing: {
    marginBottom: theme.spacing(1),
  },
}));

const PlantabilityData = (props) => {
  const {
    pms,
    germination,
    updatePms,
    updateGermination,
  } = props;

  const classes = useStyles();


  return (
    <>
      <Grid container className={classes.gridContainer} spacing={3}>
        <Grid item xs={12}>
          <FormLabel
            component="legend"
            className={classes.marginBottom1Spacing}
          >
            Informe a Germinação e o PMS:
          </FormLabel>
        </Grid>
        {[
          {
            id: 'germination',
            label: 'Germinação',
            value: germination,
            onChange: updateGermination,
          },
          {
            id: 'pms',
            label: 'PMS',
            value: pms,
            onChange: updatePms,
          },
        ].map(({
          id,
          label,
          value,
          onChange,
        }) => (
          <Grid item xs={12} md={3} lg={3} key={id}>
            <FloatingLabelInput
              key={id}
              id={id}
              label={label}
              type="number"
              value={value || ''}
              onChange={(_id, newValue) => onChange(Number.parseFloat(newValue))}
              name={id}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

PlantabilityData.propTypes = {
  pms: PropTypes.number,
  germination: PropTypes.number,
  updatePms: PropTypes.func.isRequired,
  updateGermination: PropTypes.func.isRequired,
};

export default PlantabilityData;
