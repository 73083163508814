import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import DropdownActionButtons from '../../components/DropdownActionButtons';
import { actionsPermittedPropType } from '../../../services/GeneralTypes';

const Sieves = (props) => {
  const {
    sieves,
    query,
    routes,
    actionsPermitted,
  } = props;

  const [allSieves, setSieve] = useState([]);

  const urlNewSieve = routes.newSievePath;
  const urlSieve = routes.sievesPath;

  useEffect(() => {
    setSieve(sieves.map((sieve) => {
      const updatedSieve = { ...sieve, id: sieve.id };
      return updatedSieve;
    }));
  }, [sieves]);

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.sievePath}
          editUrl={routes.editSievePath}
          deleteUrl={routes.sievePath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a peneira <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="sieve">
          <Typography component="h1" variant="h2" align="center">
            Peneiras
          </Typography>
          <SearchBar
            url={urlSieve}
            query={query}
          />
          <NewButton
            url={urlNewSieve}
            description="Nova Peneira"
          />
          <ListTable
            columns={columns}
            data={allSieves}
          />
        </div>
      </Container>
    </>
  );
};

export const sievePropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
});

Sieves.propTypes = {
  sieves: PropTypes.arrayOf(sievePropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionsPermitted: actionsPermittedPropType,
};

export default Sieves;
