
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { labelPropType } from './Labels';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

const layouts = [
  {
    id: 'portrait',
    description: 'Retrato',
  },
  {
    id: 'landscape',
    description: 'Paisagem',
  },
];


const EditLabelModal = (props) => {
  const { routes, label } = props;
  const urlLabel = routes.labelsPath;

  const getInitialState = () => {
    if (FormService.hasData) {
      return FormService.data;
    }

    if (label) {
      const layout = layouts.find(({ id }) => id === label.layout);

      return {
        description: label.description,
        height: label.height,
        width: label.width,
        angle: label.angle,
        layout,
        marginTop: label.marginTop || '',
        marginRight: label.marginRight || '',
        marginBottom: label.marginBottom || '',
        marginLeft: label.marginLeft || '',
        strokeBounds: !!label.strokeBounds,
      };
    }

    return {
      description: '',
      height: '',
      width: '',
      angle: '',
      layout: layouts[0],
      marginTop: '',
      marginRight: '',
      marginBottom: '',
      marginLeft: '',
      strokeBounds: false,
    };
  };

  const [state, setState] = useState(() => getInitialState());
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(label));

  const fields = [
    {
      id: 'description',
      label: 'Descrição',
      type: 'text',
      field: 'description',
      validations: [
        (v) => !!v || 'Descrição é obrigatória',
      ],
      visible: true,
      autoFocus: true,
      required: true,
    },
    {
      id: 'height',
      label: 'Altura',
      type: 'number',
      field: 'height',
      validations: [
        (v) => !!v || 'Altura é obrigatória',
      ],
      visible: true,
      required: true,
    },
    {
      id: 'width',
      label: 'Largura',
      type: 'number',
      field: 'width',
      validations: [
        (v) => !!v || 'Largura é obrigatória',
      ],
      visible: true,
      required: true,
    },
    {
      id: 'angle',
      label: 'Ângulo de Rotação',
      type: 'number',
      field: 'angle',
      validations: [
        (v) => v !== '' || 'Ângulo é obrigatório',
      ],
      visible: true,
      required: true,
    },
    {
      id: 'layout',
      label: 'Selecione uma Orientação',
      viewLabel: 'Orientação da Página',
      type: 'autocomplete',
      field: 'layout',
      validations: [
        (v) => !!v || 'Orientação é obrigatória',
      ],
      required: true,
      values: layouts,
      renderLabel: (layout) => layout.description,
    },
    {
      id: 'marginTop',
      label: 'Margem Superior',
      type: 'number',
      field: 'margin_top',
      validations: [
        (v) => !v || v >= 0 || 'Margem Superior não pode ser menor do que 0',
      ],
      visible: true,
    },
    {
      id: 'marginRight',
      label: 'Margem Direita',
      type: 'number',
      field: 'margin_right',
      validations: [
        (v) => !v || v >= 0 || 'Margem Direita não pode ser menor do que 0',
      ],
      visible: true,
    },
    {
      id: 'marginBottom',
      label: 'Margem Inferior',
      type: 'number',
      field: 'margin_bottom',
      validations: [
        (v) => !v || v >= 0 || 'Margem Inferior não pode ser menor do que 0',
      ],
      visible: true,
    },
    {
      id: 'marginLeft',
      label: 'Margem Esquerda',
      type: 'number',
      field: 'margin_left',
      validations: [
        (v) => !v || v >= 0 || 'Margem Esquerda não pode ser menor do que 0',
      ],
      visible: true,
    },
    {
      id: 'strokeBounds',
      label: 'Ativar borda nos campos',
      type: 'checkbox',
      field: 'stroke_bounds',
      validations: [
      ],
      visible: true,
      checked: state.strokeBounds,
    },
  ];

  const validInputs = () => UtilsService.validateState(fields, state);
  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      return `${urlLabel}/${label.id}`;
    }
    return urlLabel;
  };

  const onFormSubmit = () => { FormService.data = state; };

  return (
    <>
      <FormModal
        title="Etiqueta"
        open={open}
        formForProps={{
          method: edit ? 'put' : 'post',
          url: getUrlForm(),
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >

        {fields.map((
          {
            id,
            label: labelText,
            type,
            field,
            validations,
            placeholder,
            format,
            required,
            autoFocus,
            values,
            renderLabel,
            checked,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={labelText}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            values={values}
            renderLabel={renderLabel}
            checked={checked}
          />
        ))}
      </FormModal>
      )
    </>
  );
};

EditLabelModal.propTypes = {
  label: labelPropType,
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditLabelModal;
