import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { unitConversionPropType } from './UnitConversions';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';
import { measureUnitType } from '../../../services/GeneralTypes';
import { speciePropType } from '../species/Species';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    required: true,
    autoFocus: true,
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
  },
  {
    id: 'measureUnit',
    label: 'Unidade',
    type: 'autocomplete',
    field: 'measure_unit_id',
    validations: [
      (v) => !!v || 'Unidade é obrigatória',
    ],
    renderLabel: ({ description }) => description,
    required: true,
  },
  {
    id: 'species',
    label: 'Atividade',
    type: 'autocomplete',
    field: 'species_id',
    validations: [],
    renderLabel: ({ description }) => description,
  },
  {
    id: 'type',
    label: 'Selecione um Tipo de Conversão',
    viewLabel: 'Tipo de Conversão',
    type: 'autocomplete',
    field: 'type',
    validations: [
      (v) => !!v || 'Tipo é obrigatório',
    ],
    required: true,
    renderLabel: (type) => type.description,
  },
  {
    id: 'conversion',
    label: 'Conversão (ex: 40 -> sc de 40kg)',
    type: 'number',
    field: 'conversion',
    required: true,
    validations: [
      (v) => !!v || 'Conversão é obrigatória',
    ],
  },
];

const types = [
  {
    id: 'kg',
    description: 'Kg',
  },
  {
    id: 'seed',
    description: 'Sementes',
  },
];

const getUnitConversionValue = (unitConversion, path, defaultValue) => (
  UtilsService.getObjectValue(unitConversion, path, defaultValue)
);

export const buildUnitConversion = (unitConversion) => {
  if (FormService.hasData) {
    return FormService.data;
  }

  const type = types.find(({ id }) => id === (unitConversion || {}).type) || types[0];

  return {
    type,
    description: getUnitConversionValue(unitConversion, 'description'),
    measureUnit: getUnitConversionValue(unitConversion, 'measureUnit', {}),
    species: getUnitConversionValue(unitConversion, 'species', {}),
    conversion: getUnitConversionValue(unitConversion, 'conversion'),
  };
};

const EditUnitConversionModal = (props) => {
  const {
    routes,
    unitConversion,
    measureUnits,
    species,
  } = props;

  const urlUnitConversions = routes.unitConversionsPath;

  const [state, setState] = useState(() => buildUnitConversion(unitConversion));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(unitConversion));

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      const path = routes.unitConversionPath;
      return path.replace(':id', unitConversion.id);
    }

    return urlUnitConversions;
  };

  const additionalInfoFields = {
    measureUnit: {
      values: measureUnits,
    },
    species: {
      values: species,
    },
    type: {
      values: types,
    },
  };

  const generalFields = UtilsService.updateFields(fields, additionalInfoFields);

  const validInputs = () => UtilsService.validateState(generalFields, state);

  const onFormSubmit = () => {
    FormService.data = state;
  };

  return (
    <>
      <FormModal
        open={open}
        title="Unidade de Conversão"
        formForProps={{
          url: getUrlForm(),
          method: edit ? 'put' : 'post',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {generalFields.map((
          {
            id,
            label,
            type,
            field,
            format,
            required,
            autoFocus,
            validations,
            values,
            renderLabel,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            format={format}
            required={required}
            autoFocus={autoFocus}
            values={values}
            renderLabel={renderLabel}
          />
        ))}
      </FormModal>
    </>
  );
};

EditUnitConversionModal.propTypes = {
  unitConversion: unitConversionPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  measureUnits: PropTypes.arrayOf(PropTypes.shape(measureUnitType)),
  species: PropTypes.arrayOf(speciePropType),
};

export default EditUnitConversionModal;
