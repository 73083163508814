import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Typography, Grid, Paper, ButtonGroup, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormFor } from 'react-rails-form-helpers/lib/forms';
import FormService from '../../services/FormService';

/**
 * Exibe uma modal com formulário embutido com botões de confirmação e cancelamento
 *
 * As childrens devem estar com englobadas em tags <Grid item> </Grid>
 *
 * Define quando a modal está aberta/fechada
 * @open
 *
 * Título da modal
 * @title
 *
 * Parâmetros utilizados pelos botões de cancelamento e confirmação
 * @actionsButtons
 *
 * Props passadas para o formulário
 * @formForProps
 */

const FormModal = ({
  children, title, actionsButtons, formForProps, open, maxWidth, maxHeight,
}) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      maxHeight: maxHeight || '70%',
      backgroundColor: theme.palette.background.paper,
      maxWidth: maxWidth || '600px',
      padding: theme.spacing(2, 4, 3),
      outline: 'none',
      boxShadow: theme.shadows[5],
      overflowY: 'auto',
    },
    buttonGroup: {
      float: 'right',
    },
  }));

  const onCancel = () => {
    FormService.clearFormStore();

    if (actionsButtons.cancel.onClick) actionsButtons.cancel.onClick();
  };

  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="form-model-title"
      className={classes.modal}
      open={open}
    >
      <Paper className={classes.paper}>

        <FormFor
          url={formForProps.url}
          method={formForProps.method}
          encType={formForProps.encType}
          onSubmit={formForProps.onSubmit}
          id={formForProps.id}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="center"
                id="form-model-title"
                className={classes.title}
              >
                {title}
              </Typography>
            </Grid>

            {children}

            <Grid item xs={12}>
              <ButtonGroup className={classes.buttonGroup}>
                <Button
                  variant="text"
                  color="primary"
                  id="form-model-cancel-btn"
                  type={actionsButtons.confirm.type}
                  disabled={actionsButtons.cancel.disabled}
                  onClick={onCancel}
                  form={actionsButtons.cancel.form}
                >
                  {actionsButtons.cancel.label || 'Cancelar'}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type={actionsButtons.confirm.type || 'submit'}
                  id="form-model-confirm-btn"
                  disabled={actionsButtons.confirm.disabled}
                  onClick={actionsButtons.confirm.onClick}
                  form={actionsButtons.confirm.form}
                >
                  {actionsButtons.confirm.label || 'Confirmar'}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </FormFor>
      </Paper>
    </Modal>
  );
};

export const FormForPropType = {
  url: PropTypes.string,
  method: PropTypes.oneOf(['get', 'post', 'put', 'patch', 'delete']),
  encType: PropTypes.oneOf([
    'application/x-www-form-urlencoded',
    'multipart/form-data',
    'text/plain',
  ]),
  onSubmit: PropTypes.func,
  id: PropTypes.string,
};

export const ButtonPropType = {
  disabled: PropTypes.bool,
  onClick: PropTypes.void,
  type: PropTypes.string,
  form: PropTypes.string,
};

FormModal.defaultProps = {
  actionsButtons: {
    confirm: {},
    cancel: {},
  },

  formForProps: {},
};

const actionsButtonsPropTypes = {
  confirm: PropTypes.shape({ label: PropTypes.string, ...ButtonPropType }),
  cancel: PropTypes.shape({ label: PropTypes.string, ...ButtonPropType }),
};

export default FormModal;
FormModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  actionsButtons: PropTypes.shape(actionsButtonsPropTypes),
  formForProps: PropTypes.shape(FormForPropType),
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
};
