import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import TextField from '@material-ui/core/TextField';

import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { recommendationPeriodPropType } from './RecommendationPeriods';
import { speciePropType } from '../species/Species';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    validations: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    visible: true,
    autoFocus: true,
    required: true,
  },
  {
    id: 'startDate',
    label: 'Data Início',
    type: 'datePicker',
    field: 'start_date_picker',
    placeholder: '01/01',
    format: 'DD/MM',
    validations: [
      (v) => !!v || 'Data de Início é obrigatória',
      (v) => ('isValid' in v && v.isValid()) || 'Data inválida',
    ],
    visible: true,
    autoFocus: false,
    required: true,
  },
  {
    id: 'endDate',
    label: 'Data Fim',
    type: 'datePicker',
    field: 'end_date_picker',
    placeholder: '01/01',
    format: 'DD/MM',
    validations: [
      (v) => !!v || 'Data de Fim é obrigatória',
      (v) => ('isValid' in v && v.isValid()) || 'Data inválida',
    ],
    visible: true,
    autoFocus: false,
    required: true,
  },
  {
    id: 'specie',
    label: 'Atividade',
    type: 'autocomplete',
    field: 'specie_id',
    validations: [
    ],
    renderLabel: ({ description }) => description,
  },
];

const emptyState = {
  description: '',
  startDate: new Moment(),
  endDate: new Moment(),
  specie: {},
};

export const buildRecommendationPeriod = (recommendationPeriod) => {
  if (FormService.hasData) {
    const { startDate, endDate, ...formData } = FormService.data;

    return {
      ...formData,
      startDate: new Moment(startDate),
      endDate: new Moment(endDate),
    };
  }

  if (recommendationPeriod) {
    const {
      startDate,
      endDate,
      description,
      specie,
    } = recommendationPeriod;

    return {
      description,
      specie,
      startDate: startDate ? new Moment(startDate).utcOffset(startDate) : new Moment(),
      endDate: endDate ? new Moment(endDate).utcOffset(endDate) : new Moment(),
    };
  }

  return emptyState;
};

const EditRecommendationPeriodModal = (props) => {
  const { routes, recommendationPeriod, species } = props;
  const urlRecommendationPeriod = routes.recommendationPeriodsPath;

  const [state, setState] = useState(() => buildRecommendationPeriod(recommendationPeriod));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(recommendationPeriod));

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleDateChange = (id, value) => {
    if (value) {
      if (id === 'startDate') {
        value.year(2020);
      } else {
        value.year(2021);
      }
    }
    handleChange(id, value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      return `${urlRecommendationPeriod}/${recommendationPeriod.id}`;
    }
    return urlRecommendationPeriod;
  };

  const additionalInputsInfo = {
    startDate: {
      onChange: handleDateChange,
    },
    endDate: {
      onChange: handleDateChange,
    },
    specie: {
      values: species,
    },
  };

  const generalFields = UtilsService.updateFields(fields, additionalInputsInfo);

  const validInputs = () => UtilsService.validateState(generalFields, state);

  const onFormSubmit = () => { FormService.data = state; };

  return (
    <>
      <FormModal
        title="Período de Recomendação"
        open={open}
        formForProps={{
          method: edit ? 'put' : 'post',
          url: getUrlForm(),
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >

        {generalFields.map((
          {
            id,
            label,
            type,
            field,
            validations,
            placeholder,
            format,
            required,
            autoFocus,
            onChange,
            values,
            renderLabel,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={onChange || handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            values={values}
            renderLabel={renderLabel}
          />
        ))}
        <TextField
          name="start_date"
          value={state.startDate ? state.startDate.format('DD/MM/YYYY') : ''}
          type="hidden"
        />
        <TextField
          name="end_date"
          value={state.endDate ? state.endDate.format('DD/MM/YYYY') : ''}
          type="hidden"
        />
      </FormModal>
      )
    </>
  );
};

EditRecommendationPeriodModal.propTypes = {
  recommendationPeriod: recommendationPeriodPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  species: PropTypes.arrayOf(speciePropType),
};

export default EditRecommendationPeriodModal;
