import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useImagesListStyles = makeStyles({
  container: {
    position: 'relative',
    padding: '10px 0',
  },
  image: {
    maxHeight: '90vh',
    display: 'flex',
    margin: '0 auto',
  },
  titleContainer: {
    position: 'absolute',
    bottom: 25,
    textAlign: 'center',
    right: 0,
    left: 0,
  },
  title: {
    backgroundColor: 'rgba(0,0,0, 0.25)',
    padding: '3px 5px',
    borderRadius: 3,
    display: 'inline-block',
    color: '#fff',
  },
});


const ImagesList = ({ images }) => {
  const classes = useImagesListStyles();

  return (
    <div>
      {images.map((image) => (
        <div key={image.id} className={classes.container}>
          <img
            src={image.fileUrl}
            alt={image.description}
            className={classes.image}
          />
          {image.description && (
            <div className={classes.titleContainer}>
              <h4 className={classes.title}>
                {image.description}
              </h4>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};


ImagesList.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};

export default ImagesList;
