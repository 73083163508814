import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { actionsPermittedPropType, measureUnitType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';
import { speciePropType } from '../species/Species';

const UnitConversions = (props) => {
  const {
    unitConversions,
    query,
    routes,
    actionsPermitted,
  } = props;

  const urlNewUnitConversion = routes.newUnitConversionPath;
  const urlUnitConversions = routes.unitConversionsPath;
  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Tipo',
      cell: (row) => (
        row.type === 'seed' ? 'Sementes' : 'Kg'
      ),
    },
    {
      header: 'Conversão',
      cell: (row) => row.conversion,
    },
    {
      header: 'Atividade',
      cell: (row) => (row.species || {}).description || 'N/D',
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.unitConversionPath}
          editUrl={routes.editUnitConversionPath}
          deleteUrl={routes.unitConversionPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a conversão de unidade <b>
            ${row.measureUnit.description}</b>?<br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div className="unit-conversions">
          <Typography component="h1" variant="h2" align="center">
            Conversão de Unidades
          </Typography>
          <SearchBar
            url={urlUnitConversions}
            query={query}
          />
          <NewButton
            url={urlNewUnitConversion}
            description="Nova Conversão"
          />
          <ListTable
            columns={columns}
            data={unitConversions}
          />
        </div>
      </Container>
    </>
  );
};

export const SEED_SPEND_TYPE = 'seed';
export const WEIGHT_SPEND_TYPE = 'kg';
export const TO_KILOGRAMS = 1000;
export const TO_SEED = 1000;

export const unitConversionPropType = PropTypes.shape({
  conversion: PropTypes.number,
  type: PropTypes.string,
  species: speciePropType,
  measureUnit: PropTypes.shape(measureUnitType),
});

UnitConversions.propTypes = {
  unitConversions: PropTypes.arrayOf(unitConversionPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default UnitConversions;
