import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { InputAdornment, Typography } from '@material-ui/core';
import FloatingLabelInput from '../../components/FloatingLabelInput';

const hectareParaMetrosQuadrados = (value) => value * 10000;

const UNIDADES = [
  { label: 'Plantas/ha', id: 'ha' },
  { label: 'Plantas/m²', id: 'm2' },
];

const UNIDADE_PADRAO = UNIDADES[0];

const Adornment = ({ children }) => (
  <InputAdornment
    position="end"
    disableTypography
    style={{ marginTop: '8px', alignItems: 'end' }}
  >
    <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
      {children}
    </Typography>
  </InputAdornment>
);

Adornment.propTypes = {
  children: PropTypes.node,
};

const PlantsInput = ({
  isTypeSeedingRate,
  onChange,
}) => {
  const [unidade, setUnidade] = useState(UNIDADE_PADRAO);
  const [plants, setPlants] = useState(0);

  const onPlantsChange = (_id, value) => {
    setPlants(value);
  };

  const inputLabel = useMemo(
    () => (isTypeSeedingRate ? 'Densidade desejada' : 'População desejada'),
    [isTypeSeedingRate],
  );

  useEffect(() => {
    if (!unidade) {
      onChange(0);
      return;
    }

    const pantasConvertidas = unidade.id === 'm2'
      ? hectareParaMetrosQuadrados(plants)
      : plants;

    onChange(pantasConvertidas);
  }, [unidade, plants]);

  const adornment = useMemo(() => {
    if (isTypeSeedingRate) {
      return 'plantas/m linear';
    }

    if (unidade) return unidade.label;

    return '';
  }, [isTypeSeedingRate, unidade]);

  useEffect(() => {
    if (isTypeSeedingRate) setUnidade(UNIDADE_PADRAO);
  }, [isTypeSeedingRate]);

  return (
    <>
      <Grid item xs={12} md={4} lg={3}>
        <FloatingLabelInput
          id="plants"
          name="plants"
          label={inputLabel}
          type="number"
          value={plants}
          onChange={onPlantsChange}
          InputProps={{
            endAdornment: <Adornment>{adornment}</Adornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={3} hidden={isTypeSeedingRate}>
        <FloatingLabelInput
          id="unidade"
          name="unidade"
          label="Unidade"
          type="autocomplete"
          value={unidade}
          values={UNIDADES}
          renderLabel={({ label }) => label}
          onChange={(_id, value) => setUnidade(value)}
        />
      </Grid>
    </>
  );
};

PlantsInput.propTypes = {
  isTypeSeedingRate: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PlantsInput;
