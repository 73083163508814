import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TableLoading from './TableLoading';
import TraceabilityPagination from './TraceabilityPagination';

/**
 * Monta uma tabela com loader e exibição padrão de quando não há itens.
 *
 * @visibleName List Table
 */
const ListTable = ({
  loading,
  columns,
  data,
  dataTooltip,
  urlPagination,
  searchQuery,
  currentPage,
  totalPages,
}) => {
  const emptyClassNames = classNames({
    'is-hidden': data.length > 0,
  });
  const tableClassNames = classNames('table is-fullwidth', {
    'is-hoverable': data.length > 0,
  });
  return (
    <div className="list-table">
      <TraceabilityPagination
        url={urlPagination}
        searchQuery={searchQuery}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <table className={tableClassNames}>
        <thead>
          <tr>
            {columns.map((col) => {
              const colClassNames = classNames({
                'has-text-right': col.alignRight,
              });

              return (
                <th key={col.header} className={colClassNames}>
                  {col.header !== 'action' && col.header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id} title={dataTooltip && row[dataTooltip]}>
              {columns.map((col) => {
                const colClassNames = classNames({
                  'has-text-right': col.alignRight,
                  'is-small': col.header === 'action',
                });

                return (
                  <td key={col.header} className={colClassNames}>
                    {col.cell(row)}
                  </td>
                );
              })}
            </tr>
          ))}
          <tr className={emptyClassNames}>
            <td colSpan={columns.length} className="has-text-centered">
              Nenhum registro encontrado.
            </td>
          </tr>
        </tbody>
      </table>
      <TraceabilityPagination
        url={urlPagination}
        searchQuery={searchQuery}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <TableLoading loading={loading || false} />
    </div>
  );
};

export default ListTable;

ListTable.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    cell: PropTypes.func,
    alignRight: PropTypes.bool,
  })),
  data: PropTypes.arrayOf(PropTypes.object),
  dataTooltip: PropTypes.bool,
  urlPagination: PropTypes.string,
  searchQuery: PropTypes.string,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};
