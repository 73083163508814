export default class SessionStorageService {
  static setItem(key, value) {
    let processedValue = value;

    if (typeof value === 'object') {
      processedValue = JSON.stringify(value);
    }

    sessionStorage.setItem(key, processedValue);
  }

  static getItem(key) {
    const item = sessionStorage.getItem(key);

    try {
      return JSON.parse(item);
    } catch (err) {
      return item;
    }
  }

  static clear() {
    sessionStorage.clear();
  }

  static removeItem(key) {
    sessionStorage.removeItem(key);
  }

  static key(index) {
    return sessionStorage.key(index);
  }

  static keys() {
    return Object.keys(sessionStorage);
  }

  static hasKey(key) {
    return SessionStorageService.keys().includes(key);
  }
}
