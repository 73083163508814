import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Pagination from 'material-ui-flat-pagination';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(1, 0),
  },
}));

/**
 * Paginação de models
 *
 * @visibleName Traceability Pagination
 */
const TraceabilityPagination = (props) => {
  const {
    url,
    searchQuery,
    currentPage,
    totalPages,
  } = props;

  const [page, setPage] = useState(currentPage);

  let paginationForm = useRef();

  const handleChange = (_e, _offset, newPage) => {
    setPage(newPage);
  };

  const setFormRef = (form) => {
    paginationForm = form;
  };

  useEffect(() => {
    if (page !== currentPage) {
      paginationForm.submit();
    }
  }, [page]);

  const classes = useStyles();

  return (
    <>
      {totalPages > 0 && url && (
        <>
          <Grid container component="main" className={classes.root}>
            <Grid item xs={12}>
              <Pagination
                offset={(currentPage || 1) - 1}
                total={totalPages}
                centerRipple
                onClick={handleChange}
              />

              <form
                ref={setFormRef}
                action={url}
                method="get"
              >
                <TextField
                  name="page"
                  value={page}
                  type="hidden"
                />
                <TextField
                  name="q"
                  value={searchQuery || ''}
                  type="hidden"
                />
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default TraceabilityPagination;

TraceabilityPagination.propTypes = {
  url: PropTypes.string,
  searchQuery: PropTypes.string,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};
