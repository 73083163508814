import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ListTable from '../../components/ListTable';
import DropdownMenu from '../../components/DropdownMenu';
import DeleteListItem from '../../components/DeleteListItem';
import EditListItem from '../../components/EditListItem';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import RedirectListItem from '../../components/RedirectListItem';

const Labels = (props) => {
  const {
    labels,
    query,
    routes,
  } = props;

  const [allLabels, setLabel] = useState([]);

  const urlNewLabel = routes.newLabelPath;
  const urlLabel = routes.labelsPath;

  useEffect(() => {
    setLabel(labels.map((label) => {
      const updatedLabel = { ...label, id: label.id };
      return updatedLabel;
    }));
  }, [labels]);

  const columns = [
    {
      header: 'Descrição',
      cell: (row) => row.description,
    },
    {
      header: 'Largura',
      cell: (row) => row.width,
    },
    {
      header: 'Altura',
      cell: (row) => row.height,
    },
    {
      header: 'Ângulo',
      cell: (row) => row.angle,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <>
          <DropdownMenu>
            <EditListItem
              path={routes.editLabelPath}
              itemId={row.id}
            />
            <DeleteListItem
              path={routes.labelPath}
              itemId={row.id}
              message={
                `Você tem certeza de que deseja excluir a etiqueta <b>${row.description}</b>?
                <br>Esta ação é irreversível!`
              }
            />
            <RedirectListItem
              path={routes.labelPrintFieldsPath}
              itemId={row.id}
              description="Campos de Impressão"
              icon={<ReceiptIcon fontSize="small" />}
            />
          </DropdownMenu>
        </>
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div>
          <Typography component="h1" variant="h2" align="center">
            Etiquetas
          </Typography>
          <SearchBar
            url={urlLabel}
            query={query}
          />
          <NewButton
            url={urlNewLabel}
            description="Nova Etiqueta"
          />
          <ListTable
            columns={columns}
            data={allLabels}
          />
        </div>
      </Container>
    </>
  );
};

export const labelPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  angle: PropTypes.number,
  layout: PropTypes.oneOf(['landscape', 'portrait']),
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  strokeBounds: PropTypes.bool,
});

Labels.propTypes = {
  labels: PropTypes.arrayOf(labelPropType).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Labels;
