import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Typography, Grid, Paper, ButtonGroup, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PdfUtilsService from '../../../services/PdfUtilsService';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxHeight: '70%',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '600px',
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    boxShadow: theme.shadows[5],
    overflowY: 'auto',
  },
  buttonGroup: {
    float: 'right',
  },
  grid: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  gridTitle: {
    marginBottom: theme.spacing(1),
  },
}));

const PrintSeedLotModal = (props) => {
  const {
    pdfs,
  } = props;

  const [open, setOpen] = useState((pdfs || []).length !== 1);

  const printPreview = (pdf) => {
    const theWindow = PdfUtilsService.openPdfWindow(pdf.pdf);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
    theDoc.body.appendChild(theScript);
  };

  const error = (pdfs || []).length === 0;

  useEffect(() => {
    if ((pdfs || []).length === 1) {
      printPreview(pdfs[0]);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="form-model-title"
        className={classes.modal}
        open={open}
      >
        <Paper className={classes.paper}>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} className={classes.gridTitle}>
              <Typography
                variant="h5"
                align="center"
                id="form-model-title"
                className={classes.title}
              >
                Etiquetas
              </Typography>
            </Grid>
            {error && (
              <Grid item>
                <Typography
                  variant="h6"
                  align="center"
                  id="form-model-error"
                  className={classes.error}
                >
                  Nenhuma etiqueta encontrada
                </Typography>
              </Grid>
            )}
            {!error && pdfs.map((pdf) => (
              <Grid item key={pdf.description} xs={12}>
                <Button
                  className="button is-primary"
                  type="button"
                  onClick={() => printPreview(pdf)}
                >
                  {pdf.description}
                </Button>
              </Grid>
            ))}

            <Grid item xs={12}>
              <ButtonGroup className={classes.buttonGroup}>
                <Button
                  variant="text"
                  color="primary"
                  id="form-model-cancel-btn"
                  onClick={handleClose}
                >
                  Fechar
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

PrintSeedLotModal.propTypes = {
  pdfs: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    pdf: PropTypes.string.isRequired,
  })),
};

export default PrintSeedLotModal;
