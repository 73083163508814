/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/core/styles/withStyles';

const style = (theme) => ({
  cardAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginTop: '-20px',
  },
});

function CardAvatar(props) {
  const {
    children,
    classes,
    className,
    ...other
  } = props;

  const cardAvatarClasses = classNames({
    [classes.cardAvatar]: true,
    [className]: !!className,
  });

  return (
    <Avatar variant="rounded" aria-label="recipe" className={cardAvatarClasses} {...other}>
      {children}
    </Avatar>
  );
}

CardAvatar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  profile: PropTypes.bool,
  plain: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(style)(CardAvatar);
