import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-slick';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  imageTitle: {
    backgroundColor: 'rgba(0,0,0, 0.25)',
    display: 'inline',
    padding: '3px 5px',
    borderRadius: 3,
  },
}));


const ImagesCarousel = ({ images }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Carousel
        dots
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
      >
        {images.map((image) => (
          <div key={image.id}>
            <img
              src={image.fileUrl}
              alt={image.description}
              className="slick-image"
            />
            <div className="slick-caption">
              {image.description && (
                <h4 className={classes.imageTitle}>
                  {image.description}
                </h4>
              )}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

ImagesCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};


export default ImagesCarousel;
