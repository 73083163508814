import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InfoItem from './InfoItem';
import { gridSizeType, fieldItemType } from '../../../services/GeneralTypes';
import UtilsService from '../../../services/UtilsService';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    textAlign: 'center',
  },
  subComponentsContainer: {
    paddingTop: theme.spacing(2),
  },
}));

const InfoBox = (props) => {
  const {
    title,
    getTitle,
    fields,
    data,
    gridSize,
    subComponents,
  } = props;

  const { xs, md, lg } = gridSize || {};

  const classes = useStyles();

  const showBox = () => UtilsService.showBoxOfFields(data, fields);

  const getFieldKey = (field) => {
    if (field.attributes) {
      return field.attributes.map((attribute) => attribute.attribute).join();
    }

    return field.attribute;
  };

  const getFieldDescription = (field) => {
    if (field.description) return field.description;

    if (field.getDescription) {
      try {
        return UtilsService.runFunctionFromString({ seedLot: data }, field.getDescription);
      } catch (error) {
        console.error(error);
      }
    }

    return '';
  };

  const getCardTitle = () => {
    if (title) return title;

    if (getTitle) {
      try {
        return UtilsService.runFunctionFromString({ seedLot: data }, getTitle);
      } catch (error) {
        console.error(error);
      }
    }

    return '';
  };

  return (
    <>
      {showBox() && (
        <Grid item lg={lg || 4} md={md || 6} xs={xs || 12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={3}
                    className={classes.tableHead}
                    style={{ textAlign: 'center' }}
                  >
                    {getCardTitle()}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field) => UtilsService.showField(data, field) && (
                  <TableRow key={getFieldKey(field)}>
                    <TableCell align="right" colSpan={2}>{getFieldDescription(field)}</TableCell>
                    <TableCell align="left">
                      <b>
                        <InfoItem
                          data={data}
                          field={field}
                        />
                      </b>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={2} className={classes.subComponentsContainer}>
            {subComponents}
          </Grid>
        </Grid>
      )}
    </>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string,
  getTitle: PropTypes.string,
  fields: PropTypes.arrayOf(fieldItemType),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  gridSize: gridSizeType,
  subComponents: PropTypes.arrayOf(PropTypes.element),
};

export default InfoBox;
