import React from 'react';
import PropTypes from 'prop-types';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const LotListItem = (props) => {
  const { itemId, path, forwardedRef } = props;
  const urlLot = path.replace(':seed_lot_id', itemId);

  return (
    <ListItem
      button
      component="a"
      href={urlLot}
      ref={forwardedRef}
      target="_blank"
    >
      <ListItemIcon>
        <DescriptionIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Página do lote" />
    </ListItem>
  );
};

LotListItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  forwardedRef: PropTypes.func,
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <LotListItem {...props} forwardRef={ref} />);
