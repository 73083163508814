import React, { useState } from 'react';
import ViewModal from '../../components/ViewModal';
import { seedBeneficiationUnitType } from './SeedBeneficiationUnit';
import { fields, buildSeedBeneficiationUnit } from './EditSeedBeneficiationUnitModal';

const ViewSeedBeneficiationUnit = ({
  seedBeneficiationUnit,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <ViewModal
        open={open}
        title="UBS"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildSeedBeneficiationUnit(seedBeneficiationUnit)}
      />
    </>
  );
};

ViewSeedBeneficiationUnit.propTypes = {
  seedBeneficiationUnit: seedBeneficiationUnitType,
};

export default ViewSeedBeneficiationUnit;
