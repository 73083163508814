import React from 'react';
import PropTypes from 'prop-types';
import MessageSnackBar from './MessageSnackBar';

/**
 * Exibe uma mensagem de aviso ao usuário
 */
const HeaderMessage = (props) => {
  const {
    notifications,
  } = props;

  return (
    <>
      {notifications && notifications.map((element) => (
        <MessageSnackBar
          key={element[0]}
          variant={element[0]}
          message={element[1]}
        />
      ))}
    </>
  );
};

export default HeaderMessage;

HeaderMessage.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.string,
    ),
  ),
};
