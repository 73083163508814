import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { SeedLotPropType } from '../seedLot/SeedLots';
import { regionPropType } from '../region/Regions';
import PlantabilityCalculator from './PlantabilityCalculator';
import {
  unitConversionPropType,
  SEED_SPEND_TYPE,
  WEIGHT_SPEND_TYPE,
} from '../unit_conversion/UnitConversions';

const SeedLotPlantabilityCalculator = (props) => {
  const {
    title,
    seedLot,
    pmsField,
    germinationField,
    vigorField,
    purityField,
    useVigor,
    type,
    regions,
    efficiency,
    unitConversions,
    spendType,
  } = props;
  const pms = Number.parseFloat(get(seedLot, pmsField));
  const germination = Number.parseFloat(get(seedLot, germinationField));
  const vigor = Number.parseFloat(get(seedLot, vigorField));
  const purity = Number.parseFloat(get(seedLot, purityField));

  const useNumberOfSeeds = unitConversions.some((conversion) => (
    (!conversion.species || conversion.species.id === seedLot.variety.specie.id)
    && conversion.type === SEED_SPEND_TYPE
  ));

  const showCalculator = () => (pms > 0 || useNumberOfSeeds) && germination > 0;

  return (
    <>
      {showCalculator() && (
        <PlantabilityCalculator
          title={title}
          pms={pms}
          germination={germination}
          vigor={vigor}
          purity={purity}
          useVigor={useVigor}
          type={type}
          regions={regions}
          variety={seedLot.variety}
          efficiency={efficiency}
          unitConversions={unitConversions}
          spendType={spendType}
        />
      )}
    </>
  );
};

SeedLotPlantabilityCalculator.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  seedLot: SeedLotPropType,
  pmsField: PropTypes.string,
  germinationField: PropTypes.string,
  vigorField: PropTypes.string,
  purityField: PropTypes.string,
  useVigor: PropTypes.bool,
  regions: PropTypes.arrayOf(regionPropType),
  efficiency: PropTypes.number,
  unitConversions: PropTypes.arrayOf(unitConversionPropType),
  spendType: PropTypes.oneOf([WEIGHT_SPEND_TYPE, SEED_SPEND_TYPE]),
};

export default SeedLotPlantabilityCalculator;
