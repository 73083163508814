import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { plantabilityRecommendationPropType } from './PlantabilityRecommendations';
import FormModal from '../../components/FormModal';
import { varietyPropType } from '../variety/Varieties';
import { regionPropType } from '../region/Regions';
import { recommendationPeriodPropType } from '../recommendationPeriod/RecommendationPeriods';

const renderVarietyLabel = (variety) => {
  if (!variety.specie) {
    return variety.description;
  }

  return `${variety.description} (${variety.specie.description})`;
};

export const fields = [
  {
    id: 'variety',
    label: 'Selecione uma Cultivar',
    viewLabel: 'Cultivar',
    type: 'autocomplete',
    field: 'variety_id',
    validations: [
      (v) => !!v || 'Cultivar é obrigatória',
    ],
    required: true,
    renderLabel: renderVarietyLabel,
  },
  {
    id: 'region',
    label: 'Selecione uma Região',
    viewLabel: 'Região',
    type: 'autocomplete',
    field: 'region_id',
    validations: [
      (v) => !!v || 'Região é obrigatória',
    ],
    required: true,
    renderLabel: (region) => region.description,
  },
  {
    id: 'fertility',
    label: 'Selecione uma Fertilidade',
    viewLabel: 'Fertilidade',
    type: 'autocomplete',
    field: 'fertility',
    validations: [
      (v) => !!v || 'Fertilidade é obrigatória',
    ],
    required: true,
    renderLabel: (fertility) => fertility.description,
  },
  {
    id: 'recommendationPeriod',
    label: 'Selecione um Período de Recomendação',
    viewLabel: 'Período de Recomendação',
    type: 'autocomplete',
    field: 'recommendation_period_id',
    validations: [
      (v) => !!v || 'Período de Recomendação é obrigatório',
    ],
    required: true,
    renderLabel: (recommendationPeriod) => recommendationPeriod.description,
  },
  {
    id: 'plantsNumber',
    label: 'Número de plantas',
    type: 'number',
    field: 'plants_number',
    validations: [
      (v) => !!v || 'Número de plantas é obrigatório',
      (v) => v > 0 || 'Número de plantas deve ser maior que 0',
    ],
    required: true,
  },
];

const fertilities = [
  {
    id: 'low',
    description: 'Baixa',
  },
  {
    id: 'medium',
    description: 'Média',
  },
  {
    id: 'high',
    description: 'Alta',
  },
];

const emptyState = {
  description: '',
  plantsNumber: 0,
  fertility: fertilities[2],
  variety: null,
  region: null,
  recommendationPeriod: null,
};

export const buildPlantabilityRecommendation = (plantabilityRecommendation) => {
  if (plantabilityRecommendation) {
    const fertility = fertilities.find(({ id }) => id === plantabilityRecommendation.fertility);
    return {
      fertility,
      description: plantabilityRecommendation.description,
      plantsNumber: plantabilityRecommendation.plantsNumber,
      variety: plantabilityRecommendation.variety,
      region: plantabilityRecommendation.region,
      recommendationPeriod: plantabilityRecommendation.recommendationPeriod,
    };
  }

  return emptyState;
};

const EditPlantabilityRecommendationModal = (props) => {
  const {
    routes,
    plantabilityRecommendation,
    varieties,
    regions,
    recommendationPeriods,
  } = props;
  const urlPlantabilityRecommendation = routes.plantabilityRecommendationsPath;

  const [state, setState] = useState(() => (
    buildPlantabilityRecommendation(plantabilityRecommendation, varieties)
  ));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(plantabilityRecommendation));
  const [filteredPeriods, setFilteredPeriods] = useState(recommendationPeriods || []);

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  useEffect(() => {
    if (!state.variety) {
      return;
    }

    const specieId = (state.variety.specie || {}).id;
    const filter = (period) => !period.specie || period.specie.id === specieId;
    setFilteredPeriods((recommendationPeriods || []).filter(filter));
  }, [state.variety]);

  useEffect(() => {
    const { recommendationPeriod } = state;
    if (!recommendationPeriod) {
      return;
    }

    if (!some(filteredPeriods, ['id', recommendationPeriod.id])) {
      handleChange('recommendationPeriod', null);
    }
  }, [filteredPeriods]);

  const values = {
    variety: varieties,
    region: regions,
    fertility: fertilities,
    recommendationPeriod: filteredPeriods,
  };

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleClose = () => setOpen(false);

  const urlForm = edit
    ? `${urlPlantabilityRecommendation}/${plantabilityRecommendation.id}`
    : urlPlantabilityRecommendation;

  return (
    <>
      <FormModal
        title="Recomendação de Plantabilidade"
        open={open}
        formForProps={{
          method: edit ? 'put' : 'post',
          url: urlForm,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >

        {fields.map((
          {
            id,
            label,
            type,
            field,
            validations,
            placeholder,
            format,
            required,
            autoFocus,
            renderLabel,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            values={values[id]}
            renderLabel={renderLabel}
          />
        ))}
      </FormModal>
      )
    </>
  );
};

EditPlantabilityRecommendationModal.propTypes = {
  plantabilityRecommendation: plantabilityRecommendationPropType,
  varieties: PropTypes.arrayOf(varietyPropType),
  regions: PropTypes.arrayOf(regionPropType),
  recommendationPeriods: PropTypes.arrayOf(recommendationPeriodPropType),
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditPlantabilityRecommendationModal;
