import React, { useState } from 'react';
import ViewModal from '../../components/ViewModal';
import { fieldPropType } from './Fields';
import { fields, buildField } from './EditFieldModal';

const ViewFieldModal = (props) => {
  const {
    field,
  } = props;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Campo de Produção"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={buildField(field)}
      />
    </>
  );
};


ViewFieldModal.propTypes = {
  field: fieldPropType,
};

export default ViewFieldModal;
