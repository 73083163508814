import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { cropPropType } from './Crops';
import FormModal from '../../components/FormModal';
import FormService from '../../../services/FormService';

export const fields = [
  {
    id: 'description',
    label: 'Descrição',
    type: 'text',
    field: 'description',
    visible: true,
    autoFocus: true,
    required: true,
  },
  {
    id: 'startDate',
    label: 'Data Início',
    type: 'datePicker',
    field: 'start_date',
    placeholder: '01/01/2020',
    format: 'DD/MM/YYYY',
    visible: true,
    autoFocus: false,
    required: true,
  },
  {
    id: 'endDate',
    label: 'Data Fim',
    type: 'datePicker',
    field: 'end_date',
    placeholder: '01/01/2020',
    format: 'DD/MM/YYYY',
    visible: true,
    autoFocus: false,
    required: true,
  },
];

const emptyState = {
  description: '',
  startDate: new Moment(),
  endDate: new Moment(),
};

export const buildCrop = (crop) => {
  if (FormService.hasData) {
    const { startDate, endDate, ...fromData } = FormService.data;

    return {
      ...fromData,
      startDate: new Moment(startDate),
      endDate: new Moment(endDate),
    };
  }

  if (crop) {
    return {
      description: crop.description,
      startDate: new Moment(crop.startDate).utcOffset(crop.startDate),
      endDate: new Moment(crop.endDate).utcOffset(crop.endDate),
    };
  }

  return emptyState;
};

const EditCropModal = (props) => {
  const { routes, crop } = props;
  const urlCrops = routes.cropsPath;

  const [state, setState] = useState(() => buildCrop(crop));
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(crop));

  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      const path = routes.cropPath;
      return path.replace(':id', crop.id);
    }

    return urlCrops;
  };

  const validations = {
    description: [
      (v) => !!v || 'Descrição é obrigatória',
    ],
    startDate: [
      (v) => !!v || 'Data de Início é obrigatória',
      (v) => ('isValid' in v && v.isValid()) || 'Data inválida',
    ],
    endDate: [
      (v) => !!v || 'Data de Fim é obrigatória',
      (v) => ('isValid' in v && v.isValid()) || 'Data inválida',
      (v) => (
        'isBefore' in v
        && !v.isBefore(state.startDate)
      ) || 'Data final deve ser maior que a data inicial',
    ],
  };

  const onFormSubmit = () => {
    FormService.data = state;
  };

  const validInputs = () => {
    const fieldsWithValidations = fields.map(({ id, ...field }) => ({
      validations: validations[id],
      id,
      ...field,
    }));

    return UtilsService.validateState(fieldsWithValidations, state);
  };

  return (
    <>
      <FormModal
        open={open}
        title="Safra"
        formForProps={{
          url: getUrlForm(),
          method: edit ? 'put' : 'post',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {fields.map((
          {
            id, label, type, field, placeholder, format, required, autoFocus,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations[id]}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
          />
        ))}
      </FormModal>
    </>
  );
};

EditCropModal.propTypes = {
  crop: cropPropType,
  routes: PropTypes.objectOf(PropTypes.string),
};

export default EditCropModal;
