import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { FormFor } from 'react-rails-form-helpers';

const BackButton = (props) => {
  const { url, description } = props;

  return (
    <FormFor
      url={url}
      method="get"
    >
      <div className="buttons is-left">
        <Button
          className="button is-secondary"
          startIcon={<ArrowBackIcon />}
          type="submit"
        >
          {description || 'Retornar'}
        </Button>
      </div>
    </FormFor>
  );
};

BackButton.propTypes = {
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default BackButton;
