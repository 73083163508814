import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import FormModal from '../../components/FormModal';
import { varietyPropType } from '../variety/Varieties';
import { regionPropType } from '../region/Regions';
import { recommendationPeriodPropType } from '../recommendationPeriod/RecommendationPeriods';
import BatchPlantabilityRecommendationTable from './BatchPlantabilityRecommendationTable';

const NewBatchPlantabilityRecommendationModal = (props) => {
  const {
    routes,
    varieties,
    regions,
    recommendationPeriods,
  } = props;

  const fertilities = [
    {
      id: 'low',
      description: 'Baixa',
    },
    {
      id: 'medium',
      description: 'Média',
    },
    {
      id: 'high',
      description: 'Alta',
    },
  ];

  const getInitialState = () => ({
    description: '',
    plantsNumber: 0,
    fertility: fertilities[2],
    variety: null,
    region: null,
    recommendationPeriod: null,
  });

  const [state, setState] = useState(getInitialState());
  const [open, setOpen] = useState(true);

  const fields = [
    {
      id: 'variety',
      label: 'Selecione uma Cultivar',
      type: 'autocomplete',
      field: 'variety_id',
      validations: [
        (v) => !!v || 'Cultivar é obrigatória',
      ],
      required: true,
      values: varieties,
      gridSize: 6,
      renderLabel: (variety) => `${variety.description} (${variety.specie.description})`,
    },
    {
      id: 'region',
      label: 'Selecione uma Região',
      type: 'autocomplete',
      field: 'region_id',
      validations: [
        (v) => !!v || 'Região é obrigatória',
      ],
      required: true,
      values: regions,
      gridSize: 6,
      renderLabel: (region) => region.description,
    },
  ];

  const [validRows, setValidRows] = useState(false);
  const [filteredPeriods, setFilteredPeriods] = useState([]);

  useEffect(() => {
    if (!state.variety) {
      return;
    }

    const specieId = (state.variety.specie || {}).id;
    const filter = (period) => !period.specie || period.specie.id === specieId;
    setFilteredPeriods((recommendationPeriods || []).filter(filter));
  }, [state.variety]);

  const validInputs = () => UtilsService.validateState(fields, state) && validRows;
  const handleChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => routes.createBatchPlantabilityRecommendationsPath;

  return (
    <>
      <FormModal
        title="Recomendação de Plantabilidade"
        maxWidth="60%"
        maxHeight="85%"
        open={open}
        formForProps={{
          method: 'post',
          url: getUrlForm(),
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >

        {fields.map((
          {
            id,
            label,
            type,
            field,
            validations,
            placeholder,
            format,
            required,
            autoFocus,
            values,
            renderLabel,
            gridSize,
          },
        ) => (
          <FloatingLabelInput
            key={field}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={field}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            values={values}
            gridSize={gridSize}
            renderLabel={renderLabel}
          />
        ))}
        <BatchPlantabilityRecommendationTable
          routes={routes}
          varieties={varieties}
          regions={regions}
          recommendationPeriods={filteredPeriods}
          hasData={(valid) => setValidRows(valid)}
        />
      </FormModal>
      )
    </>
  );
};

NewBatchPlantabilityRecommendationModal.propTypes = {
  varieties: PropTypes.arrayOf(varietyPropType),
  regions: PropTypes.arrayOf(regionPropType),
  recommendationPeriods: PropTypes.arrayOf(recommendationPeriodPropType),
  routes: PropTypes.objectOf(PropTypes.string),
};

export default NewBatchPlantabilityRecommendationModal;
