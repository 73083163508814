import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LanguageIcon from '@material-ui/icons/Language';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Marker } from '@react-google-maps/api';
import { get } from 'lodash';
import Map from '../../components/Map';
import CardAvatar from '../../components/Card/CardAvatar';
import CardTitle from '../../components/Card/CardTitle';
import { SeedLotPropType } from '../seedLot/SeedLots';

const useStyles = makeStyles((theme) => ({
  legend: {
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
  },
  icon: {
    textAlign: 'center',
    marginRight: theme.spacing(1),
  },
  text: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const MapCard = (props) => {
  const {
    title,
    seedLot,
    subComponents,
  } = props;

  const fieldMarker = {
    key: 'field',
    legend: 'Campo de Produção',
    description: get(seedLot, 'firstProductionField.description'),
    coordinate: {
      lat: get(seedLot, 'firstProductionField.geolocation.latitude'),
      lng: get(seedLot, 'firstProductionField.geolocation.longitude'),
    },
    icon: {
      url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
    },
    style: {
      color: '#00e64c',
    },
  };

  const ubsMarker = {
    key: 'ubs',
    icon: {
      url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    },
    legend: 'Processamento / UBS',
    style: {
      color: '#6a91fd',
    },
    description: get(seedLot, 'seedBeneficiationUnit.name'),
    coordinate: {
      lat: get(seedLot, 'seedBeneficiationUnit.geolocation.latitude'),
      lng: get(seedLot, 'seedBeneficiationUnit.geolocation.longitude'),
    },
  };

  const isValid = (position) => position && position.lat && position.lng;
  const markers = [fieldMarker, ubsMarker].filter((marker) => isValid(marker.coordinate));

  const getCoordinates = () => markers
    .map((marker) => [marker.coordinate.lat, marker.coordinate.lng]);

  const classes = useStyles();

  const getTitle = () => {
    let mapTitle = title || 'Localização do Campo de Produção';
    const fieldName = get(seedLot, 'firstProductionField.description');

    if (isValid(fieldMarker.coordinate) && fieldName) {
      mapTitle = `${mapTitle} ${fieldName}`;
    }

    return mapTitle;
  };

  return (
    <>
      {markers.length > 0 && (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={(
                <CardAvatar>
                  <LanguageIcon />
                </CardAvatar>
              )}
              title={(
                <CardTitle
                  title={getTitle()}
                />
              )}
            />
            <CardContent>
              <div className="flex-fill">
                <Map
                  mapContainerClassName="flex-fill traceability-map-content"
                  options={{
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    controlSize: 20,
                  }}
                  allCoordinates={getCoordinates()}
                >
                  {markers.map((marker) => (
                    <Marker
                      position={marker.coordinate}
                      icon={marker.icon}
                      key={marker.key}
                    />
                  ))}
                </Map>
              </div>
              <Grid
                container
                spacing={3}
              >
                {markers.map((marker) => (
                  <Grid item xs={6} key={marker.key}>
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.legend}
                    >
                      <Brightness1Icon
                        style={marker.style}
                        className={classes.icon}
                      />
                      <Typography className={classes.text}>
                        {marker.legend}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
          {subComponents}
        </Grid>
      )}
    </>
  );
};

MapCard.propTypes = {
  title: PropTypes.string,
  seedLot: SeedLotPropType,
  subComponents: PropTypes.arrayOf(PropTypes.element),
};

export default MapCard;
