import React from 'react';

const IconSeedQualityPattern = () => {
  const styles = {
    fillcolor: {
      fill: '#303030',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="qualityPattern" style={styles.fillcolor} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 79.5 78.36">
      <path d="M17.56,30.31H10.24A25.63,25.63,0,0,0,35.87,55.93V72.41a1.83,1.83,0,0,0,1.83,1.83h3.66a1.83,1.83,0,0,0,1.83-1.83V55.93A25.62,25.62,0,0,0,17.56,30.31ZM61.49,23A25.59,25.59,0,0,0,39.11,36.17a29.43,29.43,0,0,1,6.75,12.31A25.64,25.64,0,0,0,68.82,23Z" />
      <path d="M28.3,4.75,26.21,9l-4.7.69a1,1,0,0,0-.57,1.75l3.4,3.31-.81,4.68A1,1,0,0,0,25,20.51l4.2-2.2,4.2,2.2a1,1,0,0,0,1.49-1.08l-.8-4.68,3.39-3.31A1,1,0,0,0,37,9.69L32.25,9l-2.1-4.25a1,1,0,0,0-1.85,0Z" />
      <path d="M48.78,4.75,46.68,9,42,9.69a1,1,0,0,0-.57,1.75l3.4,3.31L44,19.43a1,1,0,0,0,1.49,1.08l4.2-2.2,4.21,2.2a1,1,0,0,0,1.49-1.08l-.81-4.68L58,11.44a1,1,0,0,0-.57-1.75L52.73,9l-2.1-4.25a1,1,0,0,0-1.85,0Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconSeedQualityPattern;
