import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';

/**
 * Componente para exibir um dropdown com comportamento automático
 *
 * @todo Permitir personalizar o botão/ícone
 * @class Dropdown
 */
const DropdownMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const id = (Math.random() * 1e10).toString();
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { children, disabled } = props;

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={id}
        aria-haspopup="true"
        onClick={openMenu}
        size="small"
        fontSize="small"
        disabled={disabled}
      >
        <MoreVertIcon size="small" fontSize="small" />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { children }
      </Menu>
    </>
  );
};

export default DropdownMenu;

DropdownMenu.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
