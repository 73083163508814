import React from 'react';

const IconUnidadeDeBeneficiamentoDeSementes = () => {
  const styles = {
    fillcolor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="unidadeDeBeneficiamentoDeSementes" style={styles.fillcolor} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 79.5 78.36">
      <path d="M53.63,61.47A13.88,13.88,0,1,1,39.75,47.59,13.88,13.88,0,0,1,53.63,61.47ZM38.14,68.82l10.3-10.3a.89.89,0,0,0,0-1.26L47.18,56a.9.9,0,0,0-1.27,0l-8.4,8.4-3.92-3.92a.9.9,0,0,0-1.27,0l-1.26,1.27a.89.89,0,0,0,0,1.26l5.82,5.82a.89.89,0,0,0,1.26,0Z" />
      <circle cx="32.25" cy="13.36" r="2.51" />
      <circle cx="40.25" cy="6.36" r="2.51" />
      <circle cx="48.25" cy="13.36" r="2.51" />
      <path d="M58.35,19.19H21.15a1.92,1.92,0,0,0-1.36,3.28L34.62,37.31v9.63a16.27,16.27,0,0,1,10.26,0V37.31L59.71,22.47A1.92,1.92,0,0,0,58.35,19.19Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconUnidadeDeBeneficiamentoDeSementes;
