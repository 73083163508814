import React, { useState } from 'react';

import { seedQualityPatternPropType } from './SeedQualityPatterns';
import { fields, buildSeedQualityPattern } from './EditSeedQualityPatternModal';
import ViewModal from '../../components/ViewModal';

const ViewSeedQualityPatternModal = (props) => {
  const { seedQualityPattern } = props;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ViewModal
      open={open}
      title="Padrão de Qualidade"
      actionsButtons={{
        close: {
          onClick: handleClose,
        },
      }}
      fields={fields}
      data={buildSeedQualityPattern(seedQualityPattern)}
    />
  );
};

ViewSeedQualityPatternModal.propTypes = {
  seedQualityPattern: seedQualityPatternPropType,
};

export default ViewSeedQualityPatternModal;
