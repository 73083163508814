import React from 'react';

const IconPeriodo = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="periodo" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Periodos</title>
      <path d="M68.5,25.8H9.6A1.6,1.6,0,0,1,8,24.2v-5a6.6,6.6,0,0,1,6.6-6.7h6.7V5.3A1.7,1.7,0,0,1,23,3.6h5.5a1.8,1.8,0,0,1,1.7,1.7v7.2H48V5.3a1.7,1.7,0,0,1,1.6-1.7h5.6a1.7,1.7,0,0,1,1.6,1.7h0v7.2h6.7a6.7,6.7,0,0,1,6.7,6.7v5A1.7,1.7,0,0,1,68.5,25.8ZM9.6,30.3H68.5A1.7,1.7,0,0,1,70.2,32h0V68.1a6.6,6.6,0,0,1-6.7,6.6H14.6A6.5,6.5,0,0,1,8,68.1V32A1.6,1.6,0,0,1,9.6,30.3Zm29,15.2L25.4,58.7l-.8,6.7A1.5,1.5,0,0,0,25.9,67h.3l6.7-.8L46.1,53a.9.9,0,0,0,0-1h0l-6.5-6.4a.8.8,0,0,0-1-.1h0Zm15.2-4.2-3.5-3.6a2.8,2.8,0,0,0-4,0l-3.6,3.7a.6.6,0,0,0,0,.9h0l6.5,6.4a.6.6,0,0,0,.9,0h.1l3.6-3.6A2.8,2.8,0,0,0,53.8,41.3Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconPeriodo;
