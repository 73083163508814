import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HiddenField } from 'react-rails-form-helpers/lib/fields';
import _ from 'lodash';
import {
  Grid,
  Button,
  Link,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ClearIcon from '@material-ui/icons/Clear';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import FileUtilsService from '../../../services/FileUtilsService';
import { producerPropType, cityPropType } from './Producers';
import FormModal from '../../components/FormModal';
import { seedBeneficiationUnitType } from '../seedBeneficiationUnit/SeedBeneficiationUnit';
import FormService from '../../../services/FormService';
import MessageSnackBar from '../../components/MessageSnackBar';

export const fields = [
  {
    id: 'name',
    label: 'Nome',
    type: 'text',
    field: 'name',
    validations: [
      (v) => !!v || 'Nome é obrigatório',
    ],
    visible: true,
    autoFocus: true,
    required: true,
  },
  {
    id: 'address',
    label: 'Endereço',
    type: 'text',
    field: 'address',
    validations: [
      (v) => !!v || 'Endereço é obrigatório',
    ],
    visible: true,
    required: true,
  },
  {
    id: 'city',
    label: 'Selecione uma Cidade',
    viewLabel: 'Cidade',
    type: 'autocomplete',
    field: 'city_id',
    validations: [
      (v) => !!v || 'Cidade é obrigatória',
    ],
    visible: true,
    required: true,
    renderLabel: (city) => `${city.description} (${city.uf})`,
  },
  {
    id: 'renasem',
    label: 'RENASEM',
    type: 'text',
    field: 'renasem',
  },
  {
    id: 'seedBeneficiationUnits',
    label: 'Unidades de Beneficiamento de Sementes',
    viewLabel: 'UBSs',
    type: 'autocomplete',
    field: '',
    multiple: true,
    // eslint-disable-next-line react/prop-types
    renderLabel: ({ name, id }) => (
      <>
        {name}
        <HiddenField name="seed_beneficiation_unit_ids[]" defaultValue={id} />
      </>
    ),
    renderViewLabel: (sbu) => sbu.name,
  },
  {
    id: 'sbuField',
    label: '',
    type: 'hidden',
    fieldStyle: { display: 'none' },
    field: 'seed_beneficiation_unit_ids[]',
  },
];

const processInitialFile = (file) => {
  if (!file) {
    return FileUtilsService.initialFileState();
  }

  const newFile = file;
  newFile.hasFile = !!newFile.fileName;

  return { ...FileUtilsService.initialFileState(), ...newFile };
};

const emptyState = {
  name: '',
  city: null,
  address: '',
  renasem: '',
  seedBeneficiationUnits: [],
  image: FileUtilsService.initialFileState(),
};

export const buildProducer = (producer) => {
  const image = processInitialFile(_.get(producer, 'image'));

  if (FormService.hasData) {
    const { imageChanged, ...filtredData } = FormService.data;

    return {
      ...filtredData,
      image,
    };
  }

  if (producer) {
    const {
      name,
      city,
      address,
      renasem,
      seedBeneficiationUnit,
    } = producer;

    return {
      name,
      city,
      address,
      seedBeneficiationUnits: seedBeneficiationUnit,
      renasem: renasem || '',
      image,
    };
  }

  return emptyState;
};

const EditProducerModal = (props) => {
  const {
    routes,
    producer,
    cities,
    seedBeneficiationUnits: allSBUs,
  } = props;

  const urlProducers = routes.producersPath;

  const [state, setState] = useState(() => buildProducer(producer));
  const [messageSnackBar, setMessageSnackBar] = useState({ variant: '', message: '' });
  const [open, setOpen] = useState(true);
  const [edit] = useState(Boolean(producer));
  const [imageFields, setImageFields] = useState([]);

  const [forceUpdateImage, setForceUpdateImage] = useState(false);

  const values = {
    seedBeneficiationUnits: allSBUs,
    city: cities,
  };

  const removeImage = () => {
    const newState = { ...state };
    const { image: currentObj } = newState;

    const newImage = FileUtilsService.initialFileState();

    if (currentObj.id) {
      newImage.destroy = true;
      newImage.id = currentObj.id;
    }

    setForceUpdateImage(true);

    setState({ ...newState, image: newImage });
  };

  const getFileName = (file) => {
    if (file.fileName) {
      return file.fileName;
    }

    return (file.image) ? file.image.fileName : '';
  };

  const getFileFields = (objectKey, fileLabel) => {
    const { image: currentObj } = state;

    if (typeof currentObj !== 'object') return [];

    const hiddenClass = { display: 'none' };

    const fileFields = [
      {
        id: `${objectKey}.file`,
        label: fileLabel,
        type: 'file',
        field: `${objectKey}_attributes[file]`,
        validations: [
          () => currentObj.validFileType || 'Arquivo inválido',
        ],
        gridSize: 10,
        valueDefault: '',
        fileName: getFileName(currentObj),
        elementFooter: (
          <>
            <Grid item xs={1} style={{ paddingLeft: 0, marginLeft: '-6px' }}>
              <Button disabled={!currentObj.fileUrl}>
                <Link href={currentObj.fileUrl} color="inherit" download>
                  <GetAppIcon />
                </Link>
              </Button>
            </Grid>
            <Grid item xs={1} style={{ paddingLeft: 0, marginLeft: '-6px' }}>
              <Button
                color="inherit"
                disabled={!currentObj.hasFile}
                onClick={removeImage}
              >
                <ClearIcon />
              </Button>
            </Grid>
          </>),
      },
      {
        id: `${objectKey}.id`,
        label: '',
        type: 'text',
        field: currentObj.id ? `${objectKey}_attributes[id]` : '',
        fieldStyle: hiddenClass,
        valueDefault: currentObj.id ? currentObj.id : '',
      },
      {
        id: `${objectKey}.destroy`,
        label: '',
        type: 'text',
        field: `${objectKey}_attributes[_destroy]`,
        fieldStyle: hiddenClass,
        valueDefault: currentObj.destroy && (!currentObj.image || !currentObj.image.file),
      },
    ];

    return fileFields;
  };

  const updateFileUrl = (fileObject) => {
    const currentObj = fileObject;

    if (currentObj.fileUrl) {
      currentObj.fileUrl = '';

      setForceUpdateImage(true);
    }
  };

  const validateHasFile = (event) => event.currentTarget.files.length > 0;

  const validateFileType = ({ currentTarget }) => {
    const type = _.get(currentTarget, 'files[0].type');
    const { accept } = currentTarget;

    if (type && accept) {
      const regex = new RegExp(`^${accept.replace('*', '.*')}$`);
      return regex.test(type);
    }

    return true;
  };

  const getFilesName = (files) => (
    Array.from(files).map((file) => file.name).join(', ')
  );

  const onChangeFile = (objectKey, event) => {
    const hasFile = validateHasFile(event);
    const validFileType = validateFileType(event);
    const { files } = event.currentTarget || event.target;

    setState((prevState) => {
      const currentObj = prevState[objectKey];

      currentObj.hasFile = hasFile;
      currentObj.validFileType = validFileType;
      currentObj.destroy = !hasFile;
      currentObj.fileName = getFilesName(files);

      updateFileUrl(currentObj);
      setForceUpdateImage(true);

      return prevState;
    });
  };

  const createFileFields = (objectKey, fileLabel, descriptionLabel) => {
    const newFileFields = getFileFields(
      objectKey,
      fileLabel,
      descriptionLabel,
    );

    const fileField = newFileFields.find(({ type }) => type === 'file');

    fileField.accept = 'image/*';
    fileField.onChange = (_id, _value, event) => onChangeFile(objectKey, event);

    return newFileFields;
  };

  const updateImageFields = () => setImageFields(createFileFields('image', 'Imagem'));

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleChange = (id, value) => {
    const newState = { ...state };

    _.set(newState, id, value);
    setState(newState);
  };

  const onChangeCallback = (onChange, ...params) => {
    handleChange(...params);
    if (typeof onChange === 'function') onChange(...params);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      const path = routes.producerPath;
      return path.replace(':id', producer.id);
    }

    return urlProducers;
  };

  const onFormSubmit = () => {
    const { image, ...filtredState } = state;

    FormService.data = {
      ...filtredState,
      imageChanged: image.id ? image.destroy : FileUtilsService.hasValidFile(image),
    };
  };

  useEffect(
    updateImageFields,
    [state.image],
  );

  useEffect(() => {
    if (forceUpdateImage) {
      updateImageFields();
      setForceUpdateImage(false);
    }
  }, [forceUpdateImage]);

  useEffect(() => {
    const { data } = FormService;

    if (data && data.imageChanged) {
      setMessageSnackBar({
        message: 'Não foi possível recuperar a imagem',
        variant: 'warning',
      });
    }
  }, []);

  return (
    <>
      <FormModal
        open={open}
        title="Produtor"
        formForProps={{
          url: getUrlForm(),
          method: edit ? 'put' : 'post',
          encType: 'multipart/form-data',
          onSubmit: onFormSubmit,
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {[...fields, ...imageFields].map((
          {
            fieldClasses,
            id,
            field: inputField,
            type,
            inputClasses,
            validations,
            warnings,
            label,
            gridSize,
            format,
            placeholder,
            required,
            autoFocus,
            renderLabel,
            multiple,
            fileName,
            accept,
            fieldStyle,
            optionSelected,
            onChange,
            valueDefault,
            elementFooter,
            elementHeader,
          },
        ) => (
          <React.Fragment key={id}>
            {elementHeader}
            <FloatingLabelInput
              fieldClasses={fieldClasses}
              id={id}
              name={inputField}
              type={type}
              inputClasses={inputClasses}
              validationRules={validations}
              warningsRules={warnings}
              label={label}
              value={_.get(state, id, valueDefault)}
              onChange={(...params) => onChangeCallback(onChange, ...params)}
              gridSize={gridSize}
              format={format}
              placeholder={placeholder}
              required={required}
              autoFocus={autoFocus}
              values={values[id]}
              renderLabel={renderLabel}
              multiple={multiple}
              fileName={fileName}
              accept={accept}
              fieldStyle={fieldStyle}
              optionSelected={optionSelected}
            />
            {elementFooter}
          </React.Fragment>
        ))}
      </FormModal>
      {messageSnackBar.message && messageSnackBar.variant && (
        <MessageSnackBar
          message={messageSnackBar.message}
          variant={messageSnackBar.variant}
        />
      )}
    </>
  );
};


EditProducerModal.propTypes = {
  producer: producerPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  cities: PropTypes.arrayOf(cityPropType),
  seedBeneficiationUnits: PropTypes.arrayOf(seedBeneficiationUnitType),
};

export default EditProducerModal;
