import React from 'react';

const IconBoletim = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="boletim" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Boletins</title>
      <path d="M57.9,14.1H47.4a8.4,8.4,0,1,0-16.7,0H20.3A6.3,6.3,0,0,0,14,20.4v46a6.3,6.3,0,0,0,6.3,6.2H57.9a6.3,6.3,0,0,0,6.3-6.2v-46A6.3,6.3,0,0,0,57.9,14.1ZM39.1,11a3.1,3.1,0,1,1-3.2,3.1A3.1,3.1,0,0,1,39.1,11ZM22.6,33.1l1.6-1.6a.7.7,0,0,1,1,0l2.7,2.7L34.1,28a.9.9,0,0,1,1,0l1.6,1.7a.7.7,0,0,1,0,1L28.4,39a.9.9,0,0,1-1,0l-4.9-4.9a.8.8,0,0,1,0-1ZM26.5,57a3.2,3.2,0,1,1,3.2-3.2A3.1,3.1,0,0,1,26.5,57Zm29.3-2.1a1.1,1.1,0,0,1-1.1,1H35.9a1.1,1.1,0,0,1-1-1V52.8a1.1,1.1,0,0,1,1-1.1H54.7a1.1,1.1,0,0,1,1.1,1.1Zm0-16.8a1.3,1.3,0,0,1-1.3,1.1H34.1L38.3,35H54.5a1.1,1.1,0,0,1,1.3,1v2.1Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconBoletim;
