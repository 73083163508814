import React from 'react';

const IconPeneiras = () => {
  const style = {
    fillcolor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="peneiras" style={style.fillcolor} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 79.5 78.36">
      <title>Peneiras</title>
      <path d="M71.62,4.55H7.88a3.3,3.3,0,0,0-2.33,5.63L31,35.59V63.9a3.3,3.3,0,0,0,1.4,2.7l11,7.69a3.3,3.3,0,0,0,5.19-2.7v-36L74,10.18A3.3,3.3,0,0,0,71.62,4.55Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconPeneiras;
