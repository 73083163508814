import PropTypes from 'prop-types';

export const timestampsType = {
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

export const measureUnitType = {
  id: PropTypes.string.isRequired,
  abbreviation: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const attachmentType = {
  id: PropTypes.string,
  description: PropTypes.string,
  fileContentType: PropTypes.string,
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
  fileUpdatedAt: PropTypes.string,
  fileUrl: PropTypes.string,
};

export const laboratoryType = {
  name: PropTypes.string.isRequired,
};

export const additionalSeedInfoType = {
  others: PropTypes.number,
  wild: PropTypes.number,
  harmfulTolerated: PropTypes.number,
  harmfulForbidden: PropTypes.number,
};

export const groundEmergencyType = {
  firstReadDate: PropTypes.string,
  firstReadVigor: PropTypes.number,
  secondReadDate: PropTypes.string,
  secondReadViabilidade: PropTypes.number,
};

export const gridSizeType = PropTypes.shape({
  xs: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
});

export const fieldItemType = PropTypes.shape({
  description: PropTypes.string,
  getDescription: PropTypes.string,
  attribute: PropTypes.string,
  attributes: PropTypes.arrayOf(PropTypes.shape({
    attribute: PropTypes.string,
    type: PropTypes.string,
  })),
  type: PropTypes.string,
  maximumFactionDigits: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  ifZero: PropTypes.string,
  hideIfNull: PropTypes.bool,
});

export const geolocationPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const cropPeriodType = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const routesPropType = PropTypes.objectOf(PropTypes.string).isRequired;

export const actionsPermittedPropType = PropTypes.arrayOf(PropTypes.string).isRequired;
