import _ from 'lodash';
import moment from 'moment';

export default class UtilsService {
  /**
   * Valida os objetos no State com as funções de validação.
   *
   * @param fields Configurações dos campos a serem validados.
   * @param state State do componente com os campos.
   * @returns {boolean}
   * @memberof UtilsService
   */
  static validateState(fields, state) {
    return fields.every(({ id, validations, attribute }) => (
      !_.isArray(validations)
      || validations.every((fn) => fn(_.get(state, attribute || id), attribute || id) === true)
    ));
  }

  static formatNumber(number, options) {
    return Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options,
    }).format(number);
  }

  static setMomentLocale(locale = 'pt-BR') {
    moment.locale(locale);
  }

  static getObjectValue(object, path, defaultValue = '') {
    return _(object).omitBy(_.isNil).get(path, defaultValue);
  }

  static updateFields(fields, additionalInputsInfo) {
    return fields.map((f) => {
      const { id } = f;

      if (!additionalInputsInfo[id]) {
        return f;
      }

      return {
        ...f,
        ...additionalInputsInfo[id],
      };
    });
  }

  static getAttributeValue(data, field) {
    const attributeValue = _.get(data, field.attribute);

    const isBlank = typeof attributeValue === 'number'
      ? _.isNil(attributeValue) || _.isNaN(attributeValue)
      : _.isEmpty(attributeValue);

    return isBlank ? _.get(data, field.alternativeAttribute) : attributeValue;
  }

  static getValue(data, field) {
    if (!field || !data) return '';

    if (field.type === 'multi') {
      const value = field.attributes
        .map(({ attribute }) => this.getAttributeValue(data, attribute))
        .join(' ');

      return value || '';
    }

    return this.getAttributeValue(data, field);
  }

  static showField(data, field) {
    let show = true;
    if (field.hideIfNull) {
      show = !_.isNil(UtilsService.getValue(data, field));
    }

    if (show && field.hideIfEmpty) {
      show = !_.isEmpty(UtilsService.getValue(data, field));
    }

    return show;
  }

  static showBoxOfFields(data, fields) {
    return fields.some((field) => UtilsService.showField(data, field));
  }

  static runFunctionFromString(parameters, fnString) {
    const parsedParameters = Object.entries(parameters).reduce((acc, [name, value]) => {
      acc.names.push(name);
      acc.values.push(value);
      return acc;
    }, { names: [], values: [] });

    // eslint-disable-next-line no-new-func
    const fn = new Function(...parsedParameters.names, fnString);

    return fn(...parsedParameters.values);
  }
}
