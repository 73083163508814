import React, { useState } from 'react';
import { speciePropType } from './Species';
import { fields, buildSpecie } from './EditSpecieModal';
import ViewModal from '../../components/ViewModal';

const ViewSpecieModal = (props) => {
  const { specie } = props;

  const data = buildSpecie(specie);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ViewModal
        open={open}
        title="Atividade"
        actionsButtons={{
          close: {
            onClick: handleClose,
          },
        }}
        fields={fields}
        data={data}
      />
    </>
  );
};

ViewSpecieModal.propTypes = {
  specie: speciePropType,
};

export default ViewSpecieModal;
