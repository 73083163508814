import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import classNames from 'classnames';
import Contact from './Contact';
import HeaderSearch from './HeaderSearch';
import { simpleUserPropType } from '../user/Users';

const useStyles = makeStyles((theme) => ({
  toolBar: {
    paddingRight: theme.spacing(0),
  },
  userNameBtn: {
    padding: theme.spacing(2.5, 2, 2.5, 2),
  },
  toolBarTitle: {
    flex: 1,
    padding: theme.spacing(1),
  },
  exitIcon: {
    marginLeft: theme.spacing(0.5),
  },
  logo: {
    padding: theme.spacing(1, 0),
    maxHeight: theme.spacing(9),
  },
  logoSmall: {
    textAlign: 'center',
  },
  headerSmall: {
    alignItems: 'center',
  },
}));

const Logo = ({ header }) => {
  const classes = useStyles();

  return (
    <a
      href={header.url}
      className={classNames({ 'cursor-default': !header.url })}
    >
      <img src={header.logo} alt={header.title} className={classes.logo} />
    </a>
  );
};

const HeaderToolbar = ({ config, routes, user }) => {
  const { header } = config.content;
  const color = get(config, 'style.toolbar.text.color');
  const classes = useStyles();

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <Hidden xsDown>
            <HeaderSearch
              config={config}
              routes={routes}
              user={user}
            />
            <Logo header={header} />
            <Typography variant="h6" className={classes.toolBarTitle} style={{ color }}>
              { header.showAqilaReferences && 'Aqila - Rastreabilidade' }
            </Typography>
            <Contact
              config={config}
            />
          </Hidden>
          <Hidden smUp>
            <Grid container className={classes.headerSmall}>
              <Grid item xs={2}>
                <HeaderSearch
                  config={config}
                  routes={routes}
                  user={user}
                />
              </Grid>
              <Grid item xs={8} className={classes.logoSmall}>
                <Logo header={header} />
              </Grid>
              <Grid item xs={2}>
                <Contact
                  config={config}
                />
              </Grid>
            </Grid>
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
};

HeaderToolbar.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.objectOf(PropTypes.string),
  user: simpleUserPropType,
};

Logo.propTypes = {
  header: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HeaderToolbar;
