import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isArray, isNil } from 'lodash';
import { fieldItemType } from '../../../services/GeneralTypes';
import UtilsService from '../../../services/UtilsService';

const InfoItem = (props) => {
  const {
    field,
    data,
  } = props;

  const getValue = (fieldAttribute) => UtilsService.getValue(data, fieldAttribute);
  const getType = (fieldAttribute) => fieldAttribute.type || 'string';
  const { ifZero } = field;

  const renderString = (fieldAttribute) => getValue(fieldAttribute);
  const renderNumber = (fieldAttribute) => {
    const maximumFractionDigits = Number.isFinite(field.maximumFractionDigits)
      ? field.maximumFractionDigits : 2;

    const value = getValue(fieldAttribute);

    if (value === 0 && ifZero) return ifZero;

    return (isNil(value) ? '' : value).toLocaleString('pt-BR', {
      maximumFractionDigits,
    });
  };

  const renderDate = (fieldAttribute) => {
    const format = field.format || 'DD/MM/YYYY';

    const value = getValue(fieldAttribute);
    if (!value) {
      return '';
    }

    const date = moment(value).utcOffset(value);
    if (date && date.isValid()) {
      return date.format(format);
    }

    return '';
  };

  const renderArray = (fieldAttribute) => {
    const value = getValue(fieldAttribute);

    if (!isArray(value)) return '';

    return value.join(', ');
  };

  const types = {
    string: renderString,
    default: renderString,
    number: renderNumber,
    date: renderDate,
    array: renderArray,
  };

  const getRenderType = (fieldAttribute) => types[getType(fieldAttribute)] || types.default;

  const renderMultiFields = (fieldAttribute) => {
    const values = fieldAttribute.attributes.map((attribute) => {
      const render = getRenderType(attribute);
      return render(attribute);
    });
    return values.join(' ');
  };

  const renderPrefixSuffix = (fieldAttribute) => !isNil(getValue(fieldAttribute));
  types.multi = renderMultiFields;

  const renderAttribute = () => {
    const renderType = getRenderType(field);

    return (
      <>
        {renderPrefixSuffix() && field.prefix}
        {renderType(field)}
        {renderPrefixSuffix(field) && field.suffix}
      </>
    );
  };

  return renderAttribute();
};

InfoItem.propTypes = {
  field: fieldItemType,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  ifZero: PropTypes.string,
  hideIfNull: PropTypes.bool,
  hideIfEmpty: PropTypes.bool,
};

export default InfoItem;
