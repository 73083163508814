import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { FormFor } from 'react-rails-form-helpers';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Barra de busca padrão para ser exibida nas páginas de listagem.
 *
 * @visibleName Search Bar
 */
const SearchBar = (props) => {
  const { url, query } = props;
  const [term, setTerm] = useState(query || '');

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }));

  const classes = useStyles();

  return (
    <div className="searchbar">
      <FormFor
        url={url}
        method="get"
        id="searchForm"
        name="searchForm"
      >
        <Grid container spacing={3}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Paper className={classes.root}>
              <IconButton type="submit" className={classes.iconButton} aria-label="buscar">
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder="Buscar"
                inputProps={{ 'aria-label': 'Buscar' }}
                onChange={(event) => setTerm(event.target.value)}
                value={term}
                id="q"
                name="q"
              />
              {term && term !== '' && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="limpar"
                  onClick={() => setTerm('')}
                >
                  <CancelIcon size="small" fontSize="small" />
                </IconButton>
              )}
            </Paper>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </FormFor>
    </div>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  url: PropTypes.string.isRequired,
  query: PropTypes.string,
};
