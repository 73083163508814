import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import SearchCard from './SearchCard';
import { simpleUserPropType } from '../user/Users';

const useStyles = makeStyles((theme) => ({
  buttonContact: {
    marginRight: theme.spacing(4),
  },
  root: {
    minWidth: '250px',
    height: '100%',
  },
}));

const HeaderSearch = ({ config, routes, user }) => {
  const { allowSearch } = config.content.header;
  const color = get(config, 'style.toolbar.text.color');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isDrawerOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <>
      {allowSearch && (
        <>
          <div
            className={classes.buttonContact}
            role="presentation"
          >
            <IconButton
              style={{ color }}
              aria-label="Busca"
              onClick={handleMenuOpen}
              edge="start"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <Drawer
            open={isDrawerOpen}
            onClose={handleMenuClose}
            anchor="left"
          >
            <Grid container component="main" className={classes.root}>
              <Grid item xs={12} component={Paper} elevation={6} square>
                <SearchCard
                  routes={routes}
                  config={config}
                  user={user}
                />
              </Grid>
            </Grid>
          </Drawer>
        </>
      )}
    </>
  );
};

HeaderSearch.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.objectOf(PropTypes.string),
  user: simpleUserPropType,
};

export default HeaderSearch;
