import React from 'react';
import PropTypes from 'prop-types';
import { LoadScript } from '@react-google-maps/api';
import { withStyles } from '@material-ui/core/styles';
import TableLoading from '../../components/TableLoading';
import { GOOGLE_MAPS_API_KEY } from '../../../services/constants';
import LandingPageContent from './LandingPageContent';
import { SeedLotPropType } from '../seedLot/SeedLots';
import { userPropType } from '../user/Users';
import { regionPropType } from '../region/Regions';
import LandingPageTheme from './LandingPageTheme';
import { unitConversionPropType } from '../unit_conversion/UnitConversions';
import { SeedTreatmentPropType } from '../seedTreatment/SeedTreatments';

const MAP_LIBRARIES = ['geometry', 'drawing'];

const LandingPage = (props) => {
  const {
    config,
    seedLot,
    routes,
    user,
    regions,
    unitConversions,
    seedTreatment,
  } = props;


  return (
    <>
      <LoadScript
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        language="pt-BR"
        libraries={MAP_LIBRARIES}
        loadingElement={<TableLoading loading />}
      >
        <LandingPageTheme
          config={config}
          routes={routes}
          user={user}
        >
          <LandingPageContent
            config={config}
            seedLot={seedLot}
            regions={regions}
            unitConversions={unitConversions}
            seedTreatment={seedTreatment}
          />
        </LandingPageTheme>
      </LoadScript>
    </>
  );
};

LandingPage.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  seedLot: SeedLotPropType,
  routes: PropTypes.objectOf(PropTypes.string),
  user: userPropType,
  regions: PropTypes.arrayOf(regionPropType),
  unitConversions: PropTypes.arrayOf(unitConversionPropType),
  seedTreatment: SeedTreatmentPropType,
};

export default withStyles({ withTheme: true })(LandingPage);
