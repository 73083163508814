import React from 'react';

const IconAtividade = () => {
  const style = {
    fillCollor: {
      fill: '#1f1f1f',
    },
  };

  /* eslint-disable max-len */
  return (
    <svg className="atividade" style={style.fillCollor} xmlns="http://www.w3.org/2000/svg" widht="32" height="32" viewBox="0 0 79.5 78.4">
      <title>Atividade Icon</title>
      <path d="M71.9,38H32.6a2.2,2.2,0,0,0-2.2,2.2h0v2.2a2.2,2.2,0,0,0,2.2,2.2H71.9a2.2,2.2,0,0,0,2.2-2.2h0V40.2A2.2,2.2,0,0,0,71.9,38Zm0,21.8H32.6A2.2,2.2,0,0,0,30.4,62h0v2.1a2.2,2.2,0,0,0,2.2,2.2H71.9a2.2,2.2,0,0,0,2.2-2.2h0V62A2.2,2.2,0,0,0,71.9,59.8Zm0-43.6H32.6a2.2,2.2,0,0,0-2.2,2.2h0v2.1a2.2,2.2,0,0,0,2.2,2.2H71.9a2.2,2.2,0,0,0,2.2-2.2h0V18.4a2.2,2.2,0,0,0-2.2-2.2ZM13,56.5a6.6,6.6,0,0,0-6.5,6.6A6.5,6.5,0,0,0,13,69.6a6.6,6.6,0,0,0,6.6-6.5A6.8,6.8,0,0,0,13,56.5ZM23.3,33A1.7,1.7,0,0,0,21,33l-8.7,8.6L9.2,38.7a1.6,1.6,0,0,0-2.3-.1h0L4.7,40.8a1.6,1.6,0,0,0,0,2.2l6.5,6.5a1.8,1.8,0,0,0,2.4,0l2.1-2.2,9.9-9.7a1.7,1.7,0,0,0,0-2.3Zm0-21.8a1.6,1.6,0,0,0-2.3,0h0l-8.7,8.6-3.1-3a1.7,1.7,0,0,0-2.3,0L4.7,18.9a1.7,1.7,0,0,0,0,2.3h0l6.5,6.5a1.8,1.8,0,0,0,2.4,0l2.2-2.1,9.8-9.9a1.7,1.7,0,0,0,0-2.3Z" />
    </svg>
  );
  /* eslint-enable max-len */
};

export default IconAtividade;
