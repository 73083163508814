import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListTable from '../../components/ListTable';
import SearchBar from '../../components/SearchBar';
import NewButton from '../../components/NewButton';
import { measureUnitType, actionsPermittedPropType } from '../../../services/GeneralTypes';
import DropdownActionButtons from '../../components/DropdownActionButtons';

const MeasureUnits = ({
  measureUnits,
  query,
  routes,
  actionsPermitted,
}) => {
  const urlNewMeasureUnit = routes.newMeasureUnitPath;
  const urlMeasureUnit = routes.measureUnitsPath;

  const columns = [
    {
      header: 'Descrição',
      cell: ({ description }) => description,
    },
    {
      header: 'Abreviação',
      cell: ({ abbreviation }) => abbreviation,
    },
    {
      header: 'Ações',
      cell: (row) => (
        <DropdownActionButtons
          viewUrl={routes.measureUnitPath}
          editUrl={routes.editMeasureUnitPath}
          deleteUrl={routes.measureUnitPath}
          itemId={row.id}
          actionsPermitted={actionsPermitted}
          deleteMessage={
            `Você tem certeza de que deseja excluir a unidade de medida <b>${row.description}</b>?
            <br>Esta ação é irreversível!`
          }
        />
      ),
      alignRight: true,
    },
  ];

  return (
    <>
      <Container className="rastreabilidade-container">
        <div>
          <Typography component="h1" variant="h2" align="center">
            Unidades de medidas
          </Typography>
          <SearchBar
            url={urlMeasureUnit}
            query={query}
          />
          <NewButton
            url={urlNewMeasureUnit}
            description="Nova Unidade"
          />
          <ListTable
            columns={columns}
            data={measureUnits}
          />
        </div>
      </Container>
    </>
  );
};

MeasureUnits.propTypes = {
  measureUnits: PropTypes.arrayOf(
    PropTypes.shape(measureUnitType),
  ).isRequired,
  query: PropTypes.string,
  routes: PropTypes.objectOf(PropTypes.string),
  actionsPermitted: actionsPermittedPropType,
};

export default MeasureUnits;
