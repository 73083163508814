/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import PropTypes from 'prop-types';

/**
 * Componente para exibir um mapa na tela. Todos os componentes filhos serão
 * automaticamente passados como filhos do componente GoogleMap. As props são
 * herdadas do GoogleMapProps e do WithGoogleMapProps, do pacote react-google-maps.
 * Veja a documentação do pacote para maiores detalhes. Os componentes extras
 * `defaultMapContainer` e `defaultMapElement` exportados juntamente com este são
 * componentes padrão para usar no mapa. Veja o arquivo MapaPage/index.tsx.
 *
 * @see [React Google Maps Documentation](https://tomchentw.github.io/react-google-maps/)
 */
const Map = ({
  children,
  allCoordinates,
  ...props
}) => {
  const boundsForCoordinates = (coordinates) => {
    const { maps } = google;

    if (coordinates.length > 0) {
      const firstCoordinates = coordinates.shift();
      const firstLatLng = new maps.LatLng(firstCoordinates[0], firstCoordinates[1]);
      let result = new maps.LatLngBounds(firstLatLng);

      for (const [lat, lng] of coordinates) {
        result = result.extend(new maps.LatLng(lat, lng));
      }

      return result;
    }
    return null;
  };

  const getBounds = () => {
    if (allCoordinates && allCoordinates.length) {
      return boundsForCoordinates(allCoordinates);
    }

    return boundsForCoordinates([
      [5, -60], // Norte
      [-50, -60], // Sul
    ]);
  };

  // Centraliza o mapa na América do Sul assim que ele é carregado
  const onLoad = (map) => {
    const centerBounds = getBounds();

    if (centerBounds) {
      map.fitBounds(centerBounds);
    }

    if (props.onLoad) {
      props.onLoad(map);
    }
  };

  return (
    <GoogleMap
      {...props}
      onLoad={onLoad}
      options={{
        mapTypeId: google.maps.MapTypeId.SATELLITE,
      }}
    >
      {children}
    </GoogleMap>
  );
};

Map.propTypes = {
  routes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  onLoad: PropTypes.func,
  allCoordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
};

export default Map;
