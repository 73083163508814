/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import MapCard from './landing/MapCard';
import InfoCard from './landing/InfoCard';
import InfoBox from './landing/InfoBox';
import ImageCard from './landing/imageCard/component';
import AttachmentsCard from './landing/AttachmentsCard';
import ProducerBox from './landing/ProducerBox';
import SeedLotPlantabilityCalculator from './calculator/SeedLotPlantabilityCalculator';
import HTMLCard from './landing/HTMLCard';
import SeedTreatmentCard from './landing/SeedTreatmentCard';

const Components = {
  map: MapCard,
  info: InfoCard,
  infoBox: InfoBox,
  producerBox: ProducerBox,
  images: ImageCard,
  attachments: AttachmentsCard,
  plantabilityCalculator: SeedLotPlantabilityCalculator,
  html: HTMLCard,
  seedTreatment: SeedTreatmentCard,
};

const DynamicComponent = (props) => {
  const { block, ...rest } = props;

  const getSubComponents = () => {
    const { subComponents: subComponentsBlock = [] } = block;

    return subComponentsBlock.map((subComponent) => {
      if (Components[subComponent.component]) {
        const { props: subComponentProps } = subComponent;

        return React.createElement(Components[subComponent.component], {
          block: subComponent,
          key: subComponent.uuid,
          ...subComponentProps,
          ...rest,
        });
      }

      return <React.Fragment key={subComponent.uuid} />;
    });
  };

  const subComponents = getSubComponents();

  if (Components[block.component]) {
    const { props: blockProps } = block;

    return React.createElement(Components[block.component], {
      block,
      key: block.uuid,
      subComponents,
      ...blockProps,
      ...rest,
    });
  }

  return React.createElement(() => <></>, { key: block.uuid });
};

const blockPropsTypes = {
  uuid: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  props: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ])),
};

const rootBlockPropTypes = {
  ...blockPropsTypes,
  subComponents: PropTypes.arrayOf(PropTypes.shape(blockPropsTypes)),
};

DynamicComponent.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.shape(rootBlockPropTypes),
};

export default DynamicComponent;
