/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import CardAvatar from '../../components/Card/CardAvatar';
import CardTitle from '../../components/Card/CardTitle';
import HTMLComponent from '../../components/HTMLComponent';
import { SeedLotPropType } from '../seedLot/SeedLots';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
  },
  seedSealImage: {
    height: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(8),
    },
  },
  cardHeader: {
    flex: 1,
  },
  cardHeaderBox: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

const HTMLCard = (props) => {
  const {
    title,
    type,
    content,
    seedLot,
    evaluate,
    subComponents,
  } = props;

  const classes = useStyles();

  const renderAvatar = () => (
    <>
      <CardAvatar>
        {type === 'warning' ? (
          <WarningIcon />
        ) : (
          <InfoIcon />
        )}
      </CardAvatar>
    </>
  );

  const shouldShow = () => {
    if (!evaluate || !seedLot) {
      return true;
    }

    try {
      // eslint-disable-next-line no-new-func
      const evaluateFunction = new Function('seedLot', evaluate);

      return evaluateFunction.call(null, seedLot);
    } catch (e) {
      console.error(e);
    }

    return true;
  };

  return (
    <>
      {shouldShow() && (
        <Grid item xs={12}>
          <Card>
            <div className={classes.cardHeaderBox}>
              <CardHeader
                className={classes.cardHeader}
                avatar={renderAvatar()}
                title={(
                  <CardTitle
                    title={title || 'Informações'}
                  />
              )}
              />
            </div>
            <CardContent>
              <Grid container spacing={3} className={classes.container}>
                <HTMLComponent html={content} />
              </Grid>
            </CardContent>
          </Card>
          {subComponents}
        </Grid>
      )}
    </>
  );
};

HTMLCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  content: PropTypes.string.isRequired,
  subComponents: PropTypes.arrayOf(PropTypes.element),
  evaluate: PropTypes.string,
  seedLot: SeedLotPropType,
};

export default HTMLCard;
