import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FormFor } from 'react-rails-form-helpers';

const EditListItem = (props) => {
  const {
    itemId, path, forwardedRef, currentPage,
  } = props;
  const urlEdit = path.replace(':id', itemId);

  const editNameForm = `edit-${itemId}`;

  return (
    <FormFor
      url={urlEdit}
      method="get"
      id={editNameForm}
      name={editNameForm}
    >
      <input
        name="page"
        value={currentPage}
        type="hidden"
      />
      <ListItem
        button
        type="submit"
        component="button"
        form={editNameForm}
        className="editButton"
        ref={forwardedRef}
      >
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Editar" />
      </ListItem>
    </FormFor>
  );
};

EditListItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  forwardedRef: PropTypes.func,
  currentPage: PropTypes.number,
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <EditListItem {...props} forwardedRef={ref} />);
