import React, { useState } from 'react';

import { regionPropType } from './Regions';
import { fields, buildRegion } from './EditRegionModal';
import ViewModal from '../../components/ViewModal';

const ViewRegionModal = (props) => {
  const { region } = props;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ViewModal
      open={open}
      title="Região"
      actionsButtons={{
        close: {
          onClick: handleClose,
        },
      }}
      fields={fields}
      data={buildRegion(region)}
    />
  );
};

ViewRegionModal.propTypes = {
  region: regionPropType,
};

export default ViewRegionModal;
