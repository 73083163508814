import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Exibe uma barra de carregamento indeterminado enquanto os dados da tabela estão sendo carregados.
 * Faz parte do componente ListTable.
 *
 * @visibleName Table Loading
 */
const TableLoading = ({ loading }) => {
  const loadingNames = classNames('table-loading', {
    'is-active': loading,
  });
  return (
    <div className={loadingNames}>
      <progress className="progress is-small is-primary" max="100">15%</progress>
    </div>
  );
};

export default TableLoading;

TableLoading.propTypes = {
  loading: PropTypes.bool,
};
