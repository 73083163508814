import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../components/FormModal';
import FloatingLabelInput from '../../components/FloatingLabelInput';
import UtilsService from '../../../services/UtilsService';
import { rulePropType } from './SeedQualityRules';
import { seedQualityPatternPropType } from '../seedQualityPattern/SeedQualityPatterns';

export const operationHumanize = {
  equal: 'Igual a (=)',
  greater_than: 'Maior que (>)',
  greater_or_equal_than: 'Maior ou igual a (>=)',
  less_than: 'Menor que (<)',
  less_or_equal_than: 'Menor ou igual a (<=)',
};

export const attributeHumanize = {
  germination: 'Germinação',
  higher_and_greater_vigor: 'Mais alto e alto vigor',
  vigor: 'Vigor',
  tetrazolium_vigor: 'Vigor do Tetrazólio',
};

export const fields = [
  {
    id: 'fieldAttribute',
    label: 'Selecione um Atributo',
    viewLabel: 'Atributo',
    type: 'autocomplete',
    field: 'field_attribute',
    validations: [
      (v) => !!v || 'Atributo é obrigatório',
    ],
    renderLabel: (attribute) => attribute.description,
    required: true,
    values: Object.keys(attributeHumanize).map((attribute) => (
      {
        id: attribute,
        description: attributeHumanize[attribute],
      }
    )),
  },
  {
    id: 'operation',
    label: 'Selecione uma Operação',
    viewLabel: 'Operação',
    type: 'autocomplete',
    field: 'operation',
    validations: [
      (v) => !!v || 'Operação é obrigatória',
    ],
    renderLabel: (operation) => operation.description,
    required: true,
    values: Object.keys(operationHumanize).map((operation) => (
      {
        id: operation,
        description: operationHumanize[operation],
      }
    )),
  },
  {
    id: 'value',
    label: 'Valor',
    type: 'number',
    field: 'value',
    validations: [
      (v) => !!v || 'Valor é obrigatório',
    ],
    required: true,
  },
];

const emptyState = {
  fieldAttribute: undefined,
  value: '',
  operation: undefined,
};

export const buildSeedQualityRule = (rule) => {
  if (rule) {
    return {
      fieldAttribute: {
        id: rule.fieldAttribute,
        description: attributeHumanize[rule.fieldAttribute],
      },
      value: rule.value,
      operation: {
        id: rule.operation,
        description: operationHumanize[rule.operation],
      },
    };
  }

  return emptyState;
};

const EditSeedQualityRuleModal = (props) => {
  const {
    routes,
    seedQualityRule,
    seedQualityPattern,
  } = props;

  const [state, setState] = useState(emptyState);
  const [open, setOpen] = useState(false);
  const [edit] = useState(Boolean(seedQualityRule));

  const handleChange = (id, value) => {
    const updateState = {
      ...state,
      [id]: value,
    };

    if (id === 'specie') {
      updateState.variety = null;
    }

    setState(updateState);
  };

  useEffect(() => {
    setState(buildSeedQualityRule(seedQualityRule));
    setOpen(true);
  }, []);

  const validInputs = () => UtilsService.validateState(fields, state);

  const handleClose = () => {
    setOpen(false);
  };

  const getUrlForm = () => {
    if (edit) {
      const path = routes.rulePath;
      return path.replace(':id', seedQualityRule.id);
    }

    return routes.seedQualityPatternRulesPath.replace(':id', seedQualityPattern.id);
  };

  return (
    <>
      <FormModal
        open={open}
        title="Regra"
        formForProps={{
          url: getUrlForm(),
          method: edit ? 'put' : 'post',
        }}
        actionsButtons={{
          confirm: {
            label: 'Salvar',
            disabled: !validInputs(),
          },
          cancel: {
            onClick: handleClose,
          },
        }}
      >
        {fields.map((
          {
            id,
            label,
            type,
            field: inputField,
            validations,
            placeholder,
            format,
            required,
            autoFocus,
            renderLabel,
            values,
          },
        ) => (
          <FloatingLabelInput
            key={inputField}
            id={id}
            label={label}
            type={type}
            validationRules={validations}
            value={state[id]}
            onChange={handleChange}
            name={inputField}
            placeholder={placeholder}
            format={format}
            required={required}
            autoFocus={autoFocus}
            values={values}
            renderLabel={renderLabel}
          />
        ))}
      </FormModal>
    </>
  );
};

EditSeedQualityRuleModal.propTypes = {
  seedQualityRule: rulePropType,
  routes: PropTypes.objectOf(PropTypes.string),
  seedQualityPattern: seedQualityPatternPropType,
};

export default EditSeedQualityRuleModal;
